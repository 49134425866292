<div [class]="'deviation-widget-container ' + backgroundColorClass">
  <div class="deviation-widget-progress-label">{{ progressLabel }}</div>
  <div class="progress-target-wrapper">
    <div class="deviation-widget-progress-value">{{ progressValue }}</div>
    <div class="target-deviation-wrapper">
      <div>
        {{ 'OPERATOR_COCKPIT.TARGET' | translate }}:
        <span class="target-value">{{ target }}</span>
      </div>
      <div class="deviation" [class]="deviationColorClass">
        {{ deviation }}
      </div>
    </div>
  </div>
  <div class="progress-bar-wrapper">
    <mat-progress-bar
      class="progress-bar"
      [ngClass]="progressBarColorClass"
      mode="determinate"
      [value]="progressBarValue"
    ></mat-progress-bar>
    <div
      [style]="{ 'margin-left': targetLinePosition + 'px' }"
      class="target-line"
    ></div>
  </div>
</div>
