import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserGroup } from 'src/app/enums/user-group.enum';
import { AuthGuard } from 'src/app/services/auth.guard';
import { GroupGuard } from 'src/app/services/group.guard';
import { environment } from 'src/environments/environment';
import { TranslationLoaderResolverService } from 'src/app/services/translation-loader-resolver.service';
import { DepartmentComponent } from './department/department.component';
import { GroupsComponent } from './groups/groups.component';
import { MachineComponent } from './machine/machine.component';
import { SubgroupComponent } from './groups/subgroup/subgroup.component';
import { RealtimeDataResolverService } from '../../services/realtime-data-resolver.service';

const allowedGroups = [
  UserGroup.AppSmsfLive,
  ...(environment?.env === 'development' ? [UserGroup.AppSmsfDevAll] : []),
];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'department',
    pathMatch: 'full',
  },
  {
    path: 'department',
    component: DepartmentComponent,
    resolve: {
      model: TranslationLoaderResolverService,
      param: RealtimeDataResolverService,
    },
    pathMatch: 'full',
    data: {
      allowedGroups,
      breadcrumb: ({ param }) => param.department,
    },
  },
  {
    path: 'department/:departmentId',
    resolve: {
      model: TranslationLoaderResolverService,
      param: RealtimeDataResolverService,
    },
    canActivate: [AuthGuard, GroupGuard],
    data: {
      allowedGroups,
      breadcrumb: ({ param }) => param.department,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: DepartmentComponent,
        canActivate: [AuthGuard, GroupGuard],
      },
      {
        path: 'group/:groupId',
        resolve: {
          model: TranslationLoaderResolverService,
          param: RealtimeDataResolverService,
        },
        canActivate: [AuthGuard, GroupGuard],
        data: {
          allowedGroups,
          breadcrumb: ({ param }) => param.groupId,
        },
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: GroupsComponent,
          },
          {
            path: 'subgroup/:subgroupName',
            resolve: { param: RealtimeDataResolverService },
            canActivate: [AuthGuard, GroupGuard],
            data: {
              allowedGroups,
              breadcrumb: ({ param }) => param.subgroupName,
            },
            children: [
              {
                path: '',
                pathMatch: 'full',
                component: SubgroupComponent,
              },
              {
                path: 'machine/:machineId/name/:machineName',
                component: MachineComponent,
                resolve: { param: RealtimeDataResolverService },
                canActivate: [AuthGuard],
                data: {
                  breadcrumb: ({ param }) =>
                    decodeURIComponent(param.machineName),
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RealtimeDataRoutingModule {}
