<div class="single-day-info">
  <div class="info-title">{{ 'SUBGROUP-ITEM.ACTUAL' | translate }}</div>
  <div class="info-value" [class]="statusColor">
    {{
      status || status === 0
        ? (status | number: '1.2-2')
        : ('NOT_AVAILABLE' | translate)
    }}
  </div>
</div>
<hr class="horisontal-line" />
<div class="single-day-info">
  <div class="info-title">{{ 'SUBGROUP-ITEM.TARGET' | translate }}</div>
  <div class="info-value">
    {{
      target || target === 0
        ? (target | number: '1.2-2')
        : ('NOT_AVAILABLE' | translate)
    }}
  </div>
</div>
