import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-circular-progress-widget',
  templateUrl: './circular-progress-widget.component.html',
  styleUrls: ['./circular-progress-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CircularProgressWidgetComponent implements OnInit {
  @Input() title: string | null;
  @Input() quantity: number | null;
  @Input() target: number | null;
  @Input() unit: string | null;
  @Input() percentage: number | null;
  @Input() progressBarClass: string;
  @Input() backgroundColorClass: string;
  readonly circularProgressStroke = 10;
  readonly circularProgressBuffer = 100;
  readonly circularProgressMode = 'determinate';

  constructor() {}

  ngOnInit(): void {}
}
