import { Component, Input, OnInit } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { TreeNode } from '../../model/tree-node.model';

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss'],
})
export class TreeViewComponent implements OnInit {
  @Input() dataSource = new MatTreeNestedDataSource<TreeNode>();
  @Input() activeNodes = new Array(4);
  /* istanbul ignore next */
  @Input() treeControl = new NestedTreeControl<TreeNode>(
    (node) => node.children,
  );

  constructor() {}

  ngOnInit(): void {}

  hasChild(_: number, node: TreeNode): boolean {
    return !!node.children && node.children.length > 0;
  }

  isNodeActive(name: string): boolean {
    return this.activeNodes.includes(name);
  }

  setActiveNodes({
    level,
    name: activeName,
    level0Name,
    level1Name,
    level2Name,
  }: TreeNode): void {
    switch (level) {
      case 0:
        this.activeNodes[0] = activeName;
        this.activeNodes[1] = null;
        this.activeNodes[2] = null;
        this.activeNodes[3] = null;
        break;
      case 1:
        this.activeNodes[0] = level0Name;
        this.activeNodes[1] = activeName;
        this.activeNodes[2] = null;
        this.activeNodes[3] = null;
        break;
      case 2:
        this.activeNodes[0] = level0Name;
        this.activeNodes[1] = level1Name;
        this.activeNodes[2] = activeName;
        this.activeNodes[3] = null;
        break;
      case 3:
        this.activeNodes[0] = level0Name;
        this.activeNodes[1] = level1Name;
        this.activeNodes[2] = level2Name;
        this.activeNodes[3] = activeName;
        break;
      default:
        throw new Error('No such level in TreeView data.');
    }
  }
}
