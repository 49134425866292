import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: [
    './login-form.component.scss',
    '../../../styles/shared-classes.scss',
  ],
})
export class LoginFormComponent implements OnInit {
  email = '';
  password = '';

  constructor(
    private authService: AuthService,
    public loaderService: LoaderService,
  ) {}

  signIn(): void {
    this.authService.signIn(this.email, this.password);
  }

  ngOnInit(): void {
    this.authService.redirectAuthenticatedUser();
  }
}
