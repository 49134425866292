<div class="subgroup-list-wrapper">
  <ng-container *ngFor="let subGroupItem of subgroupList">
    <app-subgroup-item
      class="app-subgroup-item"
      [groupId]="groupId"
      [subGroupItem]="subGroupItem"
      [departmentId]="departmentId"
    ></app-subgroup-item>
  </ng-container>
</div>
