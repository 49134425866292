<div class="submit-a-report-wrapper">
  <app-datepicker
    [startView]="'month'"
    [dateFormat]="'dd MMMM yyyy'"
    [isSingleDayPreview]="false"
  ></app-datepicker>
  <div
    *ngIf="loaderService.isLoading | async; else elseBlock"
    class="loader-wrapper"
  >
    <app-loader></app-loader>
  </div>

  <ng-template #elseBlock>
    <div>
      <app-report-form
        *ngFor="let groupReport of groupList"
        [groupReport]="groupReport"
      ></app-report-form>
    </div>
  </ng-template>
</div>
