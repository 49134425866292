import { Component, OnInit, Inject } from '@angular/core';
import { WorkStationService } from '../../../../services/work-station.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-status-bar-modal',
  templateUrl: './status-bar-modal.component.html',
  styleUrls: ['./status-bar-modal.component.scss'],
})
export class StatusBarModalComponent implements OnInit {
  selectedGroupStatus: any;
  statusColorIndicator: string;
  machineStatus: string;
  departmentGroups: any;
  iconName: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private workstationService: WorkStationService,
    private router: Router,
    private dialogRef: MatDialog,
  ) {}

  ngOnInit(): void {
    this.workstationService.groupsSubject.subscribe((groups) => {
      this.departmentGroups = groups;
    });
    const {
      groupStatus,
      machineStatus,
      statusColor,
    }: {
      groupStatus: object;
      machineStatus: string;
      statusColor: string;
    } = this.data;

    this.machineStatus = machineStatus;
    this.selectedGroupStatus = groupStatus[machineStatus.toUpperCase()];
    this.statusColorIndicator = statusColor;
    this.getIconName(machineStatus);
  }

  private getIconName(machineStatus): void {
    switch (machineStatus) {
      case 'PRODUCTION':
        this.iconName = 'info';
        break;
      case 'SETUP':
        this.iconName = 'history';
        break;
      case 'NOT CONNECTED':
        this.iconName = 'power_settings_new';
        break;
      case 'PLANNED DOWNTIME':
        this.iconName = 'update';
        break;
      case 'UNPLANNED DOWNTIME':
        this.iconName = 'info';
        break;
      case 'UNDEFINED STOP':
        this.iconName = 'info';
        break;
      default:
        throw new Error('Not existing machine status');
    }
  }

  calculateCycleRate(machine): string {
    const cycleRatePercentage =
      (machine.piecesPerMinute / machine.piecesPerMinuteTarget) * 100;
    return cycleRatePercentage.toFixed(2).toString() + '%';
  }

  closeModal(): void {
    this.dialogRef.closeAll();
  }

  redirectToMachine(machineId, machineSubgroup, machineName): void {
    const getGroup = this.departmentGroups.find((group) => {
      return group.subgroups
        .flatMap((subgroup) => subgroup.machines.map((machine) => machine))
        .find((machine) => machine.workCenter === machineId);
    });
    const redirectURL = `shiftleader-dashboard/realtime-data/department/${
      getGroup.department
    }/group/${
      getGroup.groupId
    }/subgroup/${machineSubgroup}/machine/${machineId}/name/${encodeURIComponent(
      machineName,
    )}`;
    this.router.navigate([redirectURL]);
    this.dialogRef.closeAll();
  }

  getStatusIconColor(): object {
    return {
      color: this.statusColorIndicator,
    };
  }

  getStatusColor(): object {
    return {
      'border-color': this.statusColorIndicator,
    };
  }
}
