import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-details-category-bar',
  templateUrl: './report-details-category-bar.component.html',
  styleUrls: ['./report-details-category-bar.component.scss'],
})
export class ReportDetailsCategoryBarComponent implements OnInit {
  @Input() data;
  @Input() reportType: string;
  safetyBarHeadingList = ['KAT 0:', 'KAT 1:', 'KAT 2:', 'KAT 3:'];
  constructor() {}

  ngOnInit(): void {}
}
