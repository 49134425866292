<div class="report-details-stacked-chart-wrapper">
  <div class="report-details-stacked-chart-heading">{{ chartHeading }}</div>

  <div class="no-data-available" *ngIf="isError else chart">{{ 'REPORT-DETAILS.ERROR-OCCURED' | translate }}</div>
  <ng-template #chart>
    <div *ngIf="barChartData else elseBlock">
      <canvas
        baseChart
        [colors]="chartColors"
        [plugins]="chartPlugins"
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [legend]="barChartLegend"
        [chartType]="barChartType"
      >
      </canvas>
    </div>
    <ng-template #elseBlock>
        <app-loader></app-loader>
    </ng-template>
  </ng-template>
</div>
