<div class="group-status-bar-wrapper">
  <div *ngIf="loaderService.isLoading | async; else elseBlock">
    <app-loader></app-loader>
  </div>
  <ng-template #elseBlock>
    <app-group-status-bar-item
      class="group-status-bar-item"
      appStatusColor
      [groupStatus]="groupStatus"
      [statusData]="'total-machines'"
      [cssClass]="'total machines'"
      text="{{ 'GROUP-STATUS-BAR.TOTAL' }}"
      [number]="totalMachines"
    >
    </app-group-status-bar-item>

    <div *ngFor="let status of groupStatus | keyvalue">
      <app-group-status-bar-item
        [color]="statusColorMap[status.key]?.color"
        text="{{ 'GROUP-STATUS-BAR.' + status.key | translate }}"
        [machineStatus]="status.key"
        [number]="groupStatus[status.key]?.length"
        [groupStatus]="groupStatus"
      >
      </app-group-status-bar-item>
    </div>
  </ng-template>
</div>
