import { Component, OnInit, Input } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { DatePickerService } from '../../services/date-picker.service';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnInit {
  selectedDate: Date;
  @Input() dateFormat = 'dd MMMM yyyy';
  @Input() startView = 'year';
  @Input() isSingleDayPreview = false;
  today = new Date();
  yesterday: Date;

  constructor(private datePickerService: DatePickerService) {}

  ngOnInit(): void {
    this.datePickerService.singleDayDate.subscribe((selectedDate) => {
      this.selectedDate = selectedDate;
    });
    this.setLastBusinessDay();
    this.selectedDate = this.isSingleDayPreview ? this.yesterday : this.today;
  }

  changeDate(eventData: Date): void {
    this.datePickerService.selectedDateSubject.next(eventData);
    this.datePickerService.singleDayDateSubject.next(eventData);
    this.selectedDate = eventData;
  }

  /* istanbul ignore next */
  openDatepicker(dp?: MatDatepicker<Date>): void {
    dp.open();
  }

  setLastBusinessDay(): void {
    const date = new Date();
    this.yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    while (!this.isBusinessDay(date)) {
      date.setDate(date.getDate() - 1);
      this.yesterday = date;
    }
  }

  isBusinessDay(date): boolean {
    const day = date.getDay();
    return !(day === 0 || day === 6 || day === 1);
  }
}
