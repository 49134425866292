<div class="overview-wrapper">
  <div>
    <div class="header-title">{{ 'TOOL_TABLE.HEADER_TITLE' | translate }}</div>
    <table
      mat-table
      [dataSource]="toolData"
      multiTemplateDataRows
      class="mat-elevation-z8"
    >
      <ng-container
        matColumnDef="{{ column.columnDef }}"
        *ngFor="let column of columns"
      >
        <th mat-header-cell *matHeaderCellDef>{{ column.header }}</th>
        <td mat-cell *matCellDef="let element">{{ column.cell(element) }}</td>
      </ng-container>

      <!--Expanded Content Column -->
      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="displayedColumns.length"
        >
          <div
            class="element-detail"
            [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
            "
          >
            <div class="empty-div"></div>
            <div class="expanded-content">
              <div class="header-wrapper">
                <div
                  *ngFor="let column of columns.slice(6)"
                  class="header-item"
                >
                  <div class="header-content">{{ column.header }}</div>
                </div>
              </div>
              <div
                *ngFor="let prodOrder of element?.ProductionOrders"
                class="expanded-details"
              >
                <div class="details-item">
                  {{ prodOrder?.ProductionOrder }}
                </div>
                <div class="details-item">{{ prodOrder?.Material }}</div>
                <div class="details-item">{{ prodOrder?.WorkCenter }}</div>
                <div class="details-item">{{ prodOrder?.Punches }}</div>
                <div class="details-item">{{ prodOrder?.Status }}</div>
              </div>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: displayedColumns"
        class="element-row"
        [class.expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="detail-row"
      ></tr>
    </table>
  </div>
</div>
