import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-realtime-data',
  templateUrl: './realtime-data.component.html',
  styleUrls: ['./realtime-data.component.scss'],
})
export class RealtimeDataComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
