<div class="production-overview-chart-wrapper">
  <div class="percentage-overview">
    <div class="period">
      <div>{{ productionOverviewData?.duration }}</div>
    </div>
    <div class="percentage">
      <span class="fw-semibold numbers"
        >{{ productionOverviewData?.currentlyProducedPercentage || '' }}%</span
      >&nbsp;
      <span class="numbers numbers-percent"></span>
    </div>
  </div>
  <div class="production-wrapper">
    <div class="production-info">
      <span class="production-text">{{
        'PRODUCTION-OVERVIEW-CHART.CURRENTLY-PRODUCED' | translate
      }}</span>
      <span class="fw-semibold numbers">{{
        productionOverviewData?.currentlyProduced || '' | number
      }}</span>
    </div>
    <span> <hr class="divider" /></span>
    <div class="production-info">
      <span class="production-text">{{
        'PRODUCTION-OVERVIEW-CHART.TARGET-PRODUCTION' | translate
      }}</span>
      <span class="numbers">{{
        productionOverviewData?.targetProduction || '' | number
      }}</span>
    </div>
  </div>
  <div
    *ngIf="isNumber(productionOverviewData?.currentlyProduced); else elseBlock"
    class="chart-wrapper"
  >
    <app-produced-pieces-chart
      class="produced-pieces-chart"
      [lineChartData]="productionOverviewData?.lineChartData"
      [lineChartOptions]="productionOverviewData?.lineChartOptions"
      [lineChartColors]="lineChartColors"
    ></app-produced-pieces-chart>
  </div>
  <ng-template #elseBlock>
    <app-loader></app-loader>
  </ng-template>
</div>
