import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/services/shared.service';
import { Router } from '@angular/router';
import { LanguageCode } from 'src/app/enums/language-code.enum';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: [
    './language-selector.component.scss',
    '../../../styles/shared-classes.scss',
  ],
})
export class LanguageSelectorComponent implements OnInit {
  languages = [
    { languageCode: LanguageCode.English, languageName: 'English' },
    { languageCode: LanguageCode.German, languageName: 'German' },
    { languageCode: LanguageCode.Chinese, languageName: 'Chinese' },
    { languageCode: LanguageCode.Spanish, languageName: 'Spanish' },
  ];
  selectedLanguage: string;
  titleLanguage: string;

  constructor(
    public translate: TranslateService,
    private sharedService: SharedService,
    public router: Router,
  ) {}

  ngOnInit(): void {
    this.setDefaultLanguage();
  }

  setDefaultLanguage(): void {
    this.translate.addLangs(this.languages.map((lang) => lang.languageCode));
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      this.selectedLanguage = browserLang;
      this.titleLanguage = this.languages.find(
        (lang) => lang.languageCode === browserLang,
      )?.languageName;
      this.translate.use(
        browserLang.match(this.sharedService.languageCodesRgx)
          ? browserLang
          : LanguageCode.English,
      );
    } else {
      this.sharedService.setDefaultBrowserLanguage();
      localStorage.setItem(
        'locale',
        this.sharedService.setDefaultBrowserLanguage(),
      );
    }
  }

  changeLang(language: string, languageName: string): void {
    localStorage.setItem('locale', language);
    this.translate.use(language);
    this.titleLanguage = languageName;
    this.selectedLanguage = language;
    this.windowReload();
  }

  /* istanbul ignore next */
  private windowReload(): void {
    window.location.reload();
  }
}
