import { ChartsModule } from 'ng2-charts';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HistoricalDataRoutingModule } from './historical-data-routing.module';
import { HistoricalDataComponent } from './historical-data.component';
import { ReportsComponent } from './reports/reports.component';
import { SubmitAReportComponent } from './submit-a-report/submit-a-report.component';
import { DayNodeComponent } from './reports/day-node/day-node.component';
import { CalendarViewComponent } from './reports/calendar-view/calendar-view.component';
import { MaterialModule } from 'src/app/material/material.module';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { DepartmentCalendarViewComponent } from './reports/department-calendar-view/department-calendar-view.component';
import { GroupCalendarViewComponent } from './reports/group-calendar-view/group-calendar-view.component';
import { MachineCalendarViewComponent } from './reports/machine-calendar-view/machine-calendar-view.component';
import { DropdownSelectComponent } from './../../components/dropdown-select/dropdown-select.component';
import { DatepickerComponent } from './../../components/datepicker/datepicker.component';
import { ReportDetailsComponent } from './reports/report-details/report-details.component';
import { ReportDetailsCategoryBarComponent } from './reports/report-details/report-details-category-bar/report-details-category-bar.component';
import { ReportDetailsStackedChartComponent } from './reports/report-details/report-details-stacked-chart/report-details-stacked-chart.component';
import { ReportFormComponent } from './submit-a-report/report-form/report-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SubgroupCalendarViewComponent } from './reports/subgroup-calendar-view/subgroup-calendar-view.component';
import { ReportDetailsItemComponent } from './reports/report-details/report-details-item/report-details-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { SingleDayPreviewComponent } from './reports/single-day-preview/single-day-preview.component';
import { SubKpiComponent } from './reports/single-day-preview/sub-kpi/sub-kpi.component';
import { LetterCalendarComponent } from './reports/letter-calendar/letter-calendar.component';
import { SingleLetterCalendarComponent } from './reports/letter-calendar/single-letter-calendar/single-letter-calendar.component';

@NgModule({
  declarations: [
    HistoricalDataComponent,
    ReportsComponent,
    SubmitAReportComponent,
    DayNodeComponent,
    CalendarViewComponent,
    DepartmentCalendarViewComponent,
    GroupCalendarViewComponent,
    MachineCalendarViewComponent,
    DropdownSelectComponent,
    DatepickerComponent,
    ReportDetailsComponent,
    ReportDetailsCategoryBarComponent,
    ReportDetailsStackedChartComponent,
    ReportFormComponent,
    SubgroupCalendarViewComponent,
    ReportDetailsItemComponent,
    SingleDayPreviewComponent,
    SubKpiComponent,
    LetterCalendarComponent,
    SingleLetterCalendarComponent,
  ],
  imports: [
    CommonModule,
    HistoricalDataRoutingModule,
    MaterialModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslateModule.forChild({
      extend: true,
    }),
  ],
})
export class HistoricalDataModule {}
