import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sub-kpi',
  templateUrl: './sub-kpi.component.html',
  styleUrls: ['./sub-kpi.component.scss'],
})
export class SubKpiComponent implements OnInit {
  @Input() subKpi: any;
  @Input() isTargetFulfilled: any;
  @Input() target: any;
  status: number;
  statusColor: string;

  constructor() {}

  ngOnInit(): void {
    if (this.subKpi) {
      this.status = this.subKpi.reduce((acc, cur) => {
        return Object.values(cur)[0] + acc;
      }, 0);
      this.getStatusColor();
    }
  }

  getStatusColor(): void {
    if (this.isTargetFulfilled) {
      this.statusColor = 'status-green';
    } else {
      this.statusColor = 'status-red';
    }
  }
}
