import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { AuthService } from 'src/app/services/auth.service';
import { KpiService } from './../../../../services/kpi.service';
import { DatePickerService } from './../../../../services/date-picker.service';
import { SnackBarComponent } from './../../../../components/snack-bar/snack-bar.component';
@Component({
  selector: 'app-report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.scss'],
})
export class ReportFormComponent implements OnInit {
  @Input() groupReport;
  reportForm: FormGroup;
  isSubmitted: boolean;
  kpiDate: string;

  constructor(
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private datepickerService: DatePickerService,
    private authService: AuthService,
    private kpiService: KpiService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.groupReport.id) {
      this.isSubmitted = true;
    }
    this.datepickerService.selectedDate.subscribe((response) => {
      this.kpiDate = format(response, 'yyyy-MM-dd');
      this.initReportForm();
    });
  }

  private initReportForm(): void {
    this.reportForm = this.formBuilder.group({
      headcountEmployees: [
        this.groupReport?.headcountEmployees,
        [Validators.min(0)],
      ],
      safetyCat0: [this.groupReport?.safetyCat0, Validators.min(0)],
      safetyCat1: [this.groupReport?.safetyCat1, Validators.min(0)],
      safetyCat2: [this.groupReport?.safetyCat2, Validators.min(0)],
      safetyCat3: [this.groupReport?.safetyCat3, Validators.min(0)],
      cleanlinessIssues: [
        this.groupReport?.cleanlinessIssues,
        Validators.min(0),
      ],
      kpiDate: [this.kpiDate],
      user: [this.groupReport?.user],
      groupName: [this.groupReport?.groupName],
      id: [this.groupReport?.id],
    });
  }

  checkIfValuesAreEmpty(): void {
    for (let i = 0; i <= 3; i++) {
      if (!this.reportForm.get('safetyCat' + i).value) {
        this.reportForm.get('safetyCat' + i).patchValue(0);
      }
    }
    if (!this.reportForm.get('headcountEmployees').value) {
      this.reportForm.get('headcountEmployees').patchValue(0);
    }
    if (!this.reportForm.get('cleanlinessIssues').value) {
      this.reportForm.get('cleanlinessIssues').patchValue(0);
    }
  }

  resetForm(): void {
    this.isSubmitted
      ? (this.reportForm.patchValue(this.groupReport),
        this.reportForm.markAsPristine())
      : this.reportForm.reset();
  }

  onSubmit(): void {
    this.openSnackBar();
    this.isSubmitted = true;
    this.checkIfValuesAreEmpty();
    this.reportForm.markAsPristine();

    this.reportForm.controls.user.setValue(
      this.authService?.user?.attributes?.email,
    );
    this.reportForm.controls.kpiDate.setValue(this.kpiDate);
    this.reportForm.controls.groupName.setValue(this.groupReport.groupName);

    this.kpiService
      .createHistoricalDataReport(this.reportForm.value)
      .then(() => {
        this.groupReport = this.reportForm.value;
      })
      .catch();
  }

  private openSnackBar(): void {
    this.snackBar.openFromComponent(SnackBarComponent, {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: 3000,
      data: {
        message: this.translate.instant('REPORT-FORM.SNACKBAR'),
      },
    });
  }
}
