import { Directive, ElementRef, Input, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[appStatusColor]',
})
export class StatusColorDirective implements OnInit {
  @Input() statusData: string;

  constructor(private renderer: Renderer2, private el: ElementRef) {}

  ngOnInit(): void {
    this.renderer.addClass(this.el.nativeElement, this.getStatusCSSClass());
  }

  getStatusCSSClass(): string {
    return this.statusData
      ? this.statusData?.replace(/\s+/g, '-').toLowerCase()
      : 'not-connected';
  }
}
