<div class="timeline-actions-wrapper">
  <mat-button-toggle-group
    class="timeline-action-btns"
    aria-label="Hours"
    [value]="selectedHours"
    (change)="onChangeHours($event)"
  >
    <mat-button-toggle value="2">2 hrs</mat-button-toggle>
    <mat-button-toggle value="4">4 hrs</mat-button-toggle>
  </mat-button-toggle-group>

  <button
    [disabled]="isLoadingPeriods"
    type="button"
    class="button"
    mat-flat-button
    (click)="onChangeNavigation('backward')"
  >
    <mat-icon class="mat-icon">fast_rewind</mat-icon>
  </button>
  <button
    [disabled]="isLoadingPeriods"
    type="button"
    class="button"
    mat-flat-button
    (click)="onChangeNavigation('forward')"
  >
    <mat-icon class="mat-icon">fast_forward</mat-icon>
  </button>

  <button
    [disabled]="isLoadingPeriods"
    class="button"
    (click)="onReset()"
    mat-flat-button
  >
    <mat-icon class="mat-icon">replay</mat-icon>
  </button>

  <div class="timeline-date-time">
    <div class="timeline-hours">{{ hourNow }}</div>
    <div class="vertical-divider"></div>
    <div class="timeline-date">{{ selectedDateTime | date: 'shortDate' }}</div>
  </div>
</div>
