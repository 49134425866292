import { Inject, Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';
import { SharedService } from 'src/app/services/shared.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  configPath = '/config';
  configDataSubject = new BehaviorSubject(null);
  configData: Observable<any> = this.configDataSubject.asObservable();

  constructor(
    @Inject('SMSF_API_NAME') private apiName: string,
    private sharedService: SharedService,
    private loaderService: LoaderService,
  ) {}

  getConfig(): void {
    const init = {
      headers: {},
      response: true,
      queryStringParameters: {
        plantId: this.sharedService.getPlantId(),
      },
    };
    API.get(this.apiName, this.configPath, init).then((response) => {
      this.configDataSubject.next(response?.data);
    });
  }

  setMachineStatusCategory(statusCode, config): any {
    let statusCategory = '';
    let color = '';

    this.loaderService.showLoader();
    if (config) {
      if (!(statusCode in config?.machineStatus)) {
        statusCode = 0;
      }
      statusCategory = config?.machineStatus[statusCode]?.StatusCategory;
      color = config?.machineOverview[statusCategory]?.color;
    }

    if (statusCategory.length > 1 || color.length > 1) {
      this.loaderService.hideLoader();
    }
    return { statusCategory, color };
  }
}
