import { Auth } from 'aws-amplify';
import { environment } from './environments/environment';

const awsconfig = {
  Auth: {
    identityPoolId: environment.awsCognitoIdentityPoolId,
    region: environment.region,
    userPoolId: environment.awsUserPoolsId,
    userPoolWebClientId: environment.awsUserPoolsWebClientId,
    authenticationFlowType: 'CUSTOM_AUTH',
  },
  API: {
    endpoints: [
      {
        name: 'ApiGatewayWithCors',
        endpoint: environment.apiGatewayEndpoint,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${await getCurrentSessionToken()}`,
          };
        },
      },
      {
        name: 'EasyToolLifeApi',
        endpoint: environment.etlApiGatewayEndpoint,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${await getCurrentSessionToken()}`,
          };
        },
      },
      {
        name: 'ApiGatewayEndpointDataLake',
        endpoint: environment.apiGatewayEndpointDataLake,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${await getCurrentSessionToken()}`,
          };
        },
      },
    ],
  },
  aws_appsync_graphqlEndpoint: environment.apiGraphQLEndpoint,
  aws_appsync_region: environment.region,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};

async function getCurrentSessionToken() {
  return (await Auth.currentSession()).getIdToken().getJwtToken();
}

export default awsconfig;
