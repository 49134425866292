import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { API } from 'aws-amplify';
import { SharedService } from 'src/app/services/shared.service';

@Injectable({
  providedIn: 'root',
})
export class PlannedPiecesService {
  plannedPiecesShiftSubject = new BehaviorSubject<any>(null);
  plannedPiecesShift: Observable<
    any
  > = this.plannedPiecesShiftSubject.asObservable();
  plannedPiecesDaySubject = new BehaviorSubject<any>(null);
  plannedPiecesDay: Observable<
    any
  > = this.plannedPiecesDaySubject.asObservable();
  shiftLength = 8;
  dayLength = 24;
  plantId = this.sharedService.getPlantId();

  constructor(
    @Inject('SMSF_API_NAME') private apiName: string,
    private sharedService: SharedService,
  ) {}

  async getPlannedPiecesByLength(length): Promise<object> {
    const init = {
      headers: {},
      response: true,
      queryStringParameters: {
        length,
        plantId: this.plantId,
      },
    };
    return API.get(this.apiName, `/department/planned-pieces`, init);
  }

  fetchPlannedPiecesData(): void {
    this.getPlannedPiecesByLength(this.shiftLength)
      .then((result: any) => {
        this.plannedPiecesShiftSubject.next(result.data);
      })
      .catch();
    this.getPlannedPiecesByLength(this.dayLength)
      .then((result: any) => {
        this.plannedPiecesDaySubject.next(result.data);
      })
      .catch();
  }
}
