<div class="heading">{{ 'DATEPICKER.SELECT-DATE' | translate }}</div>
<div>
  <mat-card class="datepicker-wrapper" (click)="openDatepicker(picker1)">
    <mat-datepicker-toggle
      class="datepicker-display"
      matSuffix
      [for]="picker1"
    ></mat-datepicker-toggle>
    <mat-form-field class="form-field">
      <input
        matInput
        [matDatepicker]="picker1"
        class="date-input"
        (dateChange)="changeDate($event.value)"
        [max]="isSingleDayPreview ? yesterday : today"
        [value]="selectedDate"
      />
      <mat-datepicker
        (daySelected)="changeDate($event)"
        #picker1
        [startView]="startView"
      >
      </mat-datepicker>
    </mat-form-field>
    <div class="datepicker-display">
      {{ selectedDate | date: [dateFormat] }}
    </div>
  </mat-card>
</div>
