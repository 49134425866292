import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-report-details-item',
  templateUrl: './report-details-item.component.html',
  styleUrls: ['./report-details-item.component.scss'],
})
export class ReportDetailsItemComponent implements OnInit {
  @Input() data;
  @Input() reportType: string;
  @Input() comparisonLogic: string;
  @Input() isYearlyError: boolean;
  @Input() isMonthlyError: boolean;

  chartMonthLabels: string[] = [
    this.tranlsateMonth('CALENDAR.JAN'),
    this.tranlsateMonth('CALENDAR.FEB'),
    this.tranlsateMonth('CALENDAR.MAR'),
    this.tranlsateMonth('CALENDAR.APR'),
    this.tranlsateMonth('CALENDAR.MAY'),
    this.tranlsateMonth('CALENDAR.JUN'),
    this.tranlsateMonth('CALENDAR.JUL'),
    this.tranlsateMonth('CALENDAR.AUG'),
    this.tranlsateMonth('CALENDAR.SEP'),
    this.tranlsateMonth('CALENDAR.OCT'),
    this.tranlsateMonth('CALENDAR.NOV'),
    this.tranlsateMonth('CALENDAR.DEC'),
  ];

  getChartMonthLabels(): number[] {
    return this.data[0].monthlyData[0]?.data
      ? this.data[0].monthlyData[0].data.map((day, index) => index + 1)
      : [];
  }

  constructor(
    public loaderService: LoaderService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {}

  isStackedBar(reportIndex: number): boolean {
    return (
      this.reportType === 'Safety' ||
      (this.reportType === 'Quality' && reportIndex === 0) ||
      this.reportType === 'Cleanliness'
    );
  }

  getComparisonLogic(): string {
    return this.reportType === 'Costs' ||
      this.reportType === 'Quality' ||
      this.reportType === 'Delivery'
      ? 'lower is better'
      : 'higher is better';
  }

  tranlsateMonth(monthKey: string): string {
    let translatedMonth = '';
    this.translate.stream(monthKey).subscribe((translated) => {
      translatedMonth = translated;
    });
    return translatedMonth;
  }
}
