import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tool-lifetime',
  templateUrl: './tool-lifetime.component.html',
  styleUrls: ['./tool-lifetime.component.scss'],
})
export class ToolLifetimeComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
