import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { format, startOfDay, getHours } from 'date-fns';

@Component({
  selector: 'app-oee-chart',
  templateUrl: './oee-chart.component.html',
  styleUrls: ['./oee-chart.component.scss'],
})
export class OeeChartComponent implements OnInit {
  public oeeAverage = 67;

  // value needed so that the chart would start from 00:00
  private dayStartData = { x: startOfDay(new Date()) };

  // random data that will be served from backend
  private randomData = Array.from(
    new Array(getHours(new Date()) + 1),
    (x, i) => {
      return {
        x: new Date(startOfDay(new Date()).getTime() + i * 60 * 60000),
        y: Math.floor(Math.random() * 80),
      };
    },
  );

  // value needed ot have one hour after the latest data on graph
  private oneHourAfterNowData = {
    x: new Date(new Date().getTime() + 64 * 60000),
  };

  // chart data that is currently feed by random data
  public lineChartData: ChartDataSets[] = [
    {
      data: [this.dayStartData, ...this.randomData, this.oneHourAfterNowData],
      label: 'Shift 1',
    },
  ];

  // chart display settings
  public lineChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      labels: {
        boxWidth: 0,
        fontSize: 20,
      },
      onClick: () => {},
    },
    tooltips: {
      displayColors: false,
      callbacks: {
        title: () => null,
        label: (tooltipItem, data) => {
          return `OEE: ${tooltipItem.value}%`;
        },
      },
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          distribution: 'linear',
          position: 'top',
          gridLines: {
            color: 'white',
          },
          ticks: {
            maxRotation: 90,
            minRotation: 80,
          },
          time: {
            tooltipFormat: 'HH:mm',
            unit: 'minute',
            stepSize: 60,
            displayFormats: {
              minute: 'HH:mm',
              hour: 'HH:mm',
            },
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            suggestedMax: 100,
            // show horizontal lines only for 0% 50% and 100%
            callback: (value, index, values) => {
              return value === 0 || value === 50 || value === 100 ? ' ' : null;
            },
          },
        },
      ],
    },
  };
  public lineChartColors: any[] = [
    {
      borderColor: '#E74C3C',
      backgroundColor: 'rgba(255,0,0,0.3)',
    },
  ];
  public lineChartType: any = 'line';

  constructor() {}

  ngOnInit(): void {}
}
