import { Component, Input, OnInit } from '@angular/core';
import { ProductionOverviewDataModel } from '../../../model/production-overview-data.model';

@Component({
  selector: 'app-production-overview-chart',
  templateUrl: './production-overview-chart.component.html',
  styleUrls: ['./production-overview-chart.component.scss'],
})
export class ProductionOverviewChartComponent implements OnInit {
  @Input() productionOverviewData: ProductionOverviewDataModel;
  @Input() lineChartColors;

  constructor() {}

  ngOnInit(): void {}

  isNumber(value): boolean {
    return typeof value === 'number';
  }
}
