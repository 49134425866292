/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type __SubscriptionContainer = {
  onCreateProductionOrderHeader: OnCreateProductionOrderHeaderSubscription;
  onCreateProductionOrderOperation: OnCreateProductionOrderOperationSubscription;
  onCreateProductionOrderListItem: OnCreateProductionOrderListItemSubscription;
  onCreateProductionOrderComment: OnCreateProductionOrderCommentSubscription;
  onCreateProductionOrderCommentAny: OnCreateProductionOrderCommentAnySubscription;
  onCreateWorkCenterPeriods: OnCreateWorkCenterPeriodsSubscription;
  onCreateWorkCenterStatus: OnCreateWorkCenterStatusSubscription;
  onCreateConfig: OnCreateConfigSubscription;
  onCreateKpi: OnCreateKpiSubscription;
  onDeleteProductionOrderHeader: OnDeleteProductionOrderHeaderSubscription;
  onDeleteProductionOrderOperation: OnDeleteProductionOrderOperationSubscription;
  onDeleteProductionOrderComment: OnDeleteProductionOrderCommentSubscription;
  onDeleteWorkCenterPeriods: OnDeleteWorkCenterPeriodsSubscription;
  onDeleteWorkCenterStatus: OnDeleteWorkCenterStatusSubscription;
  onDeleteConfig: OnDeleteConfigSubscription;
  onDeleteKpi: OnDeleteKpiSubscription;
  onUpdateProductionOrderHeader: OnUpdateProductionOrderHeaderSubscription;
  onUpdateProductionOrderOperationList: OnUpdateProductionOrderOperationListSubscription;
  onUpdateWorkCenterPeriods: OnUpdateWorkCenterPeriodsSubscription;
  onUpdateWorkCenterStatus: OnUpdateWorkCenterStatusSubscription;
  onUpdateConfig: OnUpdateConfigSubscription;
};

export type CreateProductionOrderHeaderInput = {
  consumed_containers?: number | null;
  consumed_containers_unit?: string | null;
  consumed_quantity?: number | null;
  consumed_quantity_unit?: string | null;
  material?: string | null;
  material_description?: string | null;
  material_weight?: number | null;
  material_weight_unit?: string | null;
  modification_date?: number | null;
  plant: string;
  production_order: string;
  raw_material?: string | null;
  raw_material_batch_number?: string | null;
  raw_material_size?: number | null;
  production_order_batch?: string | null;
  sttxt?: string | null;
  ttl?: number | null;
};

export type ProductionOrderHeader = {
  __typename: "ProductionOrderHeader";
  production_order: string;
  plant?: string | null;
  modification_date?: number | null;
  sttxt?: string | null;
  material?: string | null;
  material_description?: string | null;
  material_weight?: number | null;
  material_weight_unit?: string | null;
  raw_material_batch_number?: string | null;
  raw_material?: string | null;
  raw_material_size?: number | null;
  production_order_batch?: string | null;
  consumed_containers?: number | null;
  consumed_containers_unit?: string | null;
  consumed_quantity?: number | null;
  consumed_quantity_unit?: string | null;
  ttl?: number | null;
};

export type CreateProductionOrderOperationInput = {
  asttx?: string | null;
  confirmed_quantity?: number | null;
  darue?: number | null;
  department?: string | null;
  modification_date?: number | null;
  operation?: number | null;
  press_family?: string | null;
  priority?: number | null;
  production_order: string;
  quantity_unit?: string | null;
  scheduled_end?: number | null;
  scheduled_start?: number | null;
  actual_end?: number | null;
  actual_start?: number | null;
  speed_unit?: string | null;
  target_quantity?: number | null;
  target_speed?: number | null;
  vsttxt?: string | null;
  waste_quantity?: number | null;
  workcenter?: string | null;
  status?: string | null;
  comment?: string | null;
  material?: string | null;
  material_description?: string | null;
  average_speed?: number | null;
  raw_material?: string | null;
  raw_material_batch_number?: string | null;
  production_order_batch?: string | null;
  sttxt?: string | null;
  setup_time?: number | null;
  setup_time_unit?: string | null;
  tool_available?: number | null;
  ttl?: number | null;
};

export type ProductionOrderOperation = {
  __typename: "ProductionOrderOperation";
  production_order: string;
  operation?: number | null;
  department?: string | null;
  workcenter?: string | null;
  press_family?: string | null;
  modification_date?: number | null;
  vsttxt?: string | null;
  asttx?: string | null;
  darue?: number | null;
  target_speed?: number | null;
  speed_unit?: string | null;
  target_quantity?: number | null;
  waste_quantity?: number | null;
  confirmed_quantity?: number | null;
  quantity_unit?: string | null;
  priority?: number | null;
  scheduled_start?: number | null;
  scheduled_end?: number | null;
  actual_end?: number | null;
  actual_start?: number | null;
  status?: string | null;
  comment?: string | null;
  comments?: ProductionOrderCommentConnection | null;
  material?: string | null;
  material_description?: string | null;
  average_speed?: number | null;
  raw_material?: string | null;
  raw_material_batch_number?: string | null;
  production_order_batch?: string | null;
  sttxt?: string | null;
  setup_time?: number | null;
  setup_time_unit?: string | null;
  tool_available?: number | null;
  ttl?: number | null;
};

export type ProductionOrderCommentConnection = {
  __typename: "ProductionOrderCommentConnection";
  items?: Array<ProductionOrderComment | null> | null;
  nextToken?: string | null;
};

export type ProductionOrderComment = {
  __typename: "ProductionOrderComment";
  production_order: string;
  operation: number;
  timestamp?: number | null;
  comment: string;
  operator: string;
  ttl?: number | null;
};

export type CreateProductionOrderCommentInput = {
  production_order: string;
  operation: number;
  comment: string;
  operator: string;
};

export type CreateWorkCenterPeriodsInput = {
  end?: number | null;
  index: string;
  pieces?: number | null;
  start: number;
  status?: string | null;
  location?: number | null;
  shift_date?: string | null;
  shift_number?: number | null;
  ttl?: number | null;
  production_order?: string | null;
  material?: string | null;
  speed_target?: number | null;
  speed_unit?: string | null;
  operation?: number | null;
  pieces_order?: number | null;
  pieces_day?: number | null;
  pieces_shift?: number | null;
  flag?: number | null;
};

export type WorkCenterPeriods = {
  __typename: "WorkCenterPeriods";
  end?: number | null;
  index: string;
  pieces?: number | null;
  start: number;
  status?: string | null;
  location?: number | null;
  shift_date?: string | null;
  shift_number?: number | null;
  ttl?: number | null;
  production_order?: string | null;
  material?: string | null;
  speed_target?: number | null;
  speed_unit?: string | null;
  operation?: number | null;
  pieces_order?: number | null;
  pieces_day?: number | null;
  pieces_shift?: number | null;
  flag?: number | null;
};

export type CreateWorkCenterStatusInput = {
  plant: string;
  workcenter: string;
  name?: string | null;
  status?: string | null;
  timestamp?: number | null;
  current_period?: number | null;
  shift_date?: string | null;
  shift_number?: number | null;
  shift_start?: number | null;
  shift_end?: number | null;
  shift_plan?: string | null;
  production_order?: string | null;
  material?: string | null;
  speed_actual?: number | null;
  speed_target?: number | null;
  speed_unit?: string | null;
  operation?: number | null;
  ttl?: number | null;
  pieces_plan_day?: string | null;
  pieces_plan_shift?: string | null;
  last_production_at?: number | null;
};

export type WorkCenterStatus = {
  __typename: "WorkCenterStatus";
  plant: string;
  workcenter: string;
  name?: string | null;
  status?: string | null;
  timestamp?: number | null;
  current_period?: number | null;
  shift_date?: string | null;
  shift_number?: number | null;
  shift_start?: number | null;
  shift_end?: number | null;
  shift_plan?: string | null;
  production_order?: string | null;
  material?: string | null;
  speed_actual?: number | null;
  speed_target?: number | null;
  speed_unit?: string | null;
  operation?: number | null;
  ttl?: number | null;
  pieces_plan_day?: string | null;
  pieces_plan_shift?: string | null;
  last_production_at?: number | null;
};

export type CreateConfigInput = {
  key: string;
  value?: string | null;
};

export type Config = {
  __typename: "Config";
  key: string;
  value?: string | null;
};

export type CreateKpiInput = {
  index: string;
  time?: number | null;
  location?: string | null;
  kpi?: string | null;
  period?: string | null;
  value?: string | null;
  target?: string | null;
  unit?: string | null;
  ttl?: number | null;
};

export type Kpi = {
  __typename: "Kpi";
  index: string;
  time?: number | null;
  location?: string | null;
  kpi?: string | null;
  period?: string | null;
  value?: string | null;
  target?: string | null;
  unit?: string | null;
  ttl?: number | null;
};

export type DeleteProductionOrderHeaderInput = {
  plant: string;
  production_order: string;
};

export type DeleteProductionOrderOperationInput = {
  operation: number;
  production_order: string;
};

export type DeleteProductionOrderCommentInput = {
  production_order: string;
  timestamp: number;
};

export type DeleteWorkCenterPeriodsInput = {
  index: string;
  start: number;
};

export type DeleteWorkCenterStatusInput = {
  plant: string;
  workcenter: string;
};

export type DeleteConfigInput = {
  key: string;
  value?: string | null;
};

export type DeleteKpiInput = {
  index: string;
  time?: number | null;
  location?: string | null;
  kpi?: string | null;
  period?: string | null;
  value?: string | null;
  target?: string | null;
  unit?: string | null;
  ttl?: number | null;
};

export type UpdateProductionOrderHeaderInput = {
  consumed_containers?: number | null;
  consumed_containers_unit?: string | null;
  consumed_quantity?: number | null;
  consumed_quantity_unit?: string | null;
  material?: string | null;
  material_description?: string | null;
  material_weight?: number | null;
  material_weight_unit?: string | null;
  modification_date?: number | null;
  plant: string;
  production_order: string;
  raw_material?: string | null;
  raw_material_batch_number?: string | null;
  raw_material_size?: number | null;
  production_order_batch?: string | null;
  sttxt?: string | null;
  ttl?: number | null;
};

export type UpdateProductionOrderOperationInput = {
  asttx?: string | null;
  confirmed_quantity?: number | null;
  darue?: number | null;
  department?: string | null;
  modification_date?: number | null;
  operation?: number | null;
  press_family?: string | null;
  priority?: number | null;
  production_order: string;
  quantity_unit?: string | null;
  scheduled_end?: number | null;
  scheduled_start?: number | null;
  actual_end?: number | null;
  actual_start?: number | null;
  speed_unit?: string | null;
  target_quantity?: number | null;
  target_speed?: number | null;
  vsttxt?: string | null;
  waste_quantity?: number | null;
  workcenter?: string | null;
  status?: string | null;
  comment?: string | null;
  material?: string | null;
  material_description?: string | null;
  average_speed?: number | null;
  raw_material?: string | null;
  raw_material_batch_number?: string | null;
  production_order_batch?: string | null;
  sttxt?: string | null;
  setup_time?: number | null;
  setup_time_unit?: string | null;
  tool_available?: number | null;
  ttl?: number | null;
};

export type UpdateWorkCenterPeriodsInput = {
  end?: number | null;
  index: string;
  pieces?: number | null;
  start: number;
  status?: string | null;
  location?: number | null;
  shift_date?: string | null;
  shift_number?: number | null;
  ttl?: number | null;
  production_order?: string | null;
  material?: string | null;
  speed_target?: number | null;
  speed_unit?: string | null;
  operation?: number | null;
  pieces_order?: number | null;
  pieces_day?: number | null;
  pieces_shift?: number | null;
  flag?: number | null;
};

export type UpdateWorkCenterStatusInput = {
  plant: string;
  workcenter: string;
  name?: string | null;
  status?: string | null;
  timestamp?: number | null;
  current_period?: number | null;
  shift_date?: string | null;
  shift_number?: number | null;
  shift_start?: number | null;
  shift_end?: number | null;
  shift_plan?: string | null;
  production_order?: string | null;
  material?: string | null;
  speed_actual?: number | null;
  speed_target?: number | null;
  speed_unit?: string | null;
  operation?: number | null;
  ttl?: number | null;
  pieces_plan_day?: string | null;
  pieces_plan_shift?: string | null;
  last_production_at?: number | null;
};

export type UpdateConfigInput = {
  key: string;
  value?: string | null;
};

export type SplitWorkCenterPeriodsInput = {
  index: string;
  start: number;
  end: number;
  status: string;
  location?: number | null;
};

export type WorkCenterPeriodsConnection = {
  __typename: "WorkCenterPeriodsConnection";
  items?: Array<WorkCenterPeriods | null> | null;
  nextToken?: string | null;
};

export type TableProductionOrderHeaderFilterInput = {
  consumed_containers?: TableIntFilterInput | null;
  consumed_containers_unit?: TableStringFilterInput | null;
  consumed_quantity?: TableIntFilterInput | null;
  consumed_quantity_unit?: TableStringFilterInput | null;
  material?: TableStringFilterInput | null;
  material_description?: TableStringFilterInput | null;
  material_weight?: TableFloatFilterInput | null;
  material_weight_unit?: TableStringFilterInput | null;
  modification_date?: TableFloatFilterInput | null;
  plant?: TableStringFilterInput | null;
  production_order?: TableStringFilterInput | null;
  raw_material?: TableStringFilterInput | null;
  raw_material_batch_number?: TableStringFilterInput | null;
  raw_material_size?: TableFloatFilterInput | null;
  production_order_batch?: TableStringFilterInput | null;
  sttxt?: TableStringFilterInput | null;
  ttl?: TableIntFilterInput | null;
};

export type TableIntFilterInput = {
  between?: Array<number | null> | null;
  contains?: number | null;
  eq?: number | null;
  ge?: number | null;
  gt?: number | null;
  le?: number | null;
  lt?: number | null;
  ne?: number | null;
  notContains?: number | null;
};

export type TableStringFilterInput = {
  beginsWith?: string | null;
  between?: Array<string | null> | null;
  contains?: string | null;
  eq?: string | null;
  ge?: string | null;
  gt?: string | null;
  le?: string | null;
  lt?: string | null;
  ne?: string | null;
  notContains?: string | null;
};

export type TableFloatFilterInput = {
  between?: Array<number | null> | null;
  contains?: number | null;
  eq?: number | null;
  ge?: number | null;
  gt?: number | null;
  le?: number | null;
  lt?: number | null;
  ne?: number | null;
  notContains?: number | null;
};

export type ProductionOrderHeaderConnection = {
  __typename: "ProductionOrderHeaderConnection";
  items?: Array<ProductionOrderHeader | null> | null;
  nextToken?: string | null;
};

export type ProductionOrderOperationConnection = {
  __typename: "ProductionOrderOperationConnection";
  items?: Array<ProductionOrderOperation | null> | null;
  nextToken?: string | null;
};

export type TableWorkCenterPeriodsFilterInput = {
  end?: TableFloatFilterInput | null;
  index?: TableStringFilterInput | null;
  pieces?: TableIntFilterInput | null;
  start?: TableFloatFilterInput | null;
  status?: TableStringFilterInput | null;
  location?: TableIntFilterInput | null;
  shift_date?: TableStringFilterInput | null;
  shift_number?: TableIntFilterInput | null;
  ttl?: TableIntFilterInput | null;
  production_order?: TableStringFilterInput | null;
  material?: TableStringFilterInput | null;
  speed_target?: TableIntFilterInput | null;
  speed_unit?: TableStringFilterInput | null;
  operation?: TableIntFilterInput | null;
  pieces_order?: TableIntFilterInput | null;
  pieces_day?: TableIntFilterInput | null;
  pieces_shift?: TableIntFilterInput | null;
  flag?: TableIntFilterInput | null;
};

export type TableWorkCenterStatusFilterInput = {
  plant?: TableStringFilterInput | null;
  workcenter?: TableStringFilterInput | null;
  name?: TableStringFilterInput | null;
  status?: TableStringFilterInput | null;
  timestamp?: TableFloatFilterInput | null;
  current_period?: TableFloatFilterInput | null;
  shift_date?: TableStringFilterInput | null;
  shift_number?: TableIntFilterInput | null;
  shift_start?: TableFloatFilterInput | null;
  shift_end?: TableFloatFilterInput | null;
  shift_plan?: TableStringFilterInput | null;
  production_order?: TableStringFilterInput | null;
  material?: TableStringFilterInput | null;
  speed_actual?: TableIntFilterInput | null;
  speed_target?: TableIntFilterInput | null;
  speed_unit?: TableStringFilterInput | null;
  operation?: TableIntFilterInput | null;
  ttl?: TableIntFilterInput | null;
  pieces_plan_day?: TableStringFilterInput | null;
  pieces_plan_shift?: TableStringFilterInput | null;
  last_production_at?: TableFloatFilterInput | null;
};

export type WorkCenterStatusConnection = {
  __typename: "WorkCenterStatusConnection";
  items?: Array<WorkCenterStatus | null> | null;
  nextToken?: string | null;
};

export type TableConfigFilterInput = {
  key?: TableStringFilterInput | null;
  value?: TableStringFilterInput | null;
};

export type ConfigConnection = {
  __typename: "ConfigConnection";
  items?: Array<Config | null> | null;
  nextToken?: string | null;
};

export type TableKpiFilterInput = {
  index?: TableStringFilterInput | null;
  time?: TableFloatFilterInput | null;
  location?: TableStringFilterInput | null;
  kpi?: TableStringFilterInput | null;
  period?: TableStringFilterInput | null;
  value?: TableStringFilterInput | null;
  target?: TableStringFilterInput | null;
  unit?: TableStringFilterInput | null;
  ttl?: TableIntFilterInput | null;
};

export type KpiConnection = {
  __typename: "KpiConnection";
  items?: Array<Kpi | null> | null;
  nextToken?: string | null;
};

export type CreateProductionOrderHeaderMutation = {
  __typename: "ProductionOrderHeader";
  production_order: string;
  plant?: string | null;
  modification_date?: number | null;
  sttxt?: string | null;
  material?: string | null;
  material_description?: string | null;
  material_weight?: number | null;
  material_weight_unit?: string | null;
  raw_material_batch_number?: string | null;
  raw_material?: string | null;
  raw_material_size?: number | null;
  production_order_batch?: string | null;
  consumed_containers?: number | null;
  consumed_containers_unit?: string | null;
  consumed_quantity?: number | null;
  consumed_quantity_unit?: string | null;
  ttl?: number | null;
};

export type CreateProductionOrderOperationMutation = {
  __typename: "ProductionOrderOperation";
  production_order: string;
  operation?: number | null;
  department?: string | null;
  workcenter?: string | null;
  press_family?: string | null;
  modification_date?: number | null;
  vsttxt?: string | null;
  asttx?: string | null;
  darue?: number | null;
  target_speed?: number | null;
  speed_unit?: string | null;
  target_quantity?: number | null;
  waste_quantity?: number | null;
  confirmed_quantity?: number | null;
  quantity_unit?: string | null;
  priority?: number | null;
  scheduled_start?: number | null;
  scheduled_end?: number | null;
  actual_end?: number | null;
  actual_start?: number | null;
  status?: string | null;
  comment?: string | null;
  comments?: {
    __typename: "ProductionOrderCommentConnection";
    items?: Array<{
      __typename: "ProductionOrderComment";
      production_order: string;
      operation: number;
      timestamp?: number | null;
      comment: string;
      operator: string;
      ttl?: number | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  material?: string | null;
  material_description?: string | null;
  average_speed?: number | null;
  raw_material?: string | null;
  raw_material_batch_number?: string | null;
  production_order_batch?: string | null;
  sttxt?: string | null;
  setup_time?: number | null;
  setup_time_unit?: string | null;
  tool_available?: number | null;
  ttl?: number | null;
};

export type CreateProductionOrderCommentMutation = {
  __typename: "ProductionOrderComment";
  production_order: string;
  operation: number;
  timestamp?: number | null;
  comment: string;
  operator: string;
  ttl?: number | null;
};

export type CreateWorkCenterPeriodsMutation = {
  __typename: "WorkCenterPeriods";
  end?: number | null;
  index: string;
  pieces?: number | null;
  start: number;
  status?: string | null;
  location?: number | null;
  shift_date?: string | null;
  shift_number?: number | null;
  ttl?: number | null;
  production_order?: string | null;
  material?: string | null;
  speed_target?: number | null;
  speed_unit?: string | null;
  operation?: number | null;
  pieces_order?: number | null;
  pieces_day?: number | null;
  pieces_shift?: number | null;
  flag?: number | null;
};

export type CreateWorkCenterStatusMutation = {
  __typename: "WorkCenterStatus";
  plant: string;
  workcenter: string;
  name?: string | null;
  status?: string | null;
  timestamp?: number | null;
  current_period?: number | null;
  shift_date?: string | null;
  shift_number?: number | null;
  shift_start?: number | null;
  shift_end?: number | null;
  shift_plan?: string | null;
  production_order?: string | null;
  material?: string | null;
  speed_actual?: number | null;
  speed_target?: number | null;
  speed_unit?: string | null;
  operation?: number | null;
  ttl?: number | null;
  pieces_plan_day?: string | null;
  pieces_plan_shift?: string | null;
  last_production_at?: number | null;
};

export type CreateConfigMutation = {
  __typename: "Config";
  key: string;
  value?: string | null;
};

export type CreateKpiMutation = {
  __typename: "Kpi";
  index: string;
  time?: number | null;
  location?: string | null;
  kpi?: string | null;
  period?: string | null;
  value?: string | null;
  target?: string | null;
  unit?: string | null;
  ttl?: number | null;
};

export type DeleteProductionOrderHeaderMutation = {
  __typename: "ProductionOrderHeader";
  production_order: string;
  plant?: string | null;
  modification_date?: number | null;
  sttxt?: string | null;
  material?: string | null;
  material_description?: string | null;
  material_weight?: number | null;
  material_weight_unit?: string | null;
  raw_material_batch_number?: string | null;
  raw_material?: string | null;
  raw_material_size?: number | null;
  production_order_batch?: string | null;
  consumed_containers?: number | null;
  consumed_containers_unit?: string | null;
  consumed_quantity?: number | null;
  consumed_quantity_unit?: string | null;
  ttl?: number | null;
};

export type DeleteProductionOrderOperationMutation = {
  __typename: "ProductionOrderOperation";
  production_order: string;
  operation?: number | null;
  department?: string | null;
  workcenter?: string | null;
  press_family?: string | null;
  modification_date?: number | null;
  vsttxt?: string | null;
  asttx?: string | null;
  darue?: number | null;
  target_speed?: number | null;
  speed_unit?: string | null;
  target_quantity?: number | null;
  waste_quantity?: number | null;
  confirmed_quantity?: number | null;
  quantity_unit?: string | null;
  priority?: number | null;
  scheduled_start?: number | null;
  scheduled_end?: number | null;
  actual_end?: number | null;
  actual_start?: number | null;
  status?: string | null;
  comment?: string | null;
  comments?: {
    __typename: "ProductionOrderCommentConnection";
    items?: Array<{
      __typename: "ProductionOrderComment";
      production_order: string;
      operation: number;
      timestamp?: number | null;
      comment: string;
      operator: string;
      ttl?: number | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  material?: string | null;
  material_description?: string | null;
  average_speed?: number | null;
  raw_material?: string | null;
  raw_material_batch_number?: string | null;
  production_order_batch?: string | null;
  sttxt?: string | null;
  setup_time?: number | null;
  setup_time_unit?: string | null;
  tool_available?: number | null;
  ttl?: number | null;
};

export type DeleteProductionOrderCommentMutation = {
  __typename: "ProductionOrderComment";
  production_order: string;
  operation: number;
  timestamp?: number | null;
  comment: string;
  operator: string;
  ttl?: number | null;
};

export type DeleteWorkCenterPeriodsMutation = {
  __typename: "WorkCenterPeriods";
  end?: number | null;
  index: string;
  pieces?: number | null;
  start: number;
  status?: string | null;
  location?: number | null;
  shift_date?: string | null;
  shift_number?: number | null;
  ttl?: number | null;
  production_order?: string | null;
  material?: string | null;
  speed_target?: number | null;
  speed_unit?: string | null;
  operation?: number | null;
  pieces_order?: number | null;
  pieces_day?: number | null;
  pieces_shift?: number | null;
  flag?: number | null;
};

export type DeleteWorkCenterStatusMutation = {
  __typename: "WorkCenterStatus";
  plant: string;
  workcenter: string;
  name?: string | null;
  status?: string | null;
  timestamp?: number | null;
  current_period?: number | null;
  shift_date?: string | null;
  shift_number?: number | null;
  shift_start?: number | null;
  shift_end?: number | null;
  shift_plan?: string | null;
  production_order?: string | null;
  material?: string | null;
  speed_actual?: number | null;
  speed_target?: number | null;
  speed_unit?: string | null;
  operation?: number | null;
  ttl?: number | null;
  pieces_plan_day?: string | null;
  pieces_plan_shift?: string | null;
  last_production_at?: number | null;
};

export type DeleteConfigMutation = {
  __typename: "Config";
  key: string;
  value?: string | null;
};

export type DeleteKpiMutation = {
  __typename: "Kpi";
  index: string;
  time?: number | null;
  location?: string | null;
  kpi?: string | null;
  period?: string | null;
  value?: string | null;
  target?: string | null;
  unit?: string | null;
  ttl?: number | null;
};

export type UpdateProductionOrderHeaderMutation = {
  __typename: "ProductionOrderHeader";
  production_order: string;
  plant?: string | null;
  modification_date?: number | null;
  sttxt?: string | null;
  material?: string | null;
  material_description?: string | null;
  material_weight?: number | null;
  material_weight_unit?: string | null;
  raw_material_batch_number?: string | null;
  raw_material?: string | null;
  raw_material_size?: number | null;
  production_order_batch?: string | null;
  consumed_containers?: number | null;
  consumed_containers_unit?: string | null;
  consumed_quantity?: number | null;
  consumed_quantity_unit?: string | null;
  ttl?: number | null;
};

export type UpdateProductionOrderOperationMutation = {
  __typename: "ProductionOrderOperation";
  production_order: string;
  operation?: number | null;
  department?: string | null;
  workcenter?: string | null;
  press_family?: string | null;
  modification_date?: number | null;
  vsttxt?: string | null;
  asttx?: string | null;
  darue?: number | null;
  target_speed?: number | null;
  speed_unit?: string | null;
  target_quantity?: number | null;
  waste_quantity?: number | null;
  confirmed_quantity?: number | null;
  quantity_unit?: string | null;
  priority?: number | null;
  scheduled_start?: number | null;
  scheduled_end?: number | null;
  actual_end?: number | null;
  actual_start?: number | null;
  status?: string | null;
  comment?: string | null;
  comments?: {
    __typename: "ProductionOrderCommentConnection";
    items?: Array<{
      __typename: "ProductionOrderComment";
      production_order: string;
      operation: number;
      timestamp?: number | null;
      comment: string;
      operator: string;
      ttl?: number | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  material?: string | null;
  material_description?: string | null;
  average_speed?: number | null;
  raw_material?: string | null;
  raw_material_batch_number?: string | null;
  production_order_batch?: string | null;
  sttxt?: string | null;
  setup_time?: number | null;
  setup_time_unit?: string | null;
  tool_available?: number | null;
  ttl?: number | null;
};

export type UpdateWorkCenterPeriodsMutation = {
  __typename: "WorkCenterPeriods";
  end?: number | null;
  index: string;
  pieces?: number | null;
  start: number;
  status?: string | null;
  location?: number | null;
  shift_date?: string | null;
  shift_number?: number | null;
  ttl?: number | null;
  production_order?: string | null;
  material?: string | null;
  speed_target?: number | null;
  speed_unit?: string | null;
  operation?: number | null;
  pieces_order?: number | null;
  pieces_day?: number | null;
  pieces_shift?: number | null;
  flag?: number | null;
};

export type UpdateWorkCenterStatusMutation = {
  __typename: "WorkCenterStatus";
  plant: string;
  workcenter: string;
  name?: string | null;
  status?: string | null;
  timestamp?: number | null;
  current_period?: number | null;
  shift_date?: string | null;
  shift_number?: number | null;
  shift_start?: number | null;
  shift_end?: number | null;
  shift_plan?: string | null;
  production_order?: string | null;
  material?: string | null;
  speed_actual?: number | null;
  speed_target?: number | null;
  speed_unit?: string | null;
  operation?: number | null;
  ttl?: number | null;
  pieces_plan_day?: string | null;
  pieces_plan_shift?: string | null;
  last_production_at?: number | null;
};

export type UpdateConfigMutation = {
  __typename: "Config";
  key: string;
  value?: string | null;
};

export type SplitWorkCenterPeriodsMutation = {
  __typename: "WorkCenterPeriodsConnection";
  items?: Array<{
    __typename: "WorkCenterPeriods";
    end?: number | null;
    index: string;
    pieces?: number | null;
    start: number;
    status?: string | null;
    location?: number | null;
    shift_date?: string | null;
    shift_number?: number | null;
    ttl?: number | null;
    production_order?: string | null;
    material?: string | null;
    speed_target?: number | null;
    speed_unit?: string | null;
    operation?: number | null;
    pieces_order?: number | null;
    pieces_day?: number | null;
    pieces_shift?: number | null;
    flag?: number | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetProductionOrderHeaderQuery = {
  __typename: "ProductionOrderHeader";
  production_order: string;
  plant?: string | null;
  modification_date?: number | null;
  sttxt?: string | null;
  material?: string | null;
  material_description?: string | null;
  material_weight?: number | null;
  material_weight_unit?: string | null;
  raw_material_batch_number?: string | null;
  raw_material?: string | null;
  raw_material_size?: number | null;
  production_order_batch?: string | null;
  consumed_containers?: number | null;
  consumed_containers_unit?: string | null;
  consumed_quantity?: number | null;
  consumed_quantity_unit?: string | null;
  ttl?: number | null;
};

export type GetProductionOrderOperationQuery = {
  __typename: "ProductionOrderOperation";
  production_order: string;
  operation?: number | null;
  department?: string | null;
  workcenter?: string | null;
  press_family?: string | null;
  modification_date?: number | null;
  vsttxt?: string | null;
  asttx?: string | null;
  darue?: number | null;
  target_speed?: number | null;
  speed_unit?: string | null;
  target_quantity?: number | null;
  waste_quantity?: number | null;
  confirmed_quantity?: number | null;
  quantity_unit?: string | null;
  priority?: number | null;
  scheduled_start?: number | null;
  scheduled_end?: number | null;
  actual_end?: number | null;
  actual_start?: number | null;
  status?: string | null;
  comment?: string | null;
  comments?: {
    __typename: "ProductionOrderCommentConnection";
    items?: Array<{
      __typename: "ProductionOrderComment";
      production_order: string;
      operation: number;
      timestamp?: number | null;
      comment: string;
      operator: string;
      ttl?: number | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  material?: string | null;
  material_description?: string | null;
  average_speed?: number | null;
  raw_material?: string | null;
  raw_material_batch_number?: string | null;
  production_order_batch?: string | null;
  sttxt?: string | null;
  setup_time?: number | null;
  setup_time_unit?: string | null;
  tool_available?: number | null;
  ttl?: number | null;
};

export type GetWorkCenterPeriodsQuery = {
  __typename: "WorkCenterPeriods";
  end?: number | null;
  index: string;
  pieces?: number | null;
  start: number;
  status?: string | null;
  location?: number | null;
  shift_date?: string | null;
  shift_number?: number | null;
  ttl?: number | null;
  production_order?: string | null;
  material?: string | null;
  speed_target?: number | null;
  speed_unit?: string | null;
  operation?: number | null;
  pieces_order?: number | null;
  pieces_day?: number | null;
  pieces_shift?: number | null;
  flag?: number | null;
};

export type GetWorkCenterStatusQuery = {
  __typename: "WorkCenterStatus";
  plant: string;
  workcenter: string;
  name?: string | null;
  status?: string | null;
  timestamp?: number | null;
  current_period?: number | null;
  shift_date?: string | null;
  shift_number?: number | null;
  shift_start?: number | null;
  shift_end?: number | null;
  shift_plan?: string | null;
  production_order?: string | null;
  material?: string | null;
  speed_actual?: number | null;
  speed_target?: number | null;
  speed_unit?: string | null;
  operation?: number | null;
  ttl?: number | null;
  pieces_plan_day?: string | null;
  pieces_plan_shift?: string | null;
  last_production_at?: number | null;
};

export type GetConfigQuery = {
  __typename: "Config";
  key: string;
  value?: string | null;
};

export type GetKpiQuery = {
  __typename: "Kpi";
  index: string;
  time?: number | null;
  location?: string | null;
  kpi?: string | null;
  period?: string | null;
  value?: string | null;
  target?: string | null;
  unit?: string | null;
  ttl?: number | null;
};

export type ListProductionOrderHeadersQuery = {
  __typename: "ProductionOrderHeaderConnection";
  items?: Array<{
    __typename: "ProductionOrderHeader";
    production_order: string;
    plant?: string | null;
    modification_date?: number | null;
    sttxt?: string | null;
    material?: string | null;
    material_description?: string | null;
    material_weight?: number | null;
    material_weight_unit?: string | null;
    raw_material_batch_number?: string | null;
    raw_material?: string | null;
    raw_material_size?: number | null;
    production_order_batch?: string | null;
    consumed_containers?: number | null;
    consumed_containers_unit?: string | null;
    consumed_quantity?: number | null;
    consumed_quantity_unit?: string | null;
    ttl?: number | null;
  } | null> | null;
  nextToken?: string | null;
};

export type ListProductionOrderOperationsQuery = {
  __typename: "ProductionOrderOperationConnection";
  items?: Array<{
    __typename: "ProductionOrderOperation";
    production_order: string;
    operation?: number | null;
    department?: string | null;
    workcenter?: string | null;
    press_family?: string | null;
    modification_date?: number | null;
    vsttxt?: string | null;
    asttx?: string | null;
    darue?: number | null;
    target_speed?: number | null;
    speed_unit?: string | null;
    target_quantity?: number | null;
    waste_quantity?: number | null;
    confirmed_quantity?: number | null;
    quantity_unit?: string | null;
    priority?: number | null;
    scheduled_start?: number | null;
    scheduled_end?: number | null;
    actual_end?: number | null;
    actual_start?: number | null;
    status?: string | null;
    comment?: string | null;
    comments?: {
      __typename: "ProductionOrderCommentConnection";
      items?: Array<{
        __typename: "ProductionOrderComment";
        production_order: string;
        operation: number;
        timestamp?: number | null;
        comment: string;
        operator: string;
        ttl?: number | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    material?: string | null;
    material_description?: string | null;
    average_speed?: number | null;
    raw_material?: string | null;
    raw_material_batch_number?: string | null;
    production_order_batch?: string | null;
    sttxt?: string | null;
    setup_time?: number | null;
    setup_time_unit?: string | null;
    tool_available?: number | null;
    ttl?: number | null;
  } | null> | null;
  nextToken?: string | null;
};

export type QueryProductionOrderOperationsQuery = {
  __typename: "ProductionOrderOperationConnection";
  items?: Array<{
    __typename: "ProductionOrderOperation";
    production_order: string;
    operation?: number | null;
    department?: string | null;
    workcenter?: string | null;
    press_family?: string | null;
    modification_date?: number | null;
    vsttxt?: string | null;
    asttx?: string | null;
    darue?: number | null;
    target_speed?: number | null;
    speed_unit?: string | null;
    target_quantity?: number | null;
    waste_quantity?: number | null;
    confirmed_quantity?: number | null;
    quantity_unit?: string | null;
    priority?: number | null;
    scheduled_start?: number | null;
    scheduled_end?: number | null;
    actual_end?: number | null;
    actual_start?: number | null;
    status?: string | null;
    comment?: string | null;
    comments?: {
      __typename: "ProductionOrderCommentConnection";
      items?: Array<{
        __typename: "ProductionOrderComment";
        production_order: string;
        operation: number;
        timestamp?: number | null;
        comment: string;
        operator: string;
        ttl?: number | null;
      } | null> | null;
      nextToken?: string | null;
    } | null;
    material?: string | null;
    material_description?: string | null;
    average_speed?: number | null;
    raw_material?: string | null;
    raw_material_batch_number?: string | null;
    production_order_batch?: string | null;
    sttxt?: string | null;
    setup_time?: number | null;
    setup_time_unit?: string | null;
    tool_available?: number | null;
    ttl?: number | null;
  } | null> | null;
  nextToken?: string | null;
};

export type ListProductionOrderCommentsQuery = {
  __typename: "ProductionOrderCommentConnection";
  items?: Array<{
    __typename: "ProductionOrderComment";
    production_order: string;
    operation: number;
    timestamp?: number | null;
    comment: string;
    operator: string;
    ttl?: number | null;
  } | null> | null;
  nextToken?: string | null;
};

export type ListWorkCenterPeriodsQuery = {
  __typename: "WorkCenterPeriodsConnection";
  items?: Array<{
    __typename: "WorkCenterPeriods";
    end?: number | null;
    index: string;
    pieces?: number | null;
    start: number;
    status?: string | null;
    location?: number | null;
    shift_date?: string | null;
    shift_number?: number | null;
    ttl?: number | null;
    production_order?: string | null;
    material?: string | null;
    speed_target?: number | null;
    speed_unit?: string | null;
    operation?: number | null;
    pieces_order?: number | null;
    pieces_day?: number | null;
    pieces_shift?: number | null;
    flag?: number | null;
  } | null> | null;
  nextToken?: string | null;
};

export type QueryWorkCenterPeriodsQuery = {
  __typename: "WorkCenterPeriodsConnection";
  items?: Array<{
    __typename: "WorkCenterPeriods";
    end?: number | null;
    index: string;
    pieces?: number | null;
    start: number;
    status?: string | null;
    location?: number | null;
    shift_date?: string | null;
    shift_number?: number | null;
    ttl?: number | null;
    production_order?: string | null;
    material?: string | null;
    speed_target?: number | null;
    speed_unit?: string | null;
    operation?: number | null;
    pieces_order?: number | null;
    pieces_day?: number | null;
    pieces_shift?: number | null;
    flag?: number | null;
  } | null> | null;
  nextToken?: string | null;
};

export type GetWorkCenterPeriodsByShiftQuery = {
  __typename: "WorkCenterPeriodsConnection";
  items?: Array<{
    __typename: "WorkCenterPeriods";
    end?: number | null;
    index: string;
    pieces?: number | null;
    start: number;
    status?: string | null;
    location?: number | null;
    shift_date?: string | null;
    shift_number?: number | null;
    ttl?: number | null;
    production_order?: string | null;
    material?: string | null;
    speed_target?: number | null;
    speed_unit?: string | null;
    operation?: number | null;
    pieces_order?: number | null;
    pieces_day?: number | null;
    pieces_shift?: number | null;
    flag?: number | null;
  } | null> | null;
  nextToken?: string | null;
};

export type ListWorkCenterStatusesQuery = {
  __typename: "WorkCenterStatusConnection";
  items?: Array<{
    __typename: "WorkCenterStatus";
    plant: string;
    workcenter: string;
    name?: string | null;
    status?: string | null;
    timestamp?: number | null;
    current_period?: number | null;
    shift_date?: string | null;
    shift_number?: number | null;
    shift_start?: number | null;
    shift_end?: number | null;
    shift_plan?: string | null;
    production_order?: string | null;
    material?: string | null;
    speed_actual?: number | null;
    speed_target?: number | null;
    speed_unit?: string | null;
    operation?: number | null;
    ttl?: number | null;
    pieces_plan_day?: string | null;
    pieces_plan_shift?: string | null;
    last_production_at?: number | null;
  } | null> | null;
  nextToken?: string | null;
};

export type ListConfigsQuery = {
  __typename: "ConfigConnection";
  items?: Array<{
    __typename: "Config";
    key: string;
    value?: string | null;
  } | null> | null;
  nextToken?: string | null;
};

export type ListKpisQuery = {
  __typename: "KpiConnection";
  items?: Array<{
    __typename: "Kpi";
    index: string;
    time?: number | null;
    location?: string | null;
    kpi?: string | null;
    period?: string | null;
    value?: string | null;
    target?: string | null;
    unit?: string | null;
    ttl?: number | null;
  } | null> | null;
  nextToken?: string | null;
};

export type OnCreateProductionOrderHeaderSubscription = {
  __typename: "ProductionOrderHeader";
  production_order: string;
  plant?: string | null;
  modification_date?: number | null;
  sttxt?: string | null;
  material?: string | null;
  material_description?: string | null;
  material_weight?: number | null;
  material_weight_unit?: string | null;
  raw_material_batch_number?: string | null;
  raw_material?: string | null;
  raw_material_size?: number | null;
  production_order_batch?: string | null;
  consumed_containers?: number | null;
  consumed_containers_unit?: string | null;
  consumed_quantity?: number | null;
  consumed_quantity_unit?: string | null;
  ttl?: number | null;
};

export type OnCreateProductionOrderOperationSubscription = {
  __typename: "ProductionOrderOperation";
  production_order: string;
  operation?: number | null;
  department?: string | null;
  workcenter?: string | null;
  press_family?: string | null;
  modification_date?: number | null;
  vsttxt?: string | null;
  asttx?: string | null;
  darue?: number | null;
  target_speed?: number | null;
  speed_unit?: string | null;
  target_quantity?: number | null;
  waste_quantity?: number | null;
  confirmed_quantity?: number | null;
  quantity_unit?: string | null;
  priority?: number | null;
  scheduled_start?: number | null;
  scheduled_end?: number | null;
  actual_end?: number | null;
  actual_start?: number | null;
  status?: string | null;
  comment?: string | null;
  comments?: {
    __typename: "ProductionOrderCommentConnection";
    items?: Array<{
      __typename: "ProductionOrderComment";
      production_order: string;
      operation: number;
      timestamp?: number | null;
      comment: string;
      operator: string;
      ttl?: number | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  material?: string | null;
  material_description?: string | null;
  average_speed?: number | null;
  raw_material?: string | null;
  raw_material_batch_number?: string | null;
  production_order_batch?: string | null;
  sttxt?: string | null;
  setup_time?: number | null;
  setup_time_unit?: string | null;
  tool_available?: number | null;
  ttl?: number | null;
};

export type OnCreateProductionOrderListItemSubscription = {
  __typename: "ProductionOrderOperation";
  production_order: string;
  operation?: number | null;
  department?: string | null;
  workcenter?: string | null;
  press_family?: string | null;
  modification_date?: number | null;
  vsttxt?: string | null;
  asttx?: string | null;
  darue?: number | null;
  target_speed?: number | null;
  speed_unit?: string | null;
  target_quantity?: number | null;
  waste_quantity?: number | null;
  confirmed_quantity?: number | null;
  quantity_unit?: string | null;
  priority?: number | null;
  scheduled_start?: number | null;
  scheduled_end?: number | null;
  actual_end?: number | null;
  actual_start?: number | null;
  status?: string | null;
  comment?: string | null;
  comments?: {
    __typename: "ProductionOrderCommentConnection";
    items?: Array<{
      __typename: "ProductionOrderComment";
      production_order: string;
      operation: number;
      timestamp?: number | null;
      comment: string;
      operator: string;
      ttl?: number | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  material?: string | null;
  material_description?: string | null;
  average_speed?: number | null;
  raw_material?: string | null;
  raw_material_batch_number?: string | null;
  production_order_batch?: string | null;
  sttxt?: string | null;
  setup_time?: number | null;
  setup_time_unit?: string | null;
  tool_available?: number | null;
  ttl?: number | null;
};

export type OnCreateProductionOrderCommentSubscription = {
  __typename: "ProductionOrderComment";
  production_order: string;
  operation: number;
  timestamp?: number | null;
  comment: string;
  operator: string;
  ttl?: number | null;
};

export type OnCreateProductionOrderCommentAnySubscription = {
  __typename: "ProductionOrderComment";
  production_order: string;
  operation: number;
  timestamp?: number | null;
  comment: string;
  operator: string;
  ttl?: number | null;
};

export type OnCreateWorkCenterPeriodsSubscription = {
  __typename: "WorkCenterPeriods";
  end?: number | null;
  index: string;
  pieces?: number | null;
  start: number;
  status?: string | null;
  location?: number | null;
  shift_date?: string | null;
  shift_number?: number | null;
  ttl?: number | null;
  production_order?: string | null;
  material?: string | null;
  speed_target?: number | null;
  speed_unit?: string | null;
  operation?: number | null;
  pieces_order?: number | null;
  pieces_day?: number | null;
  pieces_shift?: number | null;
  flag?: number | null;
};

export type OnCreateWorkCenterStatusSubscription = {
  __typename: "WorkCenterStatus";
  plant: string;
  workcenter: string;
  name?: string | null;
  status?: string | null;
  timestamp?: number | null;
  current_period?: number | null;
  shift_date?: string | null;
  shift_number?: number | null;
  shift_start?: number | null;
  shift_end?: number | null;
  shift_plan?: string | null;
  production_order?: string | null;
  material?: string | null;
  speed_actual?: number | null;
  speed_target?: number | null;
  speed_unit?: string | null;
  operation?: number | null;
  ttl?: number | null;
  pieces_plan_day?: string | null;
  pieces_plan_shift?: string | null;
  last_production_at?: number | null;
};

export type OnCreateConfigSubscription = {
  __typename: "Config";
  key: string;
  value?: string | null;
};

export type OnCreateKpiSubscription = {
  __typename: "Kpi";
  index: string;
  time?: number | null;
  location?: string | null;
  kpi?: string | null;
  period?: string | null;
  value?: string | null;
  target?: string | null;
  unit?: string | null;
  ttl?: number | null;
};

export type OnDeleteProductionOrderHeaderSubscription = {
  __typename: "ProductionOrderHeader";
  production_order: string;
  plant?: string | null;
  modification_date?: number | null;
  sttxt?: string | null;
  material?: string | null;
  material_description?: string | null;
  material_weight?: number | null;
  material_weight_unit?: string | null;
  raw_material_batch_number?: string | null;
  raw_material?: string | null;
  raw_material_size?: number | null;
  production_order_batch?: string | null;
  consumed_containers?: number | null;
  consumed_containers_unit?: string | null;
  consumed_quantity?: number | null;
  consumed_quantity_unit?: string | null;
  ttl?: number | null;
};

export type OnDeleteProductionOrderOperationSubscription = {
  __typename: "ProductionOrderOperation";
  production_order: string;
  operation?: number | null;
  department?: string | null;
  workcenter?: string | null;
  press_family?: string | null;
  modification_date?: number | null;
  vsttxt?: string | null;
  asttx?: string | null;
  darue?: number | null;
  target_speed?: number | null;
  speed_unit?: string | null;
  target_quantity?: number | null;
  waste_quantity?: number | null;
  confirmed_quantity?: number | null;
  quantity_unit?: string | null;
  priority?: number | null;
  scheduled_start?: number | null;
  scheduled_end?: number | null;
  actual_end?: number | null;
  actual_start?: number | null;
  status?: string | null;
  comment?: string | null;
  comments?: {
    __typename: "ProductionOrderCommentConnection";
    items?: Array<{
      __typename: "ProductionOrderComment";
      production_order: string;
      operation: number;
      timestamp?: number | null;
      comment: string;
      operator: string;
      ttl?: number | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  material?: string | null;
  material_description?: string | null;
  average_speed?: number | null;
  raw_material?: string | null;
  raw_material_batch_number?: string | null;
  production_order_batch?: string | null;
  sttxt?: string | null;
  setup_time?: number | null;
  setup_time_unit?: string | null;
  tool_available?: number | null;
  ttl?: number | null;
};

export type OnDeleteProductionOrderCommentSubscription = {
  __typename: "ProductionOrderComment";
  production_order: string;
  operation: number;
  timestamp?: number | null;
  comment: string;
  operator: string;
  ttl?: number | null;
};

export type OnDeleteWorkCenterPeriodsSubscription = {
  __typename: "WorkCenterPeriods";
  end?: number | null;
  index: string;
  pieces?: number | null;
  start: number;
  status?: string | null;
  location?: number | null;
  shift_date?: string | null;
  shift_number?: number | null;
  ttl?: number | null;
  production_order?: string | null;
  material?: string | null;
  speed_target?: number | null;
  speed_unit?: string | null;
  operation?: number | null;
  pieces_order?: number | null;
  pieces_day?: number | null;
  pieces_shift?: number | null;
  flag?: number | null;
};

export type OnDeleteWorkCenterStatusSubscription = {
  __typename: "WorkCenterStatus";
  plant: string;
  workcenter: string;
  name?: string | null;
  status?: string | null;
  timestamp?: number | null;
  current_period?: number | null;
  shift_date?: string | null;
  shift_number?: number | null;
  shift_start?: number | null;
  shift_end?: number | null;
  shift_plan?: string | null;
  production_order?: string | null;
  material?: string | null;
  speed_actual?: number | null;
  speed_target?: number | null;
  speed_unit?: string | null;
  operation?: number | null;
  ttl?: number | null;
  pieces_plan_day?: string | null;
  pieces_plan_shift?: string | null;
  last_production_at?: number | null;
};

export type OnDeleteConfigSubscription = {
  __typename: "Config";
  key: string;
  value?: string | null;
};

export type OnDeleteKpiSubscription = {
  __typename: "Kpi";
  index: string;
  time?: number | null;
  location?: string | null;
  kpi?: string | null;
  period?: string | null;
  value?: string | null;
  target?: string | null;
  unit?: string | null;
  ttl?: number | null;
};

export type OnUpdateProductionOrderHeaderSubscription = {
  __typename: "ProductionOrderHeader";
  production_order: string;
  plant?: string | null;
  modification_date?: number | null;
  sttxt?: string | null;
  material?: string | null;
  material_description?: string | null;
  material_weight?: number | null;
  material_weight_unit?: string | null;
  raw_material_batch_number?: string | null;
  raw_material?: string | null;
  raw_material_size?: number | null;
  production_order_batch?: string | null;
  consumed_containers?: number | null;
  consumed_containers_unit?: string | null;
  consumed_quantity?: number | null;
  consumed_quantity_unit?: string | null;
  ttl?: number | null;
};

export type OnUpdateProductionOrderOperationListSubscription = {
  __typename: "ProductionOrderOperation";
  production_order: string;
  operation?: number | null;
  department?: string | null;
  workcenter?: string | null;
  press_family?: string | null;
  modification_date?: number | null;
  vsttxt?: string | null;
  asttx?: string | null;
  darue?: number | null;
  target_speed?: number | null;
  speed_unit?: string | null;
  target_quantity?: number | null;
  waste_quantity?: number | null;
  confirmed_quantity?: number | null;
  quantity_unit?: string | null;
  priority?: number | null;
  scheduled_start?: number | null;
  scheduled_end?: number | null;
  actual_end?: number | null;
  actual_start?: number | null;
  status?: string | null;
  comment?: string | null;
  comments?: {
    __typename: "ProductionOrderCommentConnection";
    items?: Array<{
      __typename: "ProductionOrderComment";
      production_order: string;
      operation: number;
      timestamp?: number | null;
      comment: string;
      operator: string;
      ttl?: number | null;
    } | null> | null;
    nextToken?: string | null;
  } | null;
  material?: string | null;
  material_description?: string | null;
  average_speed?: number | null;
  raw_material?: string | null;
  raw_material_batch_number?: string | null;
  production_order_batch?: string | null;
  sttxt?: string | null;
  setup_time?: number | null;
  setup_time_unit?: string | null;
  tool_available?: number | null;
  ttl?: number | null;
};

export type OnUpdateWorkCenterPeriodsSubscription = {
  __typename: "WorkCenterPeriods";
  end?: number | null;
  index: string;
  pieces?: number | null;
  start: number;
  status?: string | null;
  location?: number | null;
  shift_date?: string | null;
  shift_number?: number | null;
  ttl?: number | null;
  production_order?: string | null;
  material?: string | null;
  speed_target?: number | null;
  speed_unit?: string | null;
  operation?: number | null;
  pieces_order?: number | null;
  pieces_day?: number | null;
  pieces_shift?: number | null;
  flag?: number | null;
};

export type OnUpdateWorkCenterStatusSubscription = {
  __typename: "WorkCenterStatus";
  plant: string;
  workcenter: string;
  name?: string | null;
  status?: string | null;
  timestamp?: number | null;
  current_period?: number | null;
  shift_date?: string | null;
  shift_number?: number | null;
  shift_start?: number | null;
  shift_end?: number | null;
  shift_plan?: string | null;
  production_order?: string | null;
  material?: string | null;
  speed_actual?: number | null;
  speed_target?: number | null;
  speed_unit?: string | null;
  operation?: number | null;
  ttl?: number | null;
  pieces_plan_day?: string | null;
  pieces_plan_shift?: string | null;
  last_production_at?: number | null;
};

export type OnUpdateConfigSubscription = {
  __typename: "Config";
  key: string;
  value?: string | null;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateProductionOrderHeader(
    input: CreateProductionOrderHeaderInput
  ): Promise<CreateProductionOrderHeaderMutation> {
    const statement = `mutation CreateProductionOrderHeader($input: CreateProductionOrderHeaderInput!) {
        createProductionOrderHeader(input: $input) {
          __typename
          production_order
          plant
          modification_date
          sttxt
          material
          material_description
          material_weight
          material_weight_unit
          raw_material_batch_number
          raw_material
          raw_material_size
          production_order_batch
          consumed_containers
          consumed_containers_unit
          consumed_quantity
          consumed_quantity_unit
          ttl
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProductionOrderHeaderMutation>(
      response.data.createProductionOrderHeader
    );
  }
  async CreateProductionOrderOperation(
    input: CreateProductionOrderOperationInput
  ): Promise<CreateProductionOrderOperationMutation> {
    const statement = `mutation CreateProductionOrderOperation($input: CreateProductionOrderOperationInput!) {
        createProductionOrderOperation(input: $input) {
          __typename
          production_order
          operation
          department
          workcenter
          press_family
          modification_date
          vsttxt
          asttx
          darue
          target_speed
          speed_unit
          target_quantity
          waste_quantity
          confirmed_quantity
          quantity_unit
          priority
          scheduled_start
          scheduled_end
          actual_end
          actual_start
          status
          comment
          comments {
            __typename
            items {
              __typename
              production_order
              operation
              timestamp
              comment
              operator
              ttl
            }
            nextToken
          }
          material
          material_description
          average_speed
          raw_material
          raw_material_batch_number
          production_order_batch
          sttxt
          setup_time
          setup_time_unit
          tool_available
          ttl
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProductionOrderOperationMutation>(
      response.data.createProductionOrderOperation
    );
  }
  async CreateProductionOrderComment(
    input: CreateProductionOrderCommentInput
  ): Promise<CreateProductionOrderCommentMutation> {
    const statement = `mutation CreateProductionOrderComment($input: CreateProductionOrderCommentInput!) {
        createProductionOrderComment(input: $input) {
          __typename
          production_order
          operation
          timestamp
          comment
          operator
          ttl
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateProductionOrderCommentMutation>(
      response.data.createProductionOrderComment
    );
  }
  async CreateWorkCenterPeriods(
    input: CreateWorkCenterPeriodsInput
  ): Promise<CreateWorkCenterPeriodsMutation> {
    const statement = `mutation CreateWorkCenterPeriods($input: CreateWorkCenterPeriodsInput!) {
        createWorkCenterPeriods(input: $input) {
          __typename
          end
          index
          pieces
          start
          status
          location
          shift_date
          shift_number
          ttl
          production_order
          material
          speed_target
          speed_unit
          operation
          pieces_order
          pieces_day
          pieces_shift
          flag
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateWorkCenterPeriodsMutation>(
      response.data.createWorkCenterPeriods
    );
  }
  async CreateWorkCenterStatus(
    input: CreateWorkCenterStatusInput
  ): Promise<CreateWorkCenterStatusMutation> {
    const statement = `mutation CreateWorkCenterStatus($input: CreateWorkCenterStatusInput!) {
        createWorkCenterStatus(input: $input) {
          __typename
          plant
          workcenter
          name
          status
          timestamp
          current_period
          shift_date
          shift_number
          shift_start
          shift_end
          shift_plan
          production_order
          material
          speed_actual
          speed_target
          speed_unit
          operation
          ttl
          pieces_plan_day
          pieces_plan_shift
          last_production_at
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateWorkCenterStatusMutation>response.data.createWorkCenterStatus;
  }
  async CreateConfig(input: CreateConfigInput): Promise<CreateConfigMutation> {
    const statement = `mutation CreateConfig($input: CreateConfigInput!) {
        createConfig(input: $input) {
          __typename
          key
          value
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConfigMutation>response.data.createConfig;
  }
  async CreateKpi(input: CreateKpiInput): Promise<CreateKpiMutation> {
    const statement = `mutation CreateKpi($input: CreateKpiInput!) {
        createKpi(input: $input) {
          __typename
          index
          time
          location
          kpi
          period
          value
          target
          unit
          ttl
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateKpiMutation>response.data.createKpi;
  }
  async DeleteProductionOrderHeader(
    input: DeleteProductionOrderHeaderInput
  ): Promise<DeleteProductionOrderHeaderMutation> {
    const statement = `mutation DeleteProductionOrderHeader($input: DeleteProductionOrderHeaderInput!) {
        deleteProductionOrderHeader(input: $input) {
          __typename
          production_order
          plant
          modification_date
          sttxt
          material
          material_description
          material_weight
          material_weight_unit
          raw_material_batch_number
          raw_material
          raw_material_size
          production_order_batch
          consumed_containers
          consumed_containers_unit
          consumed_quantity
          consumed_quantity_unit
          ttl
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProductionOrderHeaderMutation>(
      response.data.deleteProductionOrderHeader
    );
  }
  async DeleteProductionOrderOperation(
    input: DeleteProductionOrderOperationInput
  ): Promise<DeleteProductionOrderOperationMutation> {
    const statement = `mutation DeleteProductionOrderOperation($input: DeleteProductionOrderOperationInput!) {
        deleteProductionOrderOperation(input: $input) {
          __typename
          production_order
          operation
          department
          workcenter
          press_family
          modification_date
          vsttxt
          asttx
          darue
          target_speed
          speed_unit
          target_quantity
          waste_quantity
          confirmed_quantity
          quantity_unit
          priority
          scheduled_start
          scheduled_end
          actual_end
          actual_start
          status
          comment
          comments {
            __typename
            items {
              __typename
              production_order
              operation
              timestamp
              comment
              operator
              ttl
            }
            nextToken
          }
          material
          material_description
          average_speed
          raw_material
          raw_material_batch_number
          production_order_batch
          sttxt
          setup_time
          setup_time_unit
          tool_available
          ttl
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProductionOrderOperationMutation>(
      response.data.deleteProductionOrderOperation
    );
  }
  async DeleteProductionOrderComment(
    input: DeleteProductionOrderCommentInput
  ): Promise<DeleteProductionOrderCommentMutation> {
    const statement = `mutation DeleteProductionOrderComment($input: DeleteProductionOrderCommentInput!) {
        deleteProductionOrderComment(input: $input) {
          __typename
          production_order
          operation
          timestamp
          comment
          operator
          ttl
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteProductionOrderCommentMutation>(
      response.data.deleteProductionOrderComment
    );
  }
  async DeleteWorkCenterPeriods(
    input: DeleteWorkCenterPeriodsInput
  ): Promise<DeleteWorkCenterPeriodsMutation> {
    const statement = `mutation DeleteWorkCenterPeriods($input: DeleteWorkCenterPeriodsInput!) {
        deleteWorkCenterPeriods(input: $input) {
          __typename
          end
          index
          pieces
          start
          status
          location
          shift_date
          shift_number
          ttl
          production_order
          material
          speed_target
          speed_unit
          operation
          pieces_order
          pieces_day
          pieces_shift
          flag
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteWorkCenterPeriodsMutation>(
      response.data.deleteWorkCenterPeriods
    );
  }
  async DeleteWorkCenterStatus(
    input: DeleteWorkCenterStatusInput
  ): Promise<DeleteWorkCenterStatusMutation> {
    const statement = `mutation DeleteWorkCenterStatus($input: DeleteWorkCenterStatusInput!) {
        deleteWorkCenterStatus(input: $input) {
          __typename
          plant
          workcenter
          name
          status
          timestamp
          current_period
          shift_date
          shift_number
          shift_start
          shift_end
          shift_plan
          production_order
          material
          speed_actual
          speed_target
          speed_unit
          operation
          ttl
          pieces_plan_day
          pieces_plan_shift
          last_production_at
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteWorkCenterStatusMutation>response.data.deleteWorkCenterStatus;
  }
  async DeleteConfig(input: DeleteConfigInput): Promise<DeleteConfigMutation> {
    const statement = `mutation DeleteConfig($input: DeleteConfigInput!) {
        deleteConfig(input: $input) {
          __typename
          key
          value
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteConfigMutation>response.data.deleteConfig;
  }
  async DeleteKpi(input: DeleteKpiInput): Promise<DeleteKpiMutation> {
    const statement = `mutation DeleteKpi($input: DeleteKpiInput!) {
        deleteKpi(input: $input) {
          __typename
          index
          time
          location
          kpi
          period
          value
          target
          unit
          ttl
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteKpiMutation>response.data.deleteKpi;
  }
  async UpdateProductionOrderHeader(
    input: UpdateProductionOrderHeaderInput
  ): Promise<UpdateProductionOrderHeaderMutation> {
    const statement = `mutation UpdateProductionOrderHeader($input: UpdateProductionOrderHeaderInput!) {
        updateProductionOrderHeader(input: $input) {
          __typename
          production_order
          plant
          modification_date
          sttxt
          material
          material_description
          material_weight
          material_weight_unit
          raw_material_batch_number
          raw_material
          raw_material_size
          production_order_batch
          consumed_containers
          consumed_containers_unit
          consumed_quantity
          consumed_quantity_unit
          ttl
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProductionOrderHeaderMutation>(
      response.data.updateProductionOrderHeader
    );
  }
  async UpdateProductionOrderOperation(
    input: UpdateProductionOrderOperationInput
  ): Promise<UpdateProductionOrderOperationMutation> {
    const statement = `mutation UpdateProductionOrderOperation($input: UpdateProductionOrderOperationInput!) {
        updateProductionOrderOperation(input: $input) {
          __typename
          production_order
          operation
          department
          workcenter
          press_family
          modification_date
          vsttxt
          asttx
          darue
          target_speed
          speed_unit
          target_quantity
          waste_quantity
          confirmed_quantity
          quantity_unit
          priority
          scheduled_start
          scheduled_end
          actual_end
          actual_start
          status
          comment
          comments {
            __typename
            items {
              __typename
              production_order
              operation
              timestamp
              comment
              operator
              ttl
            }
            nextToken
          }
          material
          material_description
          average_speed
          raw_material
          raw_material_batch_number
          production_order_batch
          sttxt
          setup_time
          setup_time_unit
          tool_available
          ttl
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateProductionOrderOperationMutation>(
      response.data.updateProductionOrderOperation
    );
  }
  async UpdateWorkCenterPeriods(
    input: UpdateWorkCenterPeriodsInput
  ): Promise<UpdateWorkCenterPeriodsMutation> {
    const statement = `mutation UpdateWorkCenterPeriods($input: UpdateWorkCenterPeriodsInput!) {
        updateWorkCenterPeriods(input: $input) {
          __typename
          end
          index
          pieces
          start
          status
          location
          shift_date
          shift_number
          ttl
          production_order
          material
          speed_target
          speed_unit
          operation
          pieces_order
          pieces_day
          pieces_shift
          flag
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateWorkCenterPeriodsMutation>(
      response.data.updateWorkCenterPeriods
    );
  }
  async UpdateWorkCenterStatus(
    input: UpdateWorkCenterStatusInput
  ): Promise<UpdateWorkCenterStatusMutation> {
    const statement = `mutation UpdateWorkCenterStatus($input: UpdateWorkCenterStatusInput!) {
        updateWorkCenterStatus(input: $input) {
          __typename
          plant
          workcenter
          name
          status
          timestamp
          current_period
          shift_date
          shift_number
          shift_start
          shift_end
          shift_plan
          production_order
          material
          speed_actual
          speed_target
          speed_unit
          operation
          ttl
          pieces_plan_day
          pieces_plan_shift
          last_production_at
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateWorkCenterStatusMutation>response.data.updateWorkCenterStatus;
  }
  async UpdateConfig(input: UpdateConfigInput): Promise<UpdateConfigMutation> {
    const statement = `mutation UpdateConfig($input: UpdateConfigInput!) {
        updateConfig(input: $input) {
          __typename
          key
          value
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateConfigMutation>response.data.updateConfig;
  }
  async SplitWorkCenterPeriods(
    input: SplitWorkCenterPeriodsInput
  ): Promise<SplitWorkCenterPeriodsMutation> {
    const statement = `mutation SplitWorkCenterPeriods($input: SplitWorkCenterPeriodsInput!) {
        splitWorkCenterPeriods(input: $input) {
          __typename
          items {
            __typename
            end
            index
            pieces
            start
            status
            location
            shift_date
            shift_number
            ttl
            production_order
            material
            speed_target
            speed_unit
            operation
            pieces_order
            pieces_day
            pieces_shift
            flag
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SplitWorkCenterPeriodsMutation>response.data.splitWorkCenterPeriods;
  }
  async GetProductionOrderHeader(
    plant: string,
    production_order: string
  ): Promise<GetProductionOrderHeaderQuery> {
    const statement = `query GetProductionOrderHeader($plant: String!, $production_order: String!) {
        getProductionOrderHeader(plant: $plant, production_order: $production_order) {
          __typename
          production_order
          plant
          modification_date
          sttxt
          material
          material_description
          material_weight
          material_weight_unit
          raw_material_batch_number
          raw_material
          raw_material_size
          production_order_batch
          consumed_containers
          consumed_containers_unit
          consumed_quantity
          consumed_quantity_unit
          ttl
        }
      }`;
    const gqlAPIServiceArguments: any = {
      plant,
      production_order
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProductionOrderHeaderQuery>(
      response.data.getProductionOrderHeader
    );
  }
  async GetProductionOrderOperation(
    operation: number,
    production_order: string
  ): Promise<GetProductionOrderOperationQuery> {
    const statement = `query GetProductionOrderOperation($operation: Int!, $production_order: String!) {
        getProductionOrderOperation(operation: $operation, production_order: $production_order) {
          __typename
          production_order
          operation
          department
          workcenter
          press_family
          modification_date
          vsttxt
          asttx
          darue
          target_speed
          speed_unit
          target_quantity
          waste_quantity
          confirmed_quantity
          quantity_unit
          priority
          scheduled_start
          scheduled_end
          actual_end
          actual_start
          status
          comment
          comments {
            __typename
            items {
              __typename
              production_order
              operation
              timestamp
              comment
              operator
              ttl
            }
            nextToken
          }
          material
          material_description
          average_speed
          raw_material
          raw_material_batch_number
          production_order_batch
          sttxt
          setup_time
          setup_time_unit
          tool_available
          ttl
        }
      }`;
    const gqlAPIServiceArguments: any = {
      operation,
      production_order
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetProductionOrderOperationQuery>(
      response.data.getProductionOrderOperation
    );
  }
  async GetWorkCenterPeriods(
    index: string,
    start: number
  ): Promise<GetWorkCenterPeriodsQuery> {
    const statement = `query GetWorkCenterPeriods($index: String!, $start: Float!) {
        getWorkCenterPeriods(index: $index, start: $start) {
          __typename
          end
          index
          pieces
          start
          status
          location
          shift_date
          shift_number
          ttl
          production_order
          material
          speed_target
          speed_unit
          operation
          pieces_order
          pieces_day
          pieces_shift
          flag
        }
      }`;
    const gqlAPIServiceArguments: any = {
      index,
      start
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetWorkCenterPeriodsQuery>response.data.getWorkCenterPeriods;
  }
  async GetWorkCenterStatus(
    plant: string,
    workcenter: string
  ): Promise<GetWorkCenterStatusQuery> {
    const statement = `query GetWorkCenterStatus($plant: String!, $workcenter: String!) {
        getWorkCenterStatus(plant: $plant, workcenter: $workcenter) {
          __typename
          plant
          workcenter
          name
          status
          timestamp
          current_period
          shift_date
          shift_number
          shift_start
          shift_end
          shift_plan
          production_order
          material
          speed_actual
          speed_target
          speed_unit
          operation
          ttl
          pieces_plan_day
          pieces_plan_shift
          last_production_at
        }
      }`;
    const gqlAPIServiceArguments: any = {
      plant,
      workcenter
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetWorkCenterStatusQuery>response.data.getWorkCenterStatus;
  }
  async GetConfig(key: string): Promise<GetConfigQuery> {
    const statement = `query GetConfig($key: String!) {
        getConfig(key: $key) {
          __typename
          key
          value
        }
      }`;
    const gqlAPIServiceArguments: any = {
      key
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetConfigQuery>response.data.getConfig;
  }
  async GetKpi(index: string): Promise<GetKpiQuery> {
    const statement = `query GetKpi($index: String!) {
        getKpi(index: $index) {
          __typename
          index
          time
          location
          kpi
          period
          value
          target
          unit
          ttl
        }
      }`;
    const gqlAPIServiceArguments: any = {
      index
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetKpiQuery>response.data.getKpi;
  }
  async ListProductionOrderHeaders(
    filter?: TableProductionOrderHeaderFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListProductionOrderHeadersQuery> {
    const statement = `query ListProductionOrderHeaders($filter: TableProductionOrderHeaderFilterInput, $limit: Int, $nextToken: String) {
        listProductionOrderHeaders(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            production_order
            plant
            modification_date
            sttxt
            material
            material_description
            material_weight
            material_weight_unit
            raw_material_batch_number
            raw_material
            raw_material_size
            production_order_batch
            consumed_containers
            consumed_containers_unit
            consumed_quantity
            consumed_quantity_unit
            ttl
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProductionOrderHeadersQuery>(
      response.data.listProductionOrderHeaders
    );
  }
  async ListProductionOrderOperations(
    production_order: string
  ): Promise<ListProductionOrderOperationsQuery> {
    const statement = `query ListProductionOrderOperations($production_order: String!) {
        listProductionOrderOperations(production_order: $production_order) {
          __typename
          items {
            __typename
            production_order
            operation
            department
            workcenter
            press_family
            modification_date
            vsttxt
            asttx
            darue
            target_speed
            speed_unit
            target_quantity
            waste_quantity
            confirmed_quantity
            quantity_unit
            priority
            scheduled_start
            scheduled_end
            actual_end
            actual_start
            status
            comment
            comments {
              __typename
              items {
                __typename
                production_order
                operation
                timestamp
                comment
                operator
                ttl
              }
              nextToken
            }
            material
            material_description
            average_speed
            raw_material
            raw_material_batch_number
            production_order_batch
            sttxt
            setup_time
            setup_time_unit
            tool_available
            ttl
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      production_order
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProductionOrderOperationsQuery>(
      response.data.listProductionOrderOperations
    );
  }
  async QueryProductionOrderOperations(
    workcenter: string,
    status?: Array<string | null>
  ): Promise<QueryProductionOrderOperationsQuery> {
    const statement = `query QueryProductionOrderOperations($workcenter: String!, $status: [String]) {
        queryProductionOrderOperations(workcenter: $workcenter, status: $status) {
          __typename
          items {
            __typename
            production_order
            operation
            department
            workcenter
            press_family
            modification_date
            vsttxt
            asttx
            darue
            target_speed
            speed_unit
            target_quantity
            waste_quantity
            confirmed_quantity
            quantity_unit
            priority
            scheduled_start
            scheduled_end
            actual_end
            actual_start
            status
            comment
            comments {
              __typename
              items {
                __typename
                production_order
                operation
                timestamp
                comment
                operator
                ttl
              }
              nextToken
            }
            material
            material_description
            average_speed
            raw_material
            raw_material_batch_number
            production_order_batch
            sttxt
            setup_time
            setup_time_unit
            tool_available
            ttl
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      workcenter
    };
    if (status) {
      gqlAPIServiceArguments.status = status;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <QueryProductionOrderOperationsQuery>(
      response.data.queryProductionOrderOperations
    );
  }
  async ListProductionOrderComments(
    production_order: string,
    operation: number
  ): Promise<ListProductionOrderCommentsQuery> {
    const statement = `query ListProductionOrderComments($production_order: String!, $operation: Int!) {
        listProductionOrderComments(production_order: $production_order, operation: $operation) {
          __typename
          items {
            __typename
            production_order
            operation
            timestamp
            comment
            operator
            ttl
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      production_order,
      operation
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListProductionOrderCommentsQuery>(
      response.data.listProductionOrderComments
    );
  }
  async ListWorkCenterPeriods(
    filter?: TableWorkCenterPeriodsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListWorkCenterPeriodsQuery> {
    const statement = `query ListWorkCenterPeriods($filter: TableWorkCenterPeriodsFilterInput, $limit: Int, $nextToken: String) {
        listWorkCenterPeriods(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            end
            index
            pieces
            start
            status
            location
            shift_date
            shift_number
            ttl
            production_order
            material
            speed_target
            speed_unit
            operation
            pieces_order
            pieces_day
            pieces_shift
            flag
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListWorkCenterPeriodsQuery>response.data.listWorkCenterPeriods;
  }
  async QueryWorkCenterPeriods(
    index: string,
    start: number,
    end: number
  ): Promise<QueryWorkCenterPeriodsQuery> {
    const statement = `query QueryWorkCenterPeriods($index: String!, $start: Float!, $end: Float!) {
        queryWorkCenterPeriods(index: $index, start: $start, end: $end) {
          __typename
          items {
            __typename
            end
            index
            pieces
            start
            status
            location
            shift_date
            shift_number
            ttl
            production_order
            material
            speed_target
            speed_unit
            operation
            pieces_order
            pieces_day
            pieces_shift
            flag
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      index,
      start,
      end
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <QueryWorkCenterPeriodsQuery>response.data.queryWorkCenterPeriods;
  }
  async GetWorkCenterPeriodsByShift(
    index: string,
    shift_date: string,
    shift_number: number
  ): Promise<GetWorkCenterPeriodsByShiftQuery> {
    const statement = `query GetWorkCenterPeriodsByShift($index: String!, $shift_date: String!, $shift_number: Int!) {
        getWorkCenterPeriodsByShift(index: $index, shift_date: $shift_date, shift_number: $shift_number) {
          __typename
          items {
            __typename
            end
            index
            pieces
            start
            status
            location
            shift_date
            shift_number
            ttl
            production_order
            material
            speed_target
            speed_unit
            operation
            pieces_order
            pieces_day
            pieces_shift
            flag
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      index,
      shift_date,
      shift_number
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetWorkCenterPeriodsByShiftQuery>(
      response.data.getWorkCenterPeriodsByShift
    );
  }
  async ListWorkCenterStatuses(
    filter?: TableWorkCenterStatusFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListWorkCenterStatusesQuery> {
    const statement = `query ListWorkCenterStatuses($filter: TableWorkCenterStatusFilterInput, $limit: Int, $nextToken: String) {
        listWorkCenterStatuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            plant
            workcenter
            name
            status
            timestamp
            current_period
            shift_date
            shift_number
            shift_start
            shift_end
            shift_plan
            production_order
            material
            speed_actual
            speed_target
            speed_unit
            operation
            ttl
            pieces_plan_day
            pieces_plan_shift
            last_production_at
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListWorkCenterStatusesQuery>response.data.listWorkCenterStatuses;
  }
  async ListConfigs(
    filter?: TableConfigFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListConfigsQuery> {
    const statement = `query ListConfigs($filter: TableConfigFilterInput, $limit: Int, $nextToken: String) {
        listConfigs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            key
            value
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListConfigsQuery>response.data.listConfigs;
  }
  async ListKpis(
    filter?: TableKpiFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListKpisQuery> {
    const statement = `query ListKpis($filter: TableKpiFilterInput, $limit: Int, $nextToken: String) {
        listKpis(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            index
            time
            location
            kpi
            period
            value
            target
            unit
            ttl
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListKpisQuery>response.data.listKpis;
  }
  OnCreateProductionOrderHeaderListener(
    plant?: string,
    production_order?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateProductionOrderHeader">
    >
  > {
    const statement = `subscription OnCreateProductionOrderHeader($plant: String, $production_order: String) {
        onCreateProductionOrderHeader(plant: $plant, production_order: $production_order) {
          __typename
          production_order
          plant
          modification_date
          sttxt
          material
          material_description
          material_weight
          material_weight_unit
          raw_material_batch_number
          raw_material
          raw_material_size
          production_order_batch
          consumed_containers
          consumed_containers_unit
          consumed_quantity
          consumed_quantity_unit
          ttl
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (plant) {
      gqlAPIServiceArguments.plant = plant;
    }
    if (production_order) {
      gqlAPIServiceArguments.production_order = production_order;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateProductionOrderHeader">
      >
    >;
  }

  OnCreateProductionOrderOperationListener(
    production_order?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateProductionOrderOperation">
    >
  > {
    const statement = `subscription OnCreateProductionOrderOperation($production_order: String) {
        onCreateProductionOrderOperation(production_order: $production_order) {
          __typename
          production_order
          operation
          department
          workcenter
          press_family
          modification_date
          vsttxt
          asttx
          darue
          target_speed
          speed_unit
          target_quantity
          waste_quantity
          confirmed_quantity
          quantity_unit
          priority
          scheduled_start
          scheduled_end
          actual_end
          actual_start
          status
          comment
          comments {
            __typename
            items {
              __typename
              production_order
              operation
              timestamp
              comment
              operator
              ttl
            }
            nextToken
          }
          material
          material_description
          average_speed
          raw_material
          raw_material_batch_number
          production_order_batch
          sttxt
          setup_time
          setup_time_unit
          tool_available
          ttl
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (production_order) {
      gqlAPIServiceArguments.production_order = production_order;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateProductionOrderOperation">
      >
    >;
  }

  OnCreateProductionOrderListItemListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateProductionOrderListItem">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateProductionOrderListItem {
        onCreateProductionOrderListItem {
          __typename
          production_order
          operation
          department
          workcenter
          press_family
          modification_date
          vsttxt
          asttx
          darue
          target_speed
          speed_unit
          target_quantity
          waste_quantity
          confirmed_quantity
          quantity_unit
          priority
          scheduled_start
          scheduled_end
          actual_end
          actual_start
          status
          comment
          comments {
            __typename
            items {
              __typename
              production_order
              operation
              timestamp
              comment
              operator
              ttl
            }
            nextToken
          }
          material
          material_description
          average_speed
          raw_material
          raw_material_batch_number
          production_order_batch
          sttxt
          setup_time
          setup_time_unit
          tool_available
          ttl
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateProductionOrderListItem">
    >
  >;

  OnCreateProductionOrderCommentListener(
    production_order?: string,
    operation?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateProductionOrderComment">
    >
  > {
    const statement = `subscription OnCreateProductionOrderComment($production_order: String, $operation: Int) {
        onCreateProductionOrderComment(production_order: $production_order, operation: $operation) {
          __typename
          production_order
          operation
          timestamp
          comment
          operator
          ttl
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (production_order) {
      gqlAPIServiceArguments.production_order = production_order;
    }
    if (operation) {
      gqlAPIServiceArguments.operation = operation;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateProductionOrderComment">
      >
    >;
  }

  OnCreateProductionOrderCommentAnyListener: Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateProductionOrderCommentAny">
    >
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateProductionOrderCommentAny {
        onCreateProductionOrderCommentAny {
          __typename
          production_order
          operation
          timestamp
          comment
          operator
          ttl
        }
      }`
    )
  ) as Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateProductionOrderCommentAny">
    >
  >;

  OnCreateWorkCenterPeriodsListener(
    index?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateWorkCenterPeriods">
    >
  > {
    const statement = `subscription OnCreateWorkCenterPeriods($index: String) {
        onCreateWorkCenterPeriods(index: $index) {
          __typename
          end
          index
          pieces
          start
          status
          location
          shift_date
          shift_number
          ttl
          production_order
          material
          speed_target
          speed_unit
          operation
          pieces_order
          pieces_day
          pieces_shift
          flag
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (index) {
      gqlAPIServiceArguments.index = index;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateWorkCenterPeriods">
      >
    >;
  }

  OnCreateWorkCenterStatusListener(
    plant?: string,
    workcenter?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateWorkCenterStatus">
    >
  > {
    const statement = `subscription OnCreateWorkCenterStatus($plant: String, $workcenter: String) {
        onCreateWorkCenterStatus(plant: $plant, workcenter: $workcenter) {
          __typename
          plant
          workcenter
          name
          status
          timestamp
          current_period
          shift_date
          shift_number
          shift_start
          shift_end
          shift_plan
          production_order
          material
          speed_actual
          speed_target
          speed_unit
          operation
          ttl
          pieces_plan_day
          pieces_plan_shift
          last_production_at
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (plant) {
      gqlAPIServiceArguments.plant = plant;
    }
    if (workcenter) {
      gqlAPIServiceArguments.workcenter = workcenter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateWorkCenterStatus">
      >
    >;
  }

  OnCreateConfigListener(
    key?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateConfig">>
  > {
    const statement = `subscription OnCreateConfig($key: String) {
        onCreateConfig(key: $key) {
          __typename
          key
          value
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (key) {
      gqlAPIServiceArguments.key = key;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateConfig">>
    >;
  }

  OnCreateKpiListener(
    index?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateKpi">>
  > {
    const statement = `subscription OnCreateKpi($index: String) {
        onCreateKpi(index: $index) {
          __typename
          index
          time
          location
          kpi
          period
          value
          target
          unit
          ttl
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (index) {
      gqlAPIServiceArguments.index = index;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateKpi">>
    >;
  }

  OnDeleteProductionOrderHeaderListener(
    plant?: string,
    production_order?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteProductionOrderHeader">
    >
  > {
    const statement = `subscription OnDeleteProductionOrderHeader($plant: String, $production_order: String) {
        onDeleteProductionOrderHeader(plant: $plant, production_order: $production_order) {
          __typename
          production_order
          plant
          modification_date
          sttxt
          material
          material_description
          material_weight
          material_weight_unit
          raw_material_batch_number
          raw_material
          raw_material_size
          production_order_batch
          consumed_containers
          consumed_containers_unit
          consumed_quantity
          consumed_quantity_unit
          ttl
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (plant) {
      gqlAPIServiceArguments.plant = plant;
    }
    if (production_order) {
      gqlAPIServiceArguments.production_order = production_order;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteProductionOrderHeader">
      >
    >;
  }

  OnDeleteProductionOrderOperationListener(
    department?: string,
    operation?: number,
    production_order?: string,
    workcenter?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteProductionOrderOperation">
    >
  > {
    const statement = `subscription OnDeleteProductionOrderOperation($department: String, $operation: Int, $production_order: String, $workcenter: String) {
        onDeleteProductionOrderOperation(department: $department, operation: $operation, production_order: $production_order, workcenter: $workcenter) {
          __typename
          production_order
          operation
          department
          workcenter
          press_family
          modification_date
          vsttxt
          asttx
          darue
          target_speed
          speed_unit
          target_quantity
          waste_quantity
          confirmed_quantity
          quantity_unit
          priority
          scheduled_start
          scheduled_end
          actual_end
          actual_start
          status
          comment
          comments {
            __typename
            items {
              __typename
              production_order
              operation
              timestamp
              comment
              operator
              ttl
            }
            nextToken
          }
          material
          material_description
          average_speed
          raw_material
          raw_material_batch_number
          production_order_batch
          sttxt
          setup_time
          setup_time_unit
          tool_available
          ttl
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (department) {
      gqlAPIServiceArguments.department = department;
    }
    if (operation) {
      gqlAPIServiceArguments.operation = operation;
    }
    if (production_order) {
      gqlAPIServiceArguments.production_order = production_order;
    }
    if (workcenter) {
      gqlAPIServiceArguments.workcenter = workcenter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteProductionOrderOperation">
      >
    >;
  }

  OnDeleteProductionOrderCommentListener(
    production_order?: string,
    operation?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteProductionOrderComment">
    >
  > {
    const statement = `subscription OnDeleteProductionOrderComment($production_order: String, $operation: Int) {
        onDeleteProductionOrderComment(production_order: $production_order, operation: $operation) {
          __typename
          production_order
          operation
          timestamp
          comment
          operator
          ttl
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (production_order) {
      gqlAPIServiceArguments.production_order = production_order;
    }
    if (operation) {
      gqlAPIServiceArguments.operation = operation;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteProductionOrderComment">
      >
    >;
  }

  OnDeleteWorkCenterPeriodsListener(
    index?: string,
    start?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteWorkCenterPeriods">
    >
  > {
    const statement = `subscription OnDeleteWorkCenterPeriods($index: String, $start: Float) {
        onDeleteWorkCenterPeriods(index: $index, start: $start) {
          __typename
          end
          index
          pieces
          start
          status
          location
          shift_date
          shift_number
          ttl
          production_order
          material
          speed_target
          speed_unit
          operation
          pieces_order
          pieces_day
          pieces_shift
          flag
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (index) {
      gqlAPIServiceArguments.index = index;
    }
    if (start) {
      gqlAPIServiceArguments.start = start;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteWorkCenterPeriods">
      >
    >;
  }

  OnDeleteWorkCenterStatusListener(
    plant?: string,
    workcenter?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteWorkCenterStatus">
    >
  > {
    const statement = `subscription OnDeleteWorkCenterStatus($plant: String, $workcenter: String) {
        onDeleteWorkCenterStatus(plant: $plant, workcenter: $workcenter) {
          __typename
          plant
          workcenter
          name
          status
          timestamp
          current_period
          shift_date
          shift_number
          shift_start
          shift_end
          shift_plan
          production_order
          material
          speed_actual
          speed_target
          speed_unit
          operation
          ttl
          pieces_plan_day
          pieces_plan_shift
          last_production_at
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (plant) {
      gqlAPIServiceArguments.plant = plant;
    }
    if (workcenter) {
      gqlAPIServiceArguments.workcenter = workcenter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteWorkCenterStatus">
      >
    >;
  }

  OnDeleteConfigListener(
    key?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteConfig">>
  > {
    const statement = `subscription OnDeleteConfig($key: String) {
        onDeleteConfig(key: $key) {
          __typename
          key
          value
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (key) {
      gqlAPIServiceArguments.key = key;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteConfig">>
    >;
  }

  OnDeleteKpiListener(
    index?: string,
    time?: number
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteKpi">>
  > {
    const statement = `subscription OnDeleteKpi($index: String, $time: Float) {
        onDeleteKpi(index: $index, time: $time) {
          __typename
          index
          time
          location
          kpi
          period
          value
          target
          unit
          ttl
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (index) {
      gqlAPIServiceArguments.index = index;
    }
    if (time) {
      gqlAPIServiceArguments.time = time;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteKpi">>
    >;
  }

  OnUpdateProductionOrderHeaderListener(
    plant?: string,
    production_order?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateProductionOrderHeader">
    >
  > {
    const statement = `subscription OnUpdateProductionOrderHeader($plant: String, $production_order: String) {
        onUpdateProductionOrderHeader(plant: $plant, production_order: $production_order) {
          __typename
          production_order
          plant
          modification_date
          sttxt
          material
          material_description
          material_weight
          material_weight_unit
          raw_material_batch_number
          raw_material
          raw_material_size
          production_order_batch
          consumed_containers
          consumed_containers_unit
          consumed_quantity
          consumed_quantity_unit
          ttl
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (plant) {
      gqlAPIServiceArguments.plant = plant;
    }
    if (production_order) {
      gqlAPIServiceArguments.production_order = production_order;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateProductionOrderHeader">
      >
    >;
  }

  OnUpdateProductionOrderOperationListListener(
    workcenter?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateProductionOrderOperationList">
    >
  > {
    const statement = `subscription OnUpdateProductionOrderOperationList($workcenter: String) {
        onUpdateProductionOrderOperationList(workcenter: $workcenter) {
          __typename
          production_order
          operation
          department
          workcenter
          press_family
          modification_date
          vsttxt
          asttx
          darue
          target_speed
          speed_unit
          target_quantity
          waste_quantity
          confirmed_quantity
          quantity_unit
          priority
          scheduled_start
          scheduled_end
          actual_end
          actual_start
          status
          comment
          comments {
            __typename
            items {
              __typename
              production_order
              operation
              timestamp
              comment
              operator
              ttl
            }
            nextToken
          }
          material
          material_description
          average_speed
          raw_material
          raw_material_batch_number
          production_order_batch
          sttxt
          setup_time
          setup_time_unit
          tool_available
          ttl
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (workcenter) {
      gqlAPIServiceArguments.workcenter = workcenter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateProductionOrderOperationList">
      >
    >;
  }

  OnUpdateWorkCenterPeriodsListener(
    index?: string,
    start?: number
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateWorkCenterPeriods">
    >
  > {
    const statement = `subscription OnUpdateWorkCenterPeriods($index: String, $start: Float) {
        onUpdateWorkCenterPeriods(index: $index, start: $start) {
          __typename
          end
          index
          pieces
          start
          status
          location
          shift_date
          shift_number
          ttl
          production_order
          material
          speed_target
          speed_unit
          operation
          pieces_order
          pieces_day
          pieces_shift
          flag
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (index) {
      gqlAPIServiceArguments.index = index;
    }
    if (start) {
      gqlAPIServiceArguments.start = start;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateWorkCenterPeriods">
      >
    >;
  }

  OnUpdateWorkCenterStatusListener(
    plant?: string,
    workcenter?: string
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateWorkCenterStatus">
    >
  > {
    const statement = `subscription OnUpdateWorkCenterStatus($plant: String, $workcenter: String) {
        onUpdateWorkCenterStatus(plant: $plant, workcenter: $workcenter) {
          __typename
          plant
          workcenter
          name
          status
          timestamp
          current_period
          shift_date
          shift_number
          shift_start
          shift_end
          shift_plan
          production_order
          material
          speed_actual
          speed_target
          speed_unit
          operation
          ttl
          pieces_plan_day
          pieces_plan_shift
          last_production_at
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (plant) {
      gqlAPIServiceArguments.plant = plant;
    }
    if (workcenter) {
      gqlAPIServiceArguments.workcenter = workcenter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateWorkCenterStatus">
      >
    >;
  }

  OnUpdateConfigListener(
    key?: string
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateConfig">>
  > {
    const statement = `subscription OnUpdateConfig($key: String) {
        onUpdateConfig(key: $key) {
          __typename
          key
          value
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (key) {
      gqlAPIServiceArguments.key = key;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateConfig">>
    >;
  }
}
