import { Injectable } from '@angular/core';
import { ChartOptions, ChartPoint } from 'chart.js';
import { isAfter, subDays, addDays, isEqual } from 'date-fns';
import { Color } from 'ng2-charts';
import {
  PlannedPieces,
  ProducedPiecesPeriodDashboard,
  ProducedPiecesPeriodShift,
} from 'src/app/model/produced-pieces-chart.model';

@Injectable({
  providedIn: 'root',
})
export class ProducedPiecesChartService {
  constructor() {}

  public mapProducedPiecesToChartPoints(
    producedPiecesPeriods = [],
    graphStartDate: number | Date,
  ): ChartPoint[] {
    return producedPiecesPeriods
      .map(
        ({
          end,
          time,
          pieces_shift,
          count,
        }: ProducedPiecesPeriodShift & ProducedPiecesPeriodDashboard) => {
          const periodTime = end || time;
          const piecesCount = pieces_shift ?? count;
          return {
            x: new Date(periodTime),
            y: piecesCount,
          };
        },
      )
      .filter(
        (data) =>
          isEqual(new Date(data.x), graphStartDate) ||
          isAfter(new Date(data.x), graphStartDate),
      )
      .sort((a, b) => new Date(a.x).getTime() - new Date(b.x).getTime());
  }

  public calculateTargetLine({ time, pieces }: PlannedPieces): ChartPoint[] {
    return pieces.map((piece, index) => ({
      x: new Date(time[index]),
      y: piece,
    }));
  }

  public getChartShiftStartEndDates(
    currentShift: number,
    shiftStart: number,
    shiftEnd: number,
  ): { graphShiftStartDate: number; graphShiftEndDate: number } {
    let graphShiftStartDate: number;
    let graphShiftEndDate: number;
    const dateNow = new Date();
    const isThirdShift = currentShift === 3;
    const isThirdShiftAfterMidnight =
      isThirdShift && dateNow.getHours() < new Date(shiftEnd).getHours();

    if (isThirdShiftAfterMidnight) {
      const yesterday = subDays(dateNow, 1);
      graphShiftStartDate = yesterday.setHours(
        new Date(shiftStart).getHours(),
        0,
        0,
        0,
      );
    } else {
      graphShiftStartDate = new Date().setHours(
        new Date(shiftStart).getHours(),
        0,
        0,
        0,
      );
    }

    if (isThirdShift && !isThirdShiftAfterMidnight) {
      const tomorrow = addDays(dateNow, 1);
      graphShiftEndDate = tomorrow.setHours(
        new Date(shiftEnd).getHours(),
        0,
        0,
        0,
      );
    } else {
      graphShiftEndDate = new Date().setHours(
        new Date(shiftEnd).getHours(),
        0,
        0,
        0,
      );
    }

    return { graphShiftStartDate, graphShiftEndDate };
  }

  public getLineChartColors(isShift: boolean): Color[] {
    const mainLineColor = '#333333';

    return [
      {
        borderColor: mainLineColor,
        backgroundColor: 'rgba(255,0,0,0)',
      },
      {
        borderColor: isShift ? '#C197A9' : '#E74C3C',
        backgroundColor: 'rgba(255,0,0,0)',
        pointBackgroundColor: 'rgba(255,0,0,0)',
        pointBorderColor: 'rgba(255,0,0,0)',
      },
      {
        borderColor: '#E74C3C',
        backgroundColor: 'rgba(255,0,0,0)',
        pointBackgroundColor: 'rgba(255,0,0,0)',
        pointBorderColor: 'rgba(255,0,0,0)',
        borderWidth: 2,
      },
    ];
  }

  public getLineChartOptions(
    target?: number,
    maintainAspectRatio = true,
  ): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio,
      animation: {
        duration: 0,
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            time: {
              unit: 'hour',
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              suggestedMax: 100,
              /* istanbul ignore next */
              callback(value, index, values): string {
                return value.toLocaleString(localStorage.getItem('locale'));
              },
            },
          },
        ],
      },
      // @ts-ignore:next-line
      annotation: {
        annotations: [
          {
            type: 'line',
            mode: 'horizontal',
            scaleID: 'y-axis-0',
            value: target,
            borderColor: '#E74C3C',
            borderWidth: 3,
          },
        ],
      },
    };
  }
}
