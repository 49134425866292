<div *ngIf="!router.url.includes('details')">
  <div
    *ngIf="loaderService.isLoading | async; else elseBlock"
    class="loader-wrapper"
  >
    <app-loader></app-loader>
  </div>
  <ng-template #elseBlock>
    <div class="sub-kpi-wrapper">
      <div *ngFor="let day of selectedDayData | keyvalue">
        <div *ngIf="day?.key != 'date'">
          <app-single-day-preview
            [data]="day?.value"
            [title]="day.key != 'date' ? day.key : ''"
          ></app-single-day-preview>
        </div>
      </div>
    </div>
    <div>
      <app-calendar-view
        heading="{{ 'HEADER.QUALITY' | translate }}"
        [title]="'Quality'"
        [daysInMonth]="daysInMonth"
      ></app-calendar-view>
      <app-calendar-view
        heading="{{ 'HEADER.PERFORMANCE' | translate }}"
        [title]="'Performance'"
        [daysInMonth]="daysInMonth"
      ></app-calendar-view>
      <app-calendar-view
        heading="{{ 'HEADER.DELIVERY' | translate }}"
        [title]="'Delivery'"
        [daysInMonth]="daysInMonth"
      ></app-calendar-view>
      <app-calendar-view
        heading="{{ 'HEADER.COSTS' | translate }}"
        [title]="'Costs'"
        [daysInMonth]="daysInMonth"
      ></app-calendar-view>
    </div>
  </ng-template>
</div>
<router-outlet></router-outlet>
