<div class="reports-wrapper">
  <div class="dropdown-item-wrapper">
    <div class="dropdown-item">
      <app-dropdown-select> </app-dropdown-select>
    </div>

    <div class="dropdown-item">
      <app-datepicker [isSingleDayPreview]="true"></app-datepicker>
    </div>
  </div>
  <router-outlet></router-outlet>
</div>
