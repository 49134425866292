import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { WorkStationService } from './work-station.service';
import { RealtimeDataResolver } from '../model/realtime-data.model';
import { Group } from '../model/workstation-data.model';

@Injectable({
  providedIn: 'root',
})
export class RealtimeDataResolverService
  implements Resolve<RealtimeDataResolver> {
  groups: Group[];

  constructor(
    private translate: TranslateService,
    private sharedService: SharedService,
    private workStationService: WorkStationService,
  ) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<RealtimeDataResolver> {
    if (!this.groups) {
      await this.workStationService.fetchGroupsLiveData();
    }
    this.workStationService.groups.subscribe((groups: Group[]) => {
      if (groups.length) {
        this.groups = groups;
      }
    });
    const departmentId =
      route?.params?.departmentId ||
      this.sharedService.getDepartmentId(this.groups);
    const groupId = route?.params?.groupId;
    const subgroupName = route?.params?.subgroupName;
    const machineName = route?.params?.machineName;

    return {
      realtimeData: this.translate.instant('SIDEBAR.REALTIME-DATA'),
      department: departmentId,
      groupId,
      subgroupName,
      machineName,
    };
  }
}
