import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { init } from '@sentry/angular';

if (environment.production) {
  enableProdMode();

  init({
    dsn: environment.dsnSentry,
    environment: environment.env,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
