import { isFuture } from 'date-fns';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-day-node',
  templateUrl: './day-node.component.html',
  styleUrls: ['./day-node.component.scss'],
})
export class DayNodeComponent implements OnInit {
  @Input() dayData: any;
  @Input() calendarType: string;
  @Input() dayInMonth: number;
  color: string;
  filteredDayData: any;

  constructor() {}

  ngOnInit(): void {
    this.filteredDayData = this.dayData[this.calendarType]?.find(
      (day) => day.isCalendarOverview,
    );
    this.setNodeColor();    
  }

  setNodeColor(): void {
    if (
      !isFuture(new Date(this.dayData.date)) &&
      this.filteredDayData?.isTargetFulfilled
    ) {
      this.color = 'green';
    } else if (this.filteredDayData?.isTargetFulfilled === undefined) {
      this.color = 'grey';
    } else if (this.filteredDayData.isTargetFulfilled) {
      this.color = 'green';
    } else {
      this.color = 'red';
    }
  }
}
