import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Params } from '@angular/router';
import { RouterParamsService } from 'projects/shiftleader-dashboard/src/app/services/router-params.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  title: string;
  headingTitle: string;
  isShiftleaderDashboard = true;

  constructor(
    private authService: AuthService,
    private routerParamsService: RouterParamsService,
    private location: Location,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.authService.isPlantIdSet()) {
      this.setIsShiftleaderDashboard();
      if (this.isShiftleaderDashboard) {
        await this.routerParamsService.initRouterParamsService();
      }
      this.getPageTitle();
    }
  }

  getPageTitle(): void {
    this.routerParamsService.routerParams.subscribe(
      async (routerParams: Params) => {
        if (this.isShiftleaderDashboard) {
          this.headingTitle = await this.routerParamsService.setHeadingTitle(
            routerParams,
          );
        } else {
          this.headingTitle = 'Smart.Shopfloor - Operator Cockpit';
        }
      },
    );
  }

  setIsShiftleaderDashboard(): void {
    this.isShiftleaderDashboard = this.location
      .path()
      .includes('shiftleader-dashboard');
  }

  onLogout(): void {
    this.authService.signOut();
  }

  navigateBack(): void {
    this.location.back();
  }
}
