import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { subBusinessDays } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class DatePickerService {
  selectedDateSubject = new BehaviorSubject<Date>(new Date());
  selectedDate: Observable<any> = this.selectedDateSubject.asObservable();

  singleDayDateSubject = new BehaviorSubject<Date>(
    subBusinessDays(new Date(), 1),
  );
  singleDayDate: Observable<any> = this.singleDayDateSubject.asObservable();

  constructor() {}
}
