import { Injectable } from '@angular/core';
import {
  Router,
  NavigationEnd,
  ActivatedRouteSnapshot,
  Data,
} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private breadcrumbsSubject = new BehaviorSubject<any>(null);
  readonly breadcrumbs = this.breadcrumbsSubject.asObservable();

  constructor(private router: Router) {
    this.initializeBreadcrumbs();
  }

  initializeBreadcrumbs(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const root = this.router.routerState.snapshot.root;

        const breadcrumbs = [];
        this.addBreadcrumb(root, [], breadcrumbs);
        // make sure that breadcrumbs do not contain any duplicates
        const distinctBreadcrumbs = [
          ...new Map(
            breadcrumbs.map((breadcrumb) => [breadcrumb.label, breadcrumb]),
          ).values(),
        ];

        this.breadcrumbsSubject.next(distinctBreadcrumbs);
      });
  }

  private addBreadcrumb(
    route: ActivatedRouteSnapshot,
    parentUrl: string[],
    breadcrumbs,
  ): any[] {
    if (route) {
      const routeUrl = parentUrl.concat(route.url.map((url) => url.path));
      if (route.data.breadcrumb) {
        const breadcrumb = {
          label: this.getLabel(route.data),
          url: '/' + routeUrl.join('/'),
        };
        breadcrumbs.push(breadcrumb);
      }

      this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
      return breadcrumbs;
    }
  }

  private getLabel(data: Data): any {
    return typeof data.breadcrumb === 'function'
      ? data.breadcrumb(data)
      : data.breadcrumb;
  }
}
