import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-subgroup-list',
  templateUrl: './subgroup-list.component.html',
  styleUrls: ['./subgroup-list.component.scss'],
})
export class SubgroupListComponent implements OnInit {
  @Input() subgroupList;
  @Input() groupId;
  @Input() departmentId;

  constructor() {}

  ngOnInit(): void {}
}
