import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProductOrderService } from 'projects/shiftleader-dashboard/src/app/services/product-order.service';
import { WorkStationService } from 'projects/shiftleader-dashboard/src/app/services/work-station.service';
import { ConfigService } from 'projects/shiftleader-dashboard/src/app/services/config.service';

@Component({
  selector: 'app-product-order-expansion-panel-list',
  templateUrl: './product-order-expansion-panel-list.component.html',
  styleUrls: ['./product-order-expansion-panel-list.component.scss'],
})
export class ProductOrderExpansionPanelListComponent
  implements OnInit, OnDestroy {
  machineId;
  selectedMachine;
  currentProductOrders = [];
  pastProductOrders = [];
  futureProductOrders = [];
  productOrdersSubscription: Subscription;
  productOrderSubscription: Subscription;
  selectedProductOrder: number;

  constructor(
    private productOrderService: ProductOrderService,
    private route: ActivatedRoute,
    private workStationService: WorkStationService,
    private configService: ConfigService,
  ) {}

  ngOnInit(): void {
    this.setMachineId();
    this.setCurrentProductOrders();

    this.productOrderSubscription = this.productOrderService.selectedProductOrder.subscribe(
      (selectedProductOrder) => {
        this.selectedProductOrder = selectedProductOrder;
      },
    );
  }

  ngOnDestroy(): void {
    if (this.productOrdersSubscription) {
      this.productOrdersSubscription.unsubscribe();
    }

    this.productOrderService.setSelectedProductionOrder(null);
    this.productOrderSubscription.unsubscribe();
  }

  private setMachineId(): void {
    this.route.params.subscribe(async (params: Params) => {
      if (params.machineId) {
        this.machineId = params.machineId;
        this.findMachineById();

        const { data }: any = await this.productOrderService.getProductOrders({
          workCenter: this.machineId,
        });
        this.changeCurrentProductOrder(data);
        this.setNumberOfProductOrdersFromConfig(data);
      }
    });
  }

  private findMachineById(): void {
    this.workStationService.groups.subscribe((groups: any) => {
      if (this.machineId && groups.length) {
        this.selectedMachine = groups
          .flatMap((group) => {
            return group.subgroups.flatMap((subgroup) => subgroup.machines);
          })
          .find((machine) => machine.id === this.machineId);
      }
    });
  }

  private changeCurrentProductOrder(data): void {
    if (data) {
      this.productOrderService.fetchProductOrderLiveData(
        this.machineId,
        data?.current?.orderNumber,
        true,
      );
    }
  }

  private setNumberOfProductOrdersFromConfig(data): void {
    this.configService.configData.subscribe((response) => {
      if (response) {
        const {
          PAST: { numberOfPOs: pastProdOrderLimit },
          FUTURE: { numberOfPOs: futureProdOrderLimit },
        } = response.productOrder;

        this.setPastProductOrders(data.past, pastProdOrderLimit);
        this.setFutureProductOrders(data.planned, futureProdOrderLimit);
      }
    });
  }

  private setCurrentProductOrders(): void {
    this.productOrdersSubscription = this.productOrderService.currentProductOrderSubject.subscribe(
      (productOrders) => {
        this.currentProductOrders = productOrders?.current.map(
          (currentOrder) => ({
            ...currentOrder,
            state: 'current',
            ...(!this.selectedMachine?.supportsMultipleOrders &&
              currentOrder?.workCenter === this.machineId && {
                actualQuantity: this.selectedMachine?.counter,
                actualSpeed: this.selectedMachine?.piecesPerMinute,
              }),
          }),
        );
      },
    );
  }

  private setPastProductOrders(productOrders, limit): void {
    if (productOrders && limit) {
      const lastProductOrders = productOrders.slice(
        Math.max(productOrders.length - limit, 0),
      );
      this.pastProductOrders = lastProductOrders.map((pastOrder) => ({
        ...pastOrder,
        state: 'past',
      }));
    }
  }

  private setFutureProductOrders(productOrders, limit): void {
    if (productOrders && limit) {
      const lastProductOrders = productOrders.slice(0, limit);
      this.futureProductOrders = lastProductOrders.map((futureOrder) => ({
        ...futureOrder,
        state: 'next',
      }));
    }
  }
}
