<div *ngIf="reportType==='Safety'" class="category-wrapper">
  <div *ngFor="let safetyBarHeading of safetyBarHeadingList;let i =index;" [class]="'category-item cat-'+i">
    <div>{{safetyBarHeading}}</div>
    <div>{{(data? data['category_'+i]:0) | number}}</div>
  </div>

  <div class="category-item current-year">
    <div>Jahr {{data?.currentYear}}:</div>
    <div>{{data?.currentYearValue | number}}</div>
  </div>

  <div class="category-item prev-year">
    <div>Jahr {{data?.prevYear}}:</div>
    <div>{{data?.prevYearValue | number}}</div>
  </div>
</div>

<div *ngIf="reportType!=='Safety'" class="category-wrapper">
  <div class="category-item daily">
    <div>Tagesziel:</div>
    <div class="category-item-value">
      {{data?.dailyGoal | number}}
      <div *ngIf="reportType==='Costs'">&nbsp;€</div>
    </div>
  </div>

  <div class="category-item daily">
    <div>Tagesergebnis:</div>
    <div class="category-item-value">
      {{data?.dailyOutput | number}}
      <div *ngIf="reportType==='Costs'">&nbsp;€</div>
    </div>
  </div>

  <div class="category-item current-year">
    <div>Jahr {{data?.currentYear}}:</div>
    <div>{{data?.currentYearValue | number}}</div>
  </div>

  <div class="category-item prev-year">
    <div>Jahr {{data?.prevYear}}:</div>
    <div>{{data?.prevYearValue | number}}</div>
  </div>
</div>
