<mat-sidenav-container class="sidenav-container">
  <div class="sidebar-wrapper">
    <div *ngIf="!isOperatorCockpit()">
      <div
        *ngIf="isRealtimeDataGroup"
        class="sidebar-item dashboard-hover"
        (click)="sidenav.toggle(); setSidebarCategory('RealtimeData')"
      >
        <div class="sidebar-icon">
          <mat-icon aria-hidden="false">equalizer</mat-icon>
        </div>
        <div data-testid="sidebar-realtime-data" class="sidebar-title">
          {{ 'SIDEBAR.REALTIME-DATA' | translate }}
          <mat-icon>arrow_drop_down</mat-icon>
        </div>
      </div>

      <div
        *ngIf="isViewKPIGroup || isEditKPIGroup"
        class="sidebar-item dashboard-hover"
        (click)="sidenav.toggle(); setSidebarCategory('HistoricalData')"
      >
        <div class="sidebar-icon">
          <mat-icon aria-hidden="false">access_time</mat-icon>
        </div>
        <div data-testid="sidebar-historical-data" class="sidebar-title">
          {{ 'SIDEBAR.HISTORICAL-DATA' | translate }}
          <mat-icon>arrow_drop_down</mat-icon>
        </div>
      </div>

      <div
        *ngIf="isEasyToolLifeGroup"
        class="sidebar-item dashboard-hover"
        (click)="sidenav.toggle(); setSidebarCategory('EasyToolLife')"
      >
        <div class="sidebar-icon">
          <mat-icon aria-hidden="false">build</mat-icon>
        </div>
        <div class="sidebar-title">
          {{ 'SIDEBAR.TOOL_LIFETIME' | translate }}
          <mat-icon>arrow_drop_down</mat-icon>
        </div>
      </div>
    </div>

    <mat-sidenav
      #sidenav
      id="sidenav"
      class="sidenav"
      mode="over"
      [@.disabled]="true"
    >
      <div [ngSwitch]="category">
        <div *ngSwitchCase="'RealtimeData'">
          <div class="sidenav-header">
            {{ 'SIDEBAR.REALTIME-DATA' | translate }}
            <mat-icon (click)="sidenav.close()">close</mat-icon>
          </div>

          <app-department-tree-view></app-department-tree-view>
        </div>

        <div *ngSwitchCase="'HistoricalData'">
          <div class="sidenav-header">
            {{ 'SIDEBAR.HISTORICAL-DATA' | translate }}
            <mat-icon (click)="sidenav.close()">close</mat-icon>
          </div>

          <app-kpis-tree-view
            [isEditKPIGroup]="isEditKPIGroup"
          ></app-kpis-tree-view>
        </div>

        <div *ngSwitchCase="'EasyToolLife'">
          <div class="sidenav-header">
            {{ 'SIDEBAR.TOOL_LIFETIME' | translate }}
            <mat-icon (click)="sidenav.close()">close</mat-icon>
          </div>
          <a
            [routerLink]="['shiftleader-dashboard/tool-lifetime/overview']"
            class="sidenav-title"
            (click)="sidenav.close()"
          >
            {{ 'SIDEBAR.OVERVIEW' | translate }}
            <mat-icon>keyboard_arrow_right</mat-icon>
          </a>
          <a
            [routerLink]="['shiftleader-dashboard/tool-lifetime/deviation']"
            class="sidenav-title"
            (click)="sidenav.close()"
          >
            {{ 'SIDEBAR.DEVIATION' | translate }}
            <mat-icon>keyboard_arrow_right</mat-icon>
          </a>
        </div>
        <div *ngSwitchDefault>
          {{ 'SIDEBAR.SIDENAV_ERROR' | translate }}
        </div>
      </div>
    </mat-sidenav>

    <div *ngIf="isOperatorCockpit()">
      <div
        class="sidebar-item cockpit-hover"
        [routerLink]="['operator-cockpit/cockpit/overview']"
        routerLinkActive="active-link"
      >
        <div class="sidebar-icon">
          <mat-icon aria-hidden="false">dashboard</mat-icon>
        </div>
        <div class="sidebar-title">
          {{ 'OPERATOR_COCKPIT.COCKPIT' | translate }}
        </div>
      </div>

      <div
        class="sidebar-item cockpit-hover"
        [routerLink]="['operator-cockpit/cockpit/order-list']"
        routerLinkActive="active-link"
      >
        <div class="sidebar-icon">
          <mat-icon aria-hidden="false">view_list</mat-icon>
        </div>
        <div class="sidebar-title">
          {{ 'OPERATOR_COCKPIT.ORDER_LIST' | translate }}
        </div>
      </div>

      <div
        class="sidebar-item cockpit-hover"
        [routerLink]="['operator-cockpit/cockpit/periods']"
        routerLinkActive="active-link"
      >
        <div class="sidebar-icon">
          <mat-icon aria-hidden="false">timeline</mat-icon>
        </div>
        <div class="sidebar-title">
          {{ 'OPERATOR_COCKPIT.PERIODS' | translate }}
        </div>
      </div>
    </div>
  </div>
</mat-sidenav-container>
