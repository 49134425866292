import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StatusBarModalComponent } from '../status-bar-modal/status-bar-modal.component';

@Component({
  selector: 'app-group-status-bar-item',
  templateUrl: './group-status-bar-item.component.html',
  styleUrls: [
    './group-status-bar-item.component.scss',
    '../../../../../../../../src/styles/shared-classes.scss',
  ],
})
export class GroupStatusBarItemComponent implements OnInit {
  @Input() text: string;
  @Input() number: number;
  @Input() cssClass: string;
  @Input() color: string;
  @Input() groupStatus: any;
  @Input() machineStatus: any;
  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  openDialog(text): void {
    if (text !== 'GROUP-STATUS-BAR.TOTAL') {
      const dialogRef = this.dialog.open(StatusBarModalComponent, {
        data: {
          machineStatus: this.machineStatus,
          groupStatus: this.groupStatus,
          statusColor: this.color,
        },
      });
    }
  }
}
