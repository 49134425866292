<div
  class="app-wrapper"
  [ngClass]="{ 'background-color': router.url.includes('operator-cockpit') }"
>
  <app-header class="header" *ngIf="router.url != '/login'"></app-header>
  <div class="content-wrapper">
    <app-sidebar class="sidebar" *ngIf="router.url != '/login'"> </app-sidebar>
    <div class="outlet-wrapper">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
