import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-order-expansion-panel',
  templateUrl: './product-order-expansion-panel.component.html',
  styleUrls: ['./product-order-expansion-panel.component.scss'],
})
export class ProductOrderExpansionPanelComponent implements OnInit {
  @Input() productOrder;
  @Input() selectedProductOrder;

  constructor() {}

  ngOnInit(): void {
  }
}
