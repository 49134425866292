import { Component, Input, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss'],
})
export class GroupListComponent implements OnInit {
  @Input() groups;
  @Input() showLabels = false;
  @Input() departmentId;

  constructor(public loaderService: LoaderService) {}

  ngOnInit(): void {}
}
