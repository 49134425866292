import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ChartsModule } from 'ng2-charts';
import { CircularProgressWidgetComponent } from 'src/app/components/circular-progress-widget/circular-progress-widget.component';
import { DeviationWidgetComponent } from 'src/app/components/deviation-widget/deviation-widget.component';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { DataPlaceholderComponent } from 'src/app/components/data-placeholder/data-placeholder.component';
import { SharedRoutingModule } from './shared-routing.module';
import { LoaderComponent } from '../../components/loader/loader.component';
import { MaterialModule } from '../../material/material.module';
import { ProducedPiecesChartComponent } from '../../components/produced-pieces-chart/produced-pieces-chart.component';

@NgModule({
  declarations: [
    LoaderComponent,
    ProducedPiecesChartComponent,
    CircularProgressWidgetComponent,
    DeviationWidgetComponent,
    AlertComponent,
    DataPlaceholderComponent,
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    MaterialModule,
    ChartsModule,
    TranslateModule.forChild({
      extend: true,
    }),
  ],
  exports: [
    LoaderComponent,
    ProducedPiecesChartComponent,
    CircularProgressWidgetComponent,
    DeviationWidgetComponent,
    AlertComponent,
    DataPlaceholderComponent,
  ],
})
export class SharedModule {}
