<div class="calendar-wrapper">
  <div class="circle-calendar-wrapper">
    <div class="title">
      {{ 'HEADER.' + heading | translate }}
    </div>
    <div class="details-link">
      <a
        routerLink="./details/{{
          heading.charAt(0) + heading.slice(1).toLowerCase()
        }}"
        class="calendar-link"
        >Details
        <mat-icon>navigate_next</mat-icon>
      </a>
    </div>
  </div>
  <div class="calendar">
    <app-single-letter-calendar [heading]="heading" [daysInMonth]="daysInMonth">
    </app-single-letter-calendar>
    <div class="letter">{{ calendarLetter }}</div>
  </div>
</div>
