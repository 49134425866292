export enum LanguageCode {
  English = 'en',
  German = 'de',
  Chinese = 'zh',
  Spanish = 'es',
}

export enum LanguageCodeIdentifier {
  English = 'en-US',
  German = 'de-DE',
  Chinese = 'zh_CN',
  Spanish = 'es-ES',
}
