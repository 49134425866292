import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-deviation-widget',
  templateUrl: './deviation-widget.component.html',
  styleUrls: ['./deviation-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeviationWidgetComponent implements OnInit {
  @Input() progressLabel: string;
  @Input() progressBarValue: number;
  @Input() target: number;
  @Input() progressValue: number;
  @Input() deviation: string;
  @Input() progressBarColorClass: string;
  @Input() deviationColorClass: string;
  @Input() backgroundColorClass: string;
  @Input() targetLinePosition: number;

  constructor() {}

  ngOnInit(): void {}
}
