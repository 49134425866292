<div>
  <app-chart-header title="OEE"></app-chart-header>

  <div class="chart-wrapper">
    <div class="chart-oee-wrapper">
      <div class="chart-oee-label">OEE</div>
      ({{ oeeAverage }}%)
    </div>
    <div class="chart">
      <canvas
        baseChart
        height="80"
        [datasets]="lineChartData"
        [options]="lineChartOptions"
        [colors]="lineChartColors"
        [chartType]="lineChartType"
      >
      </canvas>
    </div>
  </div>
</div>
