<mat-expansion-panel
  class="expansion-panel"
  #mep="matExpansionPanel"
  [ngClass]="{ 'background-color': router.url.includes('operator-cockpit') }"
>
  <mat-expansion-panel-header class="expansion-panel-wrapper">
    <mat-panel-title class="expansion-panel-title">
      {{ titleLanguage }}
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div class="panel-body">
    <div *ngFor="let lang of languages">
      <div
        class="link link-heading"
        (click)="
          mep.expanded = false; changeLang(lang.languageCode, lang.languageName)
        "
      >
        {{ lang.languageName }}
      </div>
    </div>
  </div>
</mat-expansion-panel>
