<div class="calendar-wrapper">
  <div class="calendar-text-wrapper">
    <div class="calendar-title">{{ 'HEADER.' + heading | translate }}</div>
    <a
      routerLink="./details/{{ title.charAt(0) + title.slice(1).toLowerCase() }}"
      class="calendar-link"
      >Details
      <mat-icon>navigate_next</mat-icon>
    </a>
  </div>
  <div class="weekdays-wrapper">
    <div>{{ 'CALENDAR.MON' | translate }}</div>
    <div>{{ 'CALENDAR.TUE' | translate }}</div>
    <div>{{ 'CALENDAR.WED' | translate }}</div>
    <div>{{ 'CALENDAR.THU' | translate }}</div>
    <div>{{ 'CALENDAR.FRI' | translate }}</div>
    <div>{{ 'CALENDAR.SAT' | translate }}</div>
    <div>{{ 'CALENDAR.SUN' | translate }}</div>
  </div>
  <div class="day-node-wrapper">
    <div *ngFor="let offset of startOfTheMonthOffset"></div>
    <div *ngFor="let day of daysInMonth; let dayInMonth = index">
      <app-day-node
        [dayInMonth]="dayInMonth + 1"
        [dayData]="day"
        [calendarType]="title"
      ></app-day-node>
    </div>
  </div>
</div>
