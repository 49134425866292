import { ActivatedRoute, Params } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { format, isSameMonth } from 'date-fns';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/services/shared.service';
import { WorkStationService } from 'projects/shiftleader-dashboard/src/app/services/work-station.service';
import { Group } from 'projects/shiftleader-dashboard/src/app/model/workstation-data.model';
import { KpiService } from './../../../../services/kpi.service';
import { DatePickerService } from '../../../../services/date-picker.service';
import { RouterParamsService } from '../../../../services/router-params.service';
import { ConfigService } from '../../../../services/config.service';

@Component({
  selector: 'app-report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.scss'],
})
export class ReportDetailsComponent implements OnInit, OnDestroy {
  category: string;
  value: string;
  reportType: string;
  dataMock = require('../../../../mock/report-details-safety-data.json');
  historicalData;
  selectedDate;
  chartData = [];
  departmentId: string;
  defaultSubKpis: Array<any>;
  isYearlyError = false;
  isMonthlyError = false;
  groupsSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private kpiService: KpiService,
    private datePickerService: DatePickerService,
    private routerParamsService: RouterParamsService,
    private sharedService: SharedService,
    private configService: ConfigService,
    private workStationService: WorkStationService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.workStationService.fetchGroupsLiveData();
    this.groupsSubscription = this.workStationService.groups.subscribe(
      (groups: Group[]) => {
        if (groups.length) {
          this.departmentId = this.sharedService.getDepartmentId(groups);
        }
      },
    );
    this.historicalData = this.kpiService.historicalDataSubject.getValue();
    this.kpiService.historicalData.subscribe((historicalData) => {
      this.historicalData = historicalData;
      this.calculateDetails();
    });

    this.route.params.subscribe((params: Params) => {
      this.reportType = params.reportName;

      this.calculateDetails();
    });

    this.configService.configData.subscribe((response) => {
      if (response?.kpis) {
        const { kpis } = response;
        const reportType = this.reportType.toLowerCase();

        const subKpis = kpis.find((kpi) =>
          Object.keys(kpi).find((kpiName) => kpiName === reportType),
        );

        this.defaultSubKpis = subKpis[reportType];
        this.calculateDetails();
      }
    });

    this.datePickerService.selectedDate.subscribe((selectedDate) => {
      this.selectedDate = selectedDate;
      this.calculateDetails();
    });

    this.routerParamsService.routerParams.subscribe((params: Params) => {
      if (params?.machineId) {
        this.category = 'WorkCenter';
        this.value = params.machineId;
      } else if (params?.subgroupName) {
        this.category = 'Subgroup';
        this.value = params.subgroupName;
      } else if (params?.groupId) {
        this.category = 'Group';
        this.value = params.groupId;
      } else {
        this.category = 'Department';
        this.value = this.departmentId;
      }
      this.calculateDetails();
    });
  }

  ngOnDestroy(): void {
    this.groupsSubscription.unsubscribe();
  }

  async calculateDetails(): Promise<void> {
    this.isYearlyError = false;
    this.isMonthlyError = false;
    if (this.reportType && this.selectedDate && this.historicalData) {
      try {
        this.calculateMonthlyData();
        this.isMonthlyError = false;
      } catch (error) {
        this.isMonthlyError = true;
      }
      try {
        await this.calculateYearlyData();
        this.isYearlyError = false;
      } catch (error) {
        this.isYearlyError = true;
      }
    }
  }

  calculateMonthlyData(): void {
    this.chartData = [];

    const subKpis = this.groupSubKpisByReportType(
      this.historicalData,
      this.reportType.toUpperCase(),
    );

    Object.entries(subKpis).forEach(([key, subKpi]: any[]) => {
      const subKpiInMonth: any[] = this.kpiService.mapDataObjects(
        subKpi,
        this.selectedDate,
      );

      const monthlyData: any[] = this.populateChartData(subKpi, subKpiInMonth);

      const monthlyMarginValue = subKpi.find((el) => el.target);
      const target = monthlyMarginValue?.isDivide
        ? monthlyMarginValue?.target / 1000
        : monthlyMarginValue?.target;

      this.chartData.push({
        description: key,
        monthlyMarginValue: target,
        isGraphTypeStacked:
          this.defaultSubKpis?.find((item) => item.graphTitle === key)
            ?.graphType === 'stacked',
        monthlyData,
      });
    });
  }

  async calculateYearlyData(): Promise<void> {
    if (this.selectedDate && this.category && this.reportType) {
      const yearlyHistoricalData = await this.kpiService.getYearlyHistoricalData(
        this.selectedDate,
        this.category,
        this.value,
        this.reportType.toLowerCase(),
      );

      const subKpis = this.groupSubKpisByReportType(
        yearlyHistoricalData.data,
        this.reportType.toUpperCase(),
      );

      const today = new Date(this.selectedDate);
      const currentYear = new Array(12).fill({}).map((month, index) => {
        return {
          date: format(today.setMonth(index), 'yyyy-MM'),
        };
      });

      Object.entries(subKpis).forEach(([key, subKpi]: any[]) => {
        const subKpiInYear = currentYear?.map((month) => {
          const matchedSubKpi: any = Object.values(subKpi).find(
            (yearlyDataMonth: any) => {
              return isSameMonth(
                new Date(month.date),
                new Date(yearlyDataMonth.date),
              );
            },
          );
          return {
            ...month,
            ...(matchedSubKpi && { ...matchedSubKpi }),
          };
        });

        const yearlyData: any[] = this.populateChartData(subKpi, subKpiInYear);
        const yearlyMarginValue = subKpi.find((el) => el.target);

        const target = yearlyMarginValue?.isDivide
          ? yearlyMarginValue?.target / 1000
          : yearlyMarginValue?.target;

        this.chartData = this.chartData.map((chartData) =>
          chartData.description === key
            ? {
                ...chartData,
                yearlyMarginValue: target,
                yearlyData,
              }
            : { ...chartData },
        );
      });
    }
  }

  populateChartData(subKpi, subKpiCalendarPeriod): any[] {
    const stackedKpi = subKpi.find((item) => item?.value?.length > 1);
    const stackedLabels = stackedKpi?.value.flatMap((el) => Object.keys(el));

    const chartData = stackedLabels
      ? stackedLabels.map((label) => ({
          label,
          data: [],
        }))
      : [{ data: [] }];

    subKpiCalendarPeriod.forEach((subkpi) => {
      // elements with sub KPIs mapping
      if (subkpi.value) {
        // if graphType is stacked, then sub kpi value contains multiple sub-values (K1, K3, ...)
        if (subkpi.graphType === 'stacked') {
          subkpi.value.forEach((value, index) => {
            const [subCategoryKey] = Object.keys(value);
            const data = value[subCategoryKey];
            const formattedData = subkpi.isDivide ? data / 1000 : data;
            chartData[index].data.push(formattedData);
          });
        } else {
          const subValue = subkpi.value[0];
          const [subCategoryKey] = Object.keys(subValue);
          const data = subValue[subCategoryKey];
          const formattedData = subkpi.isDivide ? data / 1000 : data;
          chartData[0].data.push(formattedData);
        }
      } else {
        // there's no mapping for KPIs, so push only dates
        chartData.forEach((_, index) => {
          chartData[index].data.push(subkpi);
        });
      }
    });

    return chartData;
  }

  groupSubKpisByReportType(historicalData: any, reportType: string): object {
    return historicalData
      ?.map((kpis) => {
        return kpis[reportType]?.length
          ? kpis[reportType]?.map((subKpi) => ({
              ...subKpi,
              date: kpis.date,
            }))
          : this.defaultSubKpis?.map((subKpi) => ({
              date: kpis.date,
              title: subKpi.graphTitle,
            }));
      })
      .reduce((acc, cur) => {
        if (cur) {
          cur.forEach((subKpi) => {
            const title = subKpi.title
              ? subKpi.title
              : this.defaultSubKpis?.find(
                  (item) => item.graphTitle === reportType,
                )?.graphTitle;
            acc[title] = [...(acc[title] || []), subKpi];
          });
        }

        return acc;
      }, {});
  }
}
