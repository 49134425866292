import { WorkStationService } from './../../../../services/work-station.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { format } from 'date-fns';
import { LoaderService } from 'src/app/services/loader.service';
import { Group } from 'projects/shiftleader-dashboard/src/app/model/workstation-data.model';
import { DatePickerService } from '../../../../services/date-picker.service';
import { KpiService } from '../../../../services/kpi.service';
import { ConfigService } from '../../../../services/config.service';

@Component({
  selector: 'app-group-calendar-view',
  templateUrl: './group-calendar-view.component.html',
  styleUrls: [
    './group-calendar-view.component.scss',
    '../../../../../styles/reports.scss',
  ],
})
export class GroupCalendarViewComponent implements OnInit, OnDestroy {
  daysInMonth: any;
  selectedDate: Date;
  groupId: string;
  groupName: string;
  routerSubscription: Subscription;
  selectedDateSubscription: Subscription;
  historicalDataSubscription: Subscription;
  selectedDayData = [];
  kpiNames: string[];
  configKpis = [];
  isCircleCalendar = true;
  groups: Group[];

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private workStationService: WorkStationService,
    private datePickerService: DatePickerService,
    private kpiService: KpiService,
    public loaderService: LoaderService,
    private configService: ConfigService,
  ) {}

  ngOnInit(): void {
    this.workStationService.groups.subscribe((groups: Group[]) => {
      if (groups.length) {
        this.groups = groups;
      }
    });

    this.routerSubscription = this.route.params.subscribe((params: Params) => {
      this.groupId = params?.groupId;

      this.groupName = this.workStationService.getGroupNameById(
        this.groupId,
        this.groups,
      );
      this.getHistoricalDataForGroup();
    });

    this.selectedDateSubscription = this.datePickerService.singleDayDate.subscribe(
      (selectedDate) => {
        this.selectedDate = selectedDate;
        this.getSingleDayData(selectedDate);
        this.getHistoricalDataForGroup();
        this.getConfigKPIs();
      },
    );

    this.subscribeHistoricalData();

    if (!this.kpiService.historicalDataSubject.getValue()) {
      this.getHistoricalDataForGroup();
    }
  }

  subscribeHistoricalData(): void {
    this.historicalDataSubscription = this.kpiService.historicalData.subscribe(
      (historicalData) => {
        if (historicalData && this.selectedDate) {
          const sortedHistoricalData = this.kpiService.sortKPIs(
            historicalData,
            this.configKpis,
          );

          this.daysInMonth = this.kpiService.mapDataObjects(
            sortedHistoricalData,
            this.selectedDate,
          );

          this.kpiNames = this.kpiService.getKPINames(this.daysInMonth);
        }
      },
    );
  }

  getConfigKPIs(): void {
    this.configService.configData.subscribe((response) => {
      this.configKpis = response?.kpis.sort((a, b) =>
        a.sortingOrder > b.sortingOrder ? 1 : -1,
      );

      this.getSingleDayData(this.selectedDate);
      this.subscribeHistoricalData();
    });
  }

  getSingleDayData(selectedDate): void {
    if (selectedDate && this.configKpis?.length && this.groupName) {
      this.kpiService
        .getSingleDay(selectedDate, 'Group', this.groupName, this.configKpis)
        .then((selectedDayData: any) => {
          if (selectedDayData) {
            this.selectedDayData = selectedDayData;
          } else {
            this.selectedDayData = this.kpiService.getConfigKPINames(
              this.configKpis,
            );
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.selectedDateSubscription.unsubscribe();
    this.historicalDataSubscription.unsubscribe();
  }

  getHistoricalDataForGroup(): void {
    if (this.selectedDate && this.groupName) {
      this.kpiService.getHistoricalData(
        format(this.selectedDate, 'yyyy-MM-dd'),
        'Group',
        this.groupName,
      );

      this.getSingleDayData(this.selectedDate);
    }
  }

  keyValueOrder(): number {
    return 0;
  }
}
