import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OverviewComponent } from './overview/overview.component';
import { DeviationComponent } from './deviation/deviation.component';
import { TranslationLoaderResolverService } from 'src/app/services/translation-loader-resolver.service';
import { AuthGuard } from 'src/app/services/auth.guard';
import { environment } from 'src/environments/environment';
import { UserGroup } from 'src/app/enums/user-group.enum';
import { ToolLifetimeResolverService } from '../../services/tool-lifetime-resolver.service';
import { GroupGuard } from 'src/app/services/group.guard';

const commonGroups =
  environment?.env === 'development' ? [UserGroup.AppSmsfDevAll] : [];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'overview',
    pathMatch: 'full',
  },
  {
    path: 'overview',
    component: OverviewComponent,
    resolve: {
      model: TranslationLoaderResolverService,
      param: ToolLifetimeResolverService,
    },
    canActivate: [AuthGuard, GroupGuard],
    data: {
      allowedGroups: [...commonGroups, UserGroup.AppSmsfProdEasyToolLife],
      breadcrumb: ({ param }) => param.overview,
    },
  },
  {
    path: 'deviation',
    component: DeviationComponent,
    resolve: {
      model: TranslationLoaderResolverService,
      param: ToolLifetimeResolverService,
    },
    canActivate: [AuthGuard, GroupGuard],
    data: {
      allowedGroups: [...commonGroups, UserGroup.AppSmsfProdEasyToolLife],
      breadcrumb: ({ param }) => param.deviation,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ToolLifetimeRoutingModule {}
