import { RouterParamsService } from './../../services/router-params.service';
import { WorkStationService } from './../../services/work-station.service';
import { Component, OnInit } from '@angular/core';
import { Group } from '../../model/workstation-data.model';

@Component({
  selector: 'app-dropdown-select',
  templateUrl: './dropdown-select.component.html',
  styleUrls: ['./dropdown-select.component.scss'],
})
export class DropdownSelectComponent implements OnInit {
  selectedDropdownValue = 'Department';
  viewList = ['Department', 'Group', 'Machine'];
  detailsList = ['Performance', 'Quality', 'Delivery', 'Costs'];
  groupList: Group[];
  subgroupName: string;
  groupId: string;
  reportName: string;
  departmentId: string;

  constructor(
    private workStationService: WorkStationService,
    private routerParamsService: RouterParamsService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.workStationService.fetchGroupsLiveData();
    this.workStationService.groups.subscribe((groups: Group[]) => {
      if (groups.length) {
        this.groupList = groups;
      }
    });
    this.departmentId = this.groupList[0].department;
    this.getRouterParams();
  }

  getRouterParams(): void {
    this.routerParamsService.routerParams.subscribe(async (res: any) => {
      this.selectedDropdownValue = await this.routerParamsService.setHeadingTitle(
        res,
      );
      const { subgroupName, reportName, groupId } = res;
      this.subgroupName = subgroupName;
      this.reportName = reportName;
      this.groupId = groupId;
    });
  }

  setSelectedHeading(machineId: string): void {
    this.selectedDropdownValue = machineId;
  }

  isMachineListVisible(subgroupName, groupId): boolean {
    return (
      (this.groupId === groupId &&
        this.detailsList.includes(this.reportName)) ||
      (this.subgroupName === subgroupName &&
        this.detailsList.includes(this.reportName)) ||
      (!this.subgroupName &&
        !this.groupId &&
        this.detailsList.includes(this.reportName))
    );
  }
}
