import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/services/shared.service';
import { LoaderService } from 'src/app/services/loader.service';
import { Group } from '../../../model/workstation-data.model';
import { WorkStationService } from '../../../services/work-station.service';
import { ProducedPiecesService } from '../../../services/produced-pieces.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PlannedPiecesService } from '../../../services/planned-pieces.service';
import { ConfigService } from '../../../services/config.service';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
})
export class GroupsComponent implements OnInit, OnDestroy {
  @Input() showLabels = false;
  groups: Group[];
  selectedGroup: Group;
  selectedGroupStatus: any;
  refreshInterval;
  totalMachines: number;
  params;
  departmentId: string;
  statusColorMap: object;
  config;
  configSubscription: Subscription;

  constructor(
    private workStationService: WorkStationService,
    private producedPiecesService: ProducedPiecesService,
    private route: ActivatedRoute,
    public router: Router,
    private plannedPiecesService: PlannedPiecesService,
    private sharedService: SharedService,
    private configService: ConfigService,
    private loaderService: LoaderService,
  ) {}

  async ngOnInit(): Promise<void> {
    let groupId;
    this.configService.getConfig();
    this.configSubscription = this.configService.configData.subscribe(
      (config) => {
        this.statusColorMap = config?.machineOverview;
        this.config = config;
      },
    );
    this.loaderService.showLoader();
    await this.workStationService.fetchGroupsLiveData();
    this.route.params.subscribe((params: Params) => {
      if (params.groupId) {
        groupId = params.groupId;
        this.workStationService.groups.subscribe({
          next: (groups: any) => {
            if (groups.length) {
              this.departmentId = this.sharedService.getDepartmentId(groups);
              this.findGroup(groups, groupId);
            }
          },
          complete: () => {
            this.loaderService.hideLoader();
          },
        });
        this.producedPiecesService.fetchProducedPeacesLiveData({
          category: 'Group',
          value: groupId.charAt(groupId.length - 1),
        });
      }
    });

    this.plannedPiecesService.fetchPlannedPiecesData();
    this.refreshInterval = setInterval(async () => {
      this.producedPiecesService.fetchProducedPeacesLiveData({
        category: 'Group',
        value: groupId.charAt(groupId.length - 1),
      });
      this.producedPiecesService.fetchProducedPiecesChunk({
        category: 'Group',
        value: this.selectedGroup?.groupName,
      });
      await this.workStationService.fetchGroupsLiveData();
      this.plannedPiecesService.fetchPlannedPiecesData();
    }, 30000);
  }

  ngOnDestroy(): void {
    clearInterval(this.refreshInterval);
    this.configSubscription.unsubscribe();
  }

  findGroup(groups: Group[], selectedGroupId): void {
    const selectedGroup = groups?.find(
      (group: Group) => group.groupId === selectedGroupId,
    );
    this.selectedGroup = selectedGroup;

    const machines = this.workStationService.getMachinesFromSubGroups(
      selectedGroup?.subgroups,
    );
    this.totalMachines = machines?.length;
    this.selectedGroupStatus = this.workStationService.groupMachinesByStatus(
      machines,
      this.config,
    );
  }
}
