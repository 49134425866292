import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToolLifetimeResolver } from '../model/tool-lifetime-data.model';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ToolLifetimeResolverService
  implements Resolve<ToolLifetimeResolver> {
  constructor(private translate: TranslateService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): ToolLifetimeResolver {
    return {
      toolLifetime: this.translate.instant('SIDEBAR.TOOL_LIFETIME'),
      overview: this.translate.instant('SIDEBAR.OVERVIEW'),
      deviation: this.translate.instant('SIDEBAR.DEVIATION'),
    };
  }
}
