import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { format } from 'date-fns';
import { LoaderService } from 'src/app/services/loader.service';
import { DatePickerService } from '../../../../services/date-picker.service';
import { KpiService } from '../../../../services/kpi.service';
import { ConfigService } from '../../../../services/config.service';

@Component({
  selector: 'app-subgroup-calendar-view',
  templateUrl: './subgroup-calendar-view.component.html',
  styleUrls: [
    './subgroup-calendar-view.component.scss',
    '../../../../../styles/reports.scss',
  ],
})
export class SubgroupCalendarViewComponent implements OnInit, OnDestroy {
  daysInMonth: any;
  selectedDate: Date;
  subgroupName: string;
  routerSubscription: Subscription;
  selectedDateSubscription: Subscription;
  historicalDataSubscription: Subscription;
  selectedDayData = [];
  kpiNames: string[];
  configKpis = [];
  isCircleCalendar = true;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private datePickerService: DatePickerService,
    private kpiService: KpiService,
    public loaderService: LoaderService,
    private configService: ConfigService,
  ) {}

  ngOnInit(): void {
    this.routerSubscription = this.route.params.subscribe((params: Params) => {
      this.subgroupName = params?.subgroupName;
      this.getHistoricalDataForSubgroup();
    });
    this.selectedDateSubscription = this.datePickerService.singleDayDate.subscribe(
      (selectedDate) => {
        this.selectedDate = selectedDate;
        this.getHistoricalDataForSubgroup();
        this.getSingleDayData(selectedDate);
        this.getConfigKPIs();
      },
    );

    this.subscribeHistoricalData();

    if (!this.kpiService.historicalDataSubject.getValue()) {
      this.getHistoricalDataForSubgroup();
    }
  }

  subscribeHistoricalData(): void {
    this.historicalDataSubscription = this.kpiService.historicalData.subscribe(
      (historicalData) => {
        if (historicalData && this.selectedDate) {
          const sortedHistoricalData = this.kpiService.sortKPIs(
            historicalData,
            this.configKpis,
          );

          this.daysInMonth = this.kpiService.mapDataObjects(
            sortedHistoricalData,
            this.selectedDate,
          );
        }

        this.kpiNames = this.kpiService.getKPINames(this.daysInMonth);
      },
    );
  }

  getConfigKPIs(): void {
    this.configService.configData.subscribe((response) => {
      this.configKpis = response?.kpis.sort((a, b) =>
        a.sortingOrder > b.sortingOrder ? 1 : -1,
      );

      this.getSingleDayData(this.selectedDate);
      this.subscribeHistoricalData();
    });
  }

  getSingleDayData(selectedDate): void {
    if (selectedDate && this.configKpis?.length && this.subgroupName) {
      this.kpiService
        .getSingleDay(
          selectedDate,
          'Subgroup',
          this.subgroupName,
          this.configKpis,
        )
        .then((selectedDayData: any) => {
          if (selectedDayData) {
            this.selectedDayData = selectedDayData;
          } else {
            this.selectedDayData = this.kpiService.getConfigKPINames(
              this.configKpis,
            );
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.selectedDateSubscription.unsubscribe();
    this.historicalDataSubscription.unsubscribe();
  }

  getHistoricalDataForSubgroup(): void {
    if (this.selectedDate && this.subgroupName) {
      this.kpiService.getHistoricalData(
        format(this.selectedDate, 'yyyy-MM-dd'),
        'Subgroup',
        this.subgroupName,
      );

      this.getSingleDayData(this.selectedDate);
    }
  }

  keyValueOrder = (): number => {
    return 0;
  }
}
