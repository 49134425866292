<mat-tree
  [dataSource]="dataSource"
  [treeControl]="treeControl"
  class="tree-view"
>
  <!-- This is the tree node template for leaf nodes -->
  <!-- There is inline padding applied to this node using styles.
    This padding value depends on the mat-icon-button width. -->
  <mat-tree-node
    *matTreeNodeDef="let node"
    matTreeNodeToggle
    (click)="setActiveNodes(node)"
    class="non-expandable-node"
    [ngClass]="{ 'active-node': isNodeActive(node.name) }"
  >
    <a
      class="non-expandable-link"
      [ngClass]="{ 'non-expandable-category': node.level === 0 }"
      routerLink="{{ node.link }}"
    >
      {{ node.name }}
    </a>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <div
      matTreeNodeToggle
      class="mat-tree-node expandable-node"
      (click)="setActiveNodes(node)"
      [ngClass]="{ 'active-node': isNodeActive(node.name) }"
    >
      <button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
        <mat-icon class="mat-icon-rtl-mirror arrow-icon">
          {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>
      <a class="expandable-node-link" routerLink="{{ node.link }}">
        {{ node.name }}
      </a>
    </div>
    <!-- There is inline padding applied to this div using styles.
          This padding value depends on the mat-icon-button width.  -->
    <div
      [class.tree-view-invisible]="!treeControl.isExpanded(node)"
      role="group"
    >
      <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-nested-tree-node>
</mat-tree>
