<div class="product-order-chart-wrapper">
  <app-circular-progress-widget
    class="circular-progress-widget"
    backgroundColorClass="background-white"
    *ngIf="!supportsMultipleOrders"
    [title]="'PRODUCT-ORDER.QUANTITY'"
    [quantity]="quantity?.current"
    [target]="quantity?.target"
    [unit]="'pcs'"
    [percentage]="quantity?.percent"
    [progressBarClass]="quantity?.progressBarClass"
  ></app-circular-progress-widget>
  <app-deviation-widget
    #deviationWidget
    class="deviation-widget"
    [progressLabel]="'PRODUCT-ORDER.PIECES-PER-MINUTE' | translate"
    [progressValue]="piecesPerMinute?.current"
    [progressBarValue]="piecesPerMinute?.progressBarValue"
    [backgroundColorClass]="piecesPerMinute?.backgroundColorClass"
    [progressBarColorClass]="piecesPerMinute?.progressBarColorClass"
    [deviationColorClass]="piecesPerMinute?.deviationColorClass"
    [target]="piecesPerMinute?.target"
    [deviation]="piecesPerMinute?.deviation"
    [targetLinePosition]="piecesPerMinute?.targetLinePosition"
  ></app-deviation-widget>
</div>
