import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'app-timeline-actions',
  templateUrl: './timeline-actions.component.html',
  styleUrls: ['./timeline-actions.component.scss'],
})
export class TimelineActionsComponent implements OnInit {
  @Input() hourNow;
  @Input() selectedDateTime;
  @Input() isLoadingPeriods;
  @Output() changeNavigationEmitter = new EventEmitter<object>();
  defaultHours = '2';
  selectedHours: string = this.defaultHours;
  navigationType: string;
  dateNow: string = new Date().toDateString();

  constructor() {}

  ngOnInit(): void {}

  onChangeHours(matButton: MatButtonToggleChange): void {
    this.selectedHours = matButton.value;
  }

  onChangeNavigation(value: string): void {
    this.navigationType = value;
    this.changeNavigationEmitter.emit({
      selectedHours: this.selectedHours,
      moveDirection: value,
    });
  }

  onReset(): void {
    this.selectedHours = this.defaultHours;
    this.navigationType = '';
    this.changeNavigationEmitter.emit({
      selectedHours: 0,
      moveDirection: 'reset',
    });
  }
}
