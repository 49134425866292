import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalStorage } from 'src/app/enums/local-storage.enum';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-select-plant-dialog',
  templateUrl: './select-plant-dialog.component.html',
  styleUrls: ['./select-plant-dialog.component.scss'],
})
export class SelectPlantDialogComponent implements OnInit {
  plants = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<SelectPlantDialogComponent>,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.plants = this.data;
  }

  selectPlant(plant: string): void {
    localStorage.setItem(LocalStorage.PlantId, plant);
    this.authService.redirectAuthenticatedUser(plant);
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
