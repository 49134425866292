import { Component, OnInit, Input } from '@angular/core';
import { ConfigService } from '../../../../services/config.service';

@Component({
  selector: 'app-letter-calendar',
  templateUrl: './letter-calendar.component.html',
  styleUrls: ['./letter-calendar.component.scss'],
})
export class LetterCalendarComponent implements OnInit {
  @Input() heading: any;
  @Input() singleDay: any;
  @Input() daysInMonth: any;

  calendarType: string;
  calendarLetter: string;

  constructor(private configService: ConfigService) {}

  ngOnInit(): void {
    this.configService.configDataSubject.subscribe((config) => {
      this.calendarLetter = config.kpis.find(
        (kpi) => kpi[this.heading.toLowerCase()],
      )?.calendarLetter;
    });
  }
}
