<div class="group-machine-list-wrapper">
  <div *ngFor="let machine of subGroupItem?.machines">
    <div *ngIf="machine?.workCenterName">
      <app-group-machine-item
        [machine]="machine"
        [subGroupItem]="subGroupItem"
        [groupId]="groupId"
        [departmentId]="departmentId"
      ></app-group-machine-item>
    </div>
  </div>
</div>
