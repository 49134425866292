import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfigService } from '../../../../../../../../services/config.service';

@Component({
  selector: 'app-group-machine-item',
  templateUrl: './group-machine-item.component.html',
  styleUrls: [
    './group-machine-item.component.scss',
    '../../../../../../../../../../../../src/styles/shared-classes.scss',
  ],
})
export class GroupMachineItemComponent implements OnInit, OnDestroy {
  @Input() machine;
  @Input() subGroupItem;
  @Input() groupId;
  @Input() departmentId;
  statusCategory;
  config;
  configSubscription: Subscription;

  constructor(private configService: ConfigService) {}

  ngOnInit(): void {
    this.configSubscription = this.configService.configData.subscribe(
      (config) => {
        this.config = config;
      },
    );

    const { statusCategory } = this.configService.setMachineStatusCategory(
      this.machine.statusCode,
      this.config,
    );
    this.statusCategory = statusCategory;
  }

  ngOnDestroy(): void {
    this.configSubscription.unsubscribe();
  }
}
