import { Component, Input, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-group-overview',
  templateUrl: './group-overview.component.html',
  styleUrls: ['./group-overview.component.scss'],
})
export class GroupOverviewComponent implements OnInit {
  @Input() groups;
  @Input() groupStatus;
  @Input() totalMachines;
  @Input() showLabels = true;
  @Input() departmentId;
  @Input() statusColorMap;

  constructor(public loaderService: LoaderService) {}

  ngOnInit(): void {}
}
