import { Inject, Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { from, Observable } from 'rxjs';
import { SharedService } from 'src/app/services/shared.service';

@Injectable({
  providedIn: 'root',
})
export class ToolLifetimeService {
  toolReportPath = '/dashboard/tool-report';
  plantId = this.sharedService.getPlantId();

  constructor(
    @Inject('ETL_API_NAME') private apiName: string,
    private sharedService: SharedService,
  ) {}

  getToolReport(): Observable<any> {
    const init = {
      headers: {},
      response: true,
      queryStringParameters: {
        plant: this.plantId,
      },
    };
    return from(API.get(this.apiName, this.toolReportPath, init));
  }
}
