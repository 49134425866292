import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { format } from 'date-fns';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';
import { DatePickerService } from '../../../../services/date-picker.service';
import { KpiService } from '../../../../services/kpi.service';

@Component({
  selector: 'app-machine-calendar-view',
  templateUrl: './machine-calendar-view.component.html',
  styleUrls: [
    './machine-calendar-view.component.scss',
    '../../../../../styles/reports.scss',
  ],
})
export class MachineCalendarViewComponent implements OnInit, OnDestroy {
  daysInMonth: any;
  selectedDate: Date;
  machineId: string;
  routerSubscription: Subscription;
  selectedDateSubscription: Subscription;
  historicalDataSubscription: Subscription;
  selectedDayData = [];

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private datePickerService: DatePickerService,
    private kpiService: KpiService,
    public loaderService: LoaderService,
  ) {}

  ngOnInit(): void {
    this.routerSubscription = this.route.params.subscribe((params: Params) => {
      this.machineId = params?.machineId;
      this.getHistoricalDataForMachine();
    });
    this.selectedDateSubscription = this.datePickerService.singleDayDate.subscribe(
      (selectedDate) => {
        this.selectedDate = selectedDate;
        this.getHistoricalDataForMachine();
        this.getSingleDayData(selectedDate);
      },
    );
    this.historicalDataSubscription = this.kpiService.historicalData.subscribe(
      (historicalData) => {
        if (historicalData && this.selectedDate) {
          this.daysInMonth = this.kpiService.mapDataObjects(
            historicalData,
            this.selectedDate,
          );
        }
      },
    );
    if (!this.kpiService.historicalDataSubject.getValue()) {
      this.getHistoricalDataForMachine();
    }
  }

  getSingleDayData(selectedDate): void {
    if (selectedDate) {
      this.kpiService
        .getSingleDay(selectedDate, 'WorkCenter', this.machineId)
        .then((selectedDayData: any) => {
          this.selectedDayData = selectedDayData;
        });
    }
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.selectedDateSubscription.unsubscribe();
    this.historicalDataSubscription.unsubscribe();
  }

  getHistoricalDataForMachine(): void {
    if (this.selectedDate && this.machineId) {
      this.kpiService.getHistoricalData(
        format(this.selectedDate, 'yyyy-MM-dd'),
        'WorkCenter',
        this.machineId,
      );
    }
  }
}
