import { Inject, Injectable } from '@angular/core';
import { Auth, API } from 'aws-amplify';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { UserGroup } from '../enums/user-group.enum';
import { ErrorCode } from '../enums/error-code.enum';
import { LocalStorage } from '../enums/local-storage.enum';
import { UserData, ResponseUserData } from '../model/user-data.model';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { SelectPlantDialogComponent } from '../components/login-form/select-plant-dialog/select-plant-dialog.component';
import { LoaderService } from './loader.service';
import { APIService } from './API.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: any;
  loginError: object;
  redirectUrl: string;

  constructor(
    @Inject('DATA_LAKE_API_NAME') private apiName: string,
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private dialog: MatDialog,
    private loaderService: LoaderService,
    private graphqlApi: APIService,
  ) {}

  signIn(email: string, password: string): void {
    this.loaderService.showLoader();

    Auth.signIn(environment.ldapUsername).then(
      (challenge) => {
        Auth.sendCustomChallengeAnswer(
          challenge,
          JSON.stringify([email, password]),
        )
          .then(async (user) => {
            const { data: userData }: ResponseUserData = await this.getUserData(
              email,
              user?.signInUserSession?.idToken?.jwtToken,
            );
            localStorage.setItem(LocalStorage.LastAuthUser, email);

            this.setUserData(userData);

            if (this.user?.groups.includes(UserGroup.AppSmsfAllPlants)) {
              const devAllGroup =
                environment.env === 'development'
                  ? [UserGroup.AppSmsfDevAll]
                  : [];
              const appGroups = {
                smsf_operator_cockpit: [
                  UserGroup.AppSmsfMachineOperator,
                  ...devAllGroup,
                ],
                smsf_dashboard: [
                  UserGroup.AppSmsfViewKPI,
                  UserGroup.AppSmsfEditKPI,
                  UserGroup.AppSmsfLive,
                  UserGroup.AppSmsfProdEasyToolLife,
                  ...devAllGroup,
                ],
              };
              const allowedApps = Object.entries(appGroups)
                .filter(([_, entryValue]) =>
                  entryValue.some((value) => userData.groups.includes(value)),
                )
                .flatMap(([key]) => key);

              if (!allowedApps.length) {
                this.signOut();
                this.showSnackBarMessage(
                  this.translate.instant('AUTHORIZATION-ERROR.TEXT'),
                  'error',
                );
                return;
              }

              const {
                items: listConfigs,
              } = await this.graphqlApi.ListConfigs();

              this.dialog.open(SelectPlantDialogComponent, {
                data: listConfigs
                  .map((config) => ({
                    ...JSON.parse(atob(config.value)),
                    plant: config.key,
                  }))
                  .filter((config) =>
                    config.apps_enabled.some((app) =>
                      allowedApps.includes(app),
                    ),
                  )
                  .map((config) => config.plant),
              });
            } else {
              localStorage.setItem(LocalStorage.PlantId, this.user.plantId);
              const path = this.getRouteByGroup(userData.groups);
              this.router.navigate([path]);
            }
          })
          .catch((err) => {
            if (err?.code === ErrorCode.NotAuthorizedException) {
              return this.showSnackBarMessage(
                this.translate.instant('LOGIN.INCORRECT_USERNAME_OR_PASSWORD'),
                'error',
              );
            }
            this.showSnackBarMessage(err.message, 'error');
          })
          .finally(() => {
            this.loaderService.hideLoader();
          });
      },
      (err) => {
        this.loaderService.hideLoader();
        this.showSnackBarMessage(err.message, 'error');
      },
    );
  }

  private getRouteByGroup(userGroups: Array<string>): string {
    const isMachineOperator = userGroups.includes(
      UserGroup.AppSmsfMachineOperator,
    );
    const isDevGroup =
      environment.env === 'development' &&
      userGroups.includes(UserGroup.AppSmsfDevAll);

    // IMPORTANT: order of properties matters - first matching group will have precedence as a landing page
    const groupToRouteMap = {
      ...(isMachineOperator && {
        [UserGroup.AppSmsfMachineOperator]: 'operator-cockpit',
      }),
      ...(isDevGroup && {
        [UserGroup.AppSmsfDevAll]: 'shiftleader-dashboard',
      }),
      [UserGroup.AppSmsfLive]: 'shiftleader-dashboard',
      [UserGroup.AppSmsfViewKPI]:
        'shiftleader-dashboard/historical-data/reports',
      [UserGroup.AppSmsfEditKPI]:
        'shiftleader-dashboard/historical-data/reports',
      [UserGroup.AppSmsfProdEasyToolLife]:
        'shiftleader-dashboard/tool-lifetime/overview',
    };

    const [_, route]: [string, string] = Object.entries(
      groupToRouteMap,
    ).find(([groupName]: [string, string]) =>
      userGroups?.includes(groupName),
    ) || [null, null];

    if (!route) {
      throw new Error(this.translate.instant('AUTHORIZATION-ERROR.TEXT'));
    }

    return route;
  }

  private setUserData(user: UserData): void {
    const { groups, plant, name } = user;
    const plantId = localStorage.getItem(LocalStorage.PlantId) || plant;
    const isAllPlantsGroup = groups?.includes(UserGroup.AppSmsfAllPlants);

    if (!groups?.length || (!plant && !isAllPlantsGroup)) {
      this.signOut();
      this.showSnackBarMessage(
        this.translate.instant('AUTHORIZATION-ERROR.TEXT'),
        'error',
      );
      return;
    }

    // TODO: extract workcenter from logged in user
    const plantWorkCenters = {
      1200: {
        id: '1147-95',
        name: 'PWA 15/6 V',
      },
      3900: {
        id: '1120-01',
        name: 'NB515L',
      },
    };

    this.user = {
      ...this.user,
      plantId,
      groups,
      workCenter: plantWorkCenters[plantId],
      name,
    };
  }

  private getUserData(
    email: string,
    jwtToken: string,
  ): Promise<ResponseUserData> {
    const init = {
      headers: {
        Authorization: jwtToken,
      },
      response: true,
      queryStringParameters: {
        email,
        filter: 'SMSF',
      },
    };

    return API.get(this.apiName, `/authorise`, init);
  }

  redirectAuthenticatedUser(plant?: string): void {
    Auth.currentAuthenticatedUser().then(
      async (res) => {
        let { data: userData }: ResponseUserData = await this.getUserData(
          localStorage.getItem(LocalStorage.LastAuthUser),
          res?.signInUserSession?.idToken?.jwtToken,
        );

        if (plant) {
          userData = {
            ...userData,
            plant,
          };
        }
        this.setUserData(userData);

        if (this.isPlantIdNotSet(userData)) {
          this.signOut();
        } else {
          if (this.redirectUrl) {
            this.router.navigate([this.redirectUrl]);
          } else {
            const path = this.getRouteByGroup(userData.groups);
            this.router.navigate([path]);
          }
        }
      },
      () => {},
    );
  }

  signOut(): void {
    Auth.signOut().then(() => {
      this.user = null;
      this.redirectUrl = null;
      localStorage.removeItem(LocalStorage.LastAuthUser);
      localStorage.removeItem(LocalStorage.PlantId);
      this.router.navigate(['login']);
    });
  }

  public showSnackBarMessage(message: string, type: string): void {
    this.snackBar.open(message, 'x', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: 3000,
      panelClass: ['mat-toolbar', `snack-bar-${type}`],
    });
  }

  async userAuthInit(): Promise<void> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const { data: userData }: ResponseUserData = await this.getUserData(
        localStorage.getItem(LocalStorage.LastAuthUser),
        user?.signInUserSession?.idToken?.jwtToken,
      );

      if (this.isPlantIdNotSet(userData)) {
        this.signOut();
      } else {
        this.setUserData(userData);
      }
    } catch (err) {
      this.showSnackBarMessage(err.message, 'error');
    }
  }

  private isPlantIdNotSet(userData: UserData): boolean {
    return !userData?.plant && !localStorage.getItem(LocalStorage.PlantId);
  }

  isPlantIdSet(): boolean {
    return this.user?.plantId || localStorage.getItem(LocalStorage.PlantId);
  }
}
