import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CircularProgressWidgetService {
  constructor() {}

  calculateProgressBarPercentage(quantity: number, target: number): number {
    return Math.round((quantity / target) * 100);
  }

  getProgressBarClass(
    progressBarPercentage: number,
    lowerBoundary: number,
    upperBoundary: number,
  ): string {
    switch (true) {
      case progressBarPercentage < lowerBoundary:
        return 'progress-bar-black';
      case progressBarPercentage >= lowerBoundary &&
        progressBarPercentage <= upperBoundary:
        return 'progress-bar-green';
      case progressBarPercentage > upperBoundary:
        return 'progress-bar-red';
      default:
        return 'progress-bar-black';
    }
  }
}
