import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/services/shared.service';
import { Group } from '../../../model/workstation-data.model';
import { WorkStationService } from '../../../services/work-station.service';
import { ProducedPiecesService } from '../../../services/produced-pieces.service';
import { PlannedPiecesService } from '../../../services/planned-pieces.service';
import { ConfigService } from '../../../services/config.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.scss'],
})
export class DepartmentComponent implements OnInit, OnDestroy {
  groups: Group[];
  groupStatus: any;
  refreshInterval;
  totalMachines: number;
  departmentId: string;
  statusColorMap: object;
  config;
  configSubscription: Subscription = new Subscription();

  constructor(
    private workStationService: WorkStationService,
    private producedPiecesService: ProducedPiecesService,
    private plannedPiecesService: PlannedPiecesService,
    private configService: ConfigService,
    private sharedService: SharedService,
    private loaderService: LoaderService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.configService.getConfig();
    this.configSubscription = this.configService.configData.subscribe(
      (config) => {
        /* istanbul ignore next */
        this.statusColorMap = config?.machineOverview;
        this.config = config;
      },
    );
    this.loaderService.showLoader();
    await this.workStationService.fetchGroupsLiveData();
    this.workStationService.groups.subscribe({
      next: (groups: Group[]) => {
        if (groups.length) {
          this.departmentId = this.sharedService.getDepartmentId(groups);
          this.groups = groups;
          const machines = this.workStationService.getMachinesFromGroups(
            groups,
          );
          this.groupStatus = this.workStationService.groupMachinesByStatus(
            machines,
            this.config,
          );
          this.totalMachines = machines.length;
        }
      },
      complete: () => {
        this.loaderService.hideLoader();
      },
    });

    this.producedPiecesService.fetchProducedPeacesLiveData({
      category: 'Department',
      value: this.departmentId,
    });
    this.plannedPiecesService.fetchPlannedPiecesData();
    this.refreshInterval = setInterval(async () => {
      this.plannedPiecesService.fetchPlannedPiecesData();
      await this.workStationService.fetchGroupsLiveData();
      this.producedPiecesService.fetchProducedPeacesLiveData({
        category: 'Department',
        value: this.departmentId,
      });
      this.producedPiecesService.fetchProducedPiecesChunk({
        category: 'Department',
        value: this.departmentId,
      });
    }, 30000);
  }

  ngOnDestroy(): void {
    clearInterval(this.refreshInterval);
    this.configSubscription.unsubscribe();
  }
}
