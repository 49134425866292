import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-single-day-preview',
  templateUrl: './single-day-preview.component.html',
  styleUrls: ['./single-day-preview.component.scss'],
})
export class SingleDayPreviewComponent implements OnInit {
  @Input() data: any;
  @Input() title: any;
  descriptions: string[];

  constructor() {}

  ngOnInit(): void {}
}
