import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SharedService } from 'src/app/services/shared.service';
import { WorkStationService } from './work-station.service';
import { Group } from '../model/workstation-data.model';

@Injectable({
  providedIn: 'root',
})
export class RouterParamsService {
  routerParamsSubject = new BehaviorSubject<object>({});
  routerParams: Observable<object> = this.routerParamsSubject.asObservable();
  groups: Group[];

  constructor(
    private router: Router,
    private translate: TranslateService,
    private sharedService: SharedService,
    private workStationService: WorkStationService,
  ) {}

  async initRouterParamsService(): Promise<void> {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.routerParamsSubject.next(this.getAllParams());
      }
    });
    await this.workStationService.fetchGroupsLiveData();
    this.workStationService.groups.subscribe((groups: Group[]) => {
      if (groups.length) {
        this.groups = groups;
      }
    });
  }

  getAllParams(): object {
    const params = {};
    let route = this.router.routerState.snapshot.root;

    do {
      for (const key in route.params) {
        if (route.params) {
          params[key] = route.params[key];
        }
      }
      route = route.firstChild;
    } while (route);

    return params;
  }

  async setHeadingTitle(routerParams: Params): Promise<string> {
    const machineNameDecoded = decodeURIComponent(routerParams?.machineName);
    const machineTranslation = this.translate.instant('HEADER.MACHINE');
    const subgroupTranslation = this.translate.instant('HEADER.SUBGROUP');
    const groupTranslation = this.translate.instant('HEADER.GROUP');
    const departmentTranslation = this.translate.instant('HEADER.DEPARTMENT');
    const departmentId = this.sharedService.getDepartmentId(this.groups);

    if (
      this.router.url.endsWith('/realtime-data/department') ||
      this.router.url.endsWith(`/realtime-data/department/${departmentId}`) ||
      this.router.url.endsWith('/reports/department')
    ) {
      return `${departmentTranslation} ${departmentId}`;
    } else if (routerParams.machineId) {
      if (routerParams.reportName && routerParams.machineName) {
        return `${machineTranslation} ${machineNameDecoded} (${
          routerParams.machineId
        }) ${this.setReportDetailHeading(routerParams.reportName)}`;
      } else if (routerParams.machineId && routerParams.machineName) {
        return `${machineTranslation} ${machineNameDecoded} (${routerParams.machineId})`;
      }
      return `${machineTranslation} ` + routerParams.machineId;
    } else if (routerParams.subgroupName) {
      if (routerParams.reportName) {
        return `${subgroupTranslation} ${
          routerParams.subgroupName
        } ${this.setReportDetailHeading(routerParams.reportName)}`;
      }
      return `${subgroupTranslation} ` + routerParams.subgroupName;
    } else if (routerParams.groupId) {
      if (routerParams.reportName) {
        return `${groupTranslation} ${
          routerParams.groupId
        } ${this.setReportDetailHeading(routerParams.reportName)}`;
      }
      return `${groupTranslation} ` + routerParams.groupId;
    } else if (routerParams.reportName) {
      return `${departmentTranslation} ${departmentId} ${this.setReportDetailHeading(
        routerParams.reportName,
      )}`;
    } else {
      return null;
    }
  }

  setReportDetailHeading(reportName: string): string {
    switch (reportName) {
      case 'Safety':
        return this.translate.instant('HEADER.SAFETY');
      case 'Quality':
        return this.translate.instant('HEADER.QUALITY');
      case 'Headcount':
        return this.translate.instant('HEADER.HEADCOUNT');
      case 'Performance':
        return this.translate.instant('HEADER.PERFORMANCE');
      case 'Delivery':
        return this.translate.instant('HEADER.DELIVERY');
      case 'Costs':
        return this.translate.instant('HEADER.COSTS');
      case 'Cleanliness':
        return this.translate.instant('HEADER.CLEANLINESS');
    }
  }
}
