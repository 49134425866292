import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import zh from '@angular/common/locales/zh';
import es from '@angular/common/locales/es';
import { LOCALE_ID } from '@angular/core';
import { createErrorHandler } from '@sentry/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UrlSerializer } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Amplify } from 'aws-amplify';
import { MaterialModule } from 'src/app/material/material.module';
import { LoginFormComponent } from 'src/app/components/login-form/login-form.component';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { SidebarComponent } from 'src/app/components/sidebar/sidebar.component';
import { LanguageSelectorComponent } from 'src/app/components/language-selector/language-selector.component';
import { SelectPlantDialogComponent } from 'src/app/components/login-form/select-plant-dialog/select-plant-dialog.component';
import { LanguageCode } from 'src/app/enums/language-code.enum';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './modules/shared/shared.module';
import { SnackBarComponent } from '../../projects/shiftleader-dashboard/src/app/components/snack-bar/snack-bar.component';
import { StatusBarModalComponent } from '../../projects/shiftleader-dashboard/src/app/components/group-overview/group-status-bar/status-bar-modal/status-bar-modal.component';
import { DepartmentTreeViewComponent } from '../../projects/shiftleader-dashboard/src/app/components/department-tree-view/department-tree-view.component';
import { KPIsTreeViewComponent } from '../../projects/shiftleader-dashboard/src/app/components/kpis-tree-view/kpis-tree-view.component';
import { TreeViewComponent } from '../../projects/shiftleader-dashboard/src/app/components/tree-view/tree-view.component';
import { BreadcrumbComponent } from '../../projects/shiftleader-dashboard/src/app/components/breadcrumb/breadcrumb.component';
import { RealtimeDataModule } from '../../projects/shiftleader-dashboard/src/app/modules/realtime-data/realtime-data.module';
import { HistoricalDataModule } from '../../projects/shiftleader-dashboard/src/app/modules/historical-data/historical-data.module';
import { HistoricalDataRoutingModule } from '../../projects/shiftleader-dashboard/src/app/modules/historical-data/historical-data-routing.module';
import { ToolLifetimeModule } from '../../projects/shiftleader-dashboard/src/app/modules/tool-lifetime/tool-lifetime.module';
import { CustomUrlSerializer } from '../../projects/shiftleader-dashboard/src/app/services/custom-url-serializer';
import awsconfig from '../aws-exports';
import { SharedService } from './services/shared.service';

Amplify.configure(awsconfig);
registerLocaleData(localeDe, LanguageCode.German);
registerLocaleData(zh);
registerLocaleData(es);

@NgModule({
  declarations: [
    AppComponent,
    LoginFormComponent,
    HeaderComponent,
    SidebarComponent,
    SnackBarComponent,
    LanguageSelectorComponent,
    StatusBarModalComponent,
    SelectPlantDialogComponent,
    DepartmentTreeViewComponent,
    KPIsTreeViewComponent,
    TreeViewComponent,
    BreadcrumbComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    RealtimeDataModule,
    HistoricalDataModule,
    HistoricalDataRoutingModule,
    HttpClientModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToolLifetimeModule,
  ],
  providers: [
    { provide: 'SMSF_API_NAME', useValue: awsconfig.API.endpoints[0].name },
    { provide: 'ETL_API_NAME', useValue: awsconfig.API.endpoints[1].name },
    {
      provide: 'DATA_LAKE_API_NAME',
      useValue: awsconfig.API.endpoints[2].name,
    },
    {
      provide: LOCALE_ID,
      useValue: SharedService.getLanguageCodeIdentifier().code,
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: UrlSerializer,
      useClass: CustomUrlSerializer,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
