<div class="single-day-wrapper">
  <div class="single-day-title">
    {{ 'HEADER.' + title | translate }}
  </div>
  <div class="kpi-wrapper">
    <div *ngIf="data.length; else elseBlock">
      <div class="single-kpi-wrapper">
        <div *ngFor="let kpis of data">
          <div class="single-day-description" *ngIf="kpis.title">
            {{ 'SINGLE_DAY_PREVIEW.' + kpis.title | translate }}
          </div>
          <div class="single-day-description" *ngIf="!kpis.title">
            {{ kpis.title }}
          </div>
          <div class="single-day-status-target-wrapper">
            <app-sub-kpi
              [subKpi]="kpis.value"
              [isTargetFulfilled]="kpis.isTargetFulfilled"
              [target]="kpis.target"
            ></app-sub-kpi>
          </div>
        </div>
      </div>
    </div>
    <ng-template #elseBlock>
      <div>
        <div class="single-day-description"></div>
        <div class="single-day-status-target-wrapper">
          <app-sub-kpi [isTargetFulfilled]="false"></app-sub-kpi>
        </div>
      </div>
    </ng-template>
  </div>
</div>
