import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { API } from 'aws-amplify';
import { format } from 'date-fns';
import { SharedService } from 'src/app/services/shared.service';

@Injectable({
  providedIn: 'root',
})
export class ProducedPiecesService {
  producedPiecesSubject = new BehaviorSubject<any>(null);
  producedPieces: Observable<any> = this.producedPiecesSubject.asObservable();
  producedPiecesList;

  constructor(
    @Inject('SMSF_API_NAME') private apiName: string,
    private sharedService: SharedService,
  ) {}

  getProducedPieces(queryParams?: object): Promise<object> {
    const init = {
      headers: {},
      response: true,
      queryStringParameters: {
        plantId: this.sharedService.getPlantId(),
        ...queryParams,
      },
    };
    return API.get(this.apiName, `/department/produced-pieces`, init);
  }

  fetchProducedPeacesLiveData(queryParams?: object): void {
    this.getProducedPieces(queryParams)
      .then((result: any) => {
        this.producedPiecesList = result?.data;
        this.producedPiecesSubject.next(this.producedPiecesList);
      })
      .catch();
  }

  fetchProducedPiecesChunk(queryParams?: object): void {
    if (this.producedPiecesList) {
      const mostRecentTimestamp = this.getMostRecentProducedPcsTimestamp();

      if (!mostRecentTimestamp) {
        return;
      }

      this.getProducedPieces({
        ...queryParams,
        date: format(mostRecentTimestamp, 'yyyy-MM-dd'),
        startTime: format(mostRecentTimestamp, 'HH:mm:ss'),
      })
        .then(({ data }: any) => {
          if (
            Object.keys(this.producedPiecesList.producedPiecesDay)[0] in
            data.producedPiecesDay
          ) {
            this.producedPiecesList = {
              ...this.producedPiecesList,
              producedPiecesDay: this.appendProducedPieces(
                this.producedPiecesList?.producedPiecesDay,
                data?.producedPiecesDay,
              ),
              producedPiecesShift: this.appendProducedPieces(
                this.producedPiecesList?.producedPiecesShift,
                data?.producedPiecesShift,
              ),
              currentShift: data.currentShift,
              shiftStart: data.shiftStart,
              shiftEnd: data.shiftEnd,
            };

            this.producedPiecesSubject.next(this.producedPiecesList);
          }
        })
        .catch();
    }
  }

  private getMostRecentProducedPcsTimestamp(): Date | null {
    const mostRecentTimestamps: any = Object.values(
      this.producedPiecesList?.producedPiecesShift,
    )
      .filter(({ periodsCount }: any) => periodsCount.length)
      .map(
        ({ periodsCount }: any) =>
          new Date(periodsCount[periodsCount.length - 1].time),
      );

    if (!mostRecentTimestamps.length) {
      return null;
    }

    return new Date(Math.max(...mostRecentTimestamps));
  }

  private appendProducedPieces(oldProducedPcs, newProducedPcs): any {
    return {
      ...Object.entries(oldProducedPcs).reduce(
        (acc, [entryKey, entryValue]: any) => {
          acc[entryKey] = {
            totalCount: newProducedPcs[entryKey]?.totalCount,
            periodsCount: [
              ...entryValue?.periodsCount,
              ...newProducedPcs[entryKey]?.periodsCount,
            ],
          };

          return acc;
        },
        {},
      ),
    };
  }
}
