import { Component, Input } from '@angular/core';
import { format } from 'date-fns';

@Component({
  selector: 'app-chart-header',
  templateUrl: './chart-header.component.html',
  styleUrls: ['./chart-header.component.scss'],
})
export class ChartHeaderComponent {
  @Input() title: string;
  @Input() hideTime = false;
  now: string = new Date().toDateString();
  today: string = format(new Date(), 'HH:mm');

  constructor() {}
}
