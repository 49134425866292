import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ConfigService } from '../../../services/config.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: [
    './progress-bar.component.scss',
    '../../../../../../../src/styles/shared-classes.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent implements OnInit, OnDestroy {
  @Input() periods: any;
  @Input() dayPeriod: any;
  config;
  configSubscription: Subscription;

  constructor(private configService: ConfigService) {}

  ngOnInit(): void {
    this.configSubscription = this.configService.configData.subscribe(
      (config) => {
        this.config = config;
      },
    );
  }

  ngOnDestroy(): void {
    this.configSubscription.unsubscribe();
  }

  setMachineColor(statusCode): string {
    const { color } = this.configService.setMachineStatusCategory(
      statusCode,
      this.config,
    );
    return color;
  }
}
