import { Component, Input, OnInit, Output } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { TreeNode } from '../../model/tree-node.model';
import { ConfigService } from '../../services/config.service';
import { BreadcrumbService } from '../../services/breadcrumb.service';

@Component({
  selector: 'app-kpis-tree-view',
  templateUrl: './kpis-tree-view.component.html',
  styleUrls: ['./kpis-tree-view.component.scss'],
})
export class KPIsTreeViewComponent implements OnInit {
  @Output() dataSource = new MatTreeNestedDataSource<any>();
  /* istanbul ignore next */
  @Output() treeControl = new NestedTreeControl<TreeNode>(
    (node) => node.children,
  );
  @Output() activeNodes = [];
  @Input() isEditKPIGroup;
  configKpis = [];

  constructor(
    private authService: AuthService,
    private configService: ConfigService,
    private translateService: TranslateService,
    private breadcrumbService: BreadcrumbService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (!this.authService.user) {
      await this.authService.userAuthInit();
    }

    this.configService.configData.subscribe((response) => {
      this.configKpis = response?.kpis.sort((a, b) =>
        a.sortingOrder > b.sortingOrder ? 1 : -1,
      );
    });

    this.dataSource.data = this.treeDataMapper();
    this.treeControl.dataNodes = this.dataSource.data;

    this.breadcrumbService.breadcrumbs.subscribe((breadcrumbs) => {
      this.syncTreeViewWithBreadcrumbs(breadcrumbs);
      this.expandActiveTreeViewNodes(this.activeNodes);
    });
  }

  private treeDataMapper(): Array<TreeNode> {
    const rootPath = 'shiftleader-dashboard/historical-data/reports/department';
    const sidebarKPISections = {
      [this.translateService.instant('SIDEBAR.KPI-OVERVIEW')]: {
        children: this.configKpis.map((configKpi) => configKpi.kpiName),
        link: rootPath,
      },
      ...(this.isEditKPIGroup && {
        [this.translateService.instant('SIDEBAR.MANUAL-REPORT')]: {
          link: 'shiftleader-dashboard/historical-data/submit-a-report',
        },
      }),
    };

    return Object.entries(sidebarKPISections).map(([entryKey, entryValue]) => {
      return {
        name: entryKey,
        level: 0,
        link: entryValue.link,
        ...(entryValue.children && {
          children: entryValue.children.map((kpi) => {
            return {
              name: this.translateService.instant(`HEADER.${kpi}`),
              level0Name: entryKey,
              level: 1,
              link: `${rootPath}/details/${kpi.charAt(0)}${kpi
                .slice(1)
                .toLowerCase()}`,
            };
          }),
        }),
      };
    });
  }

  private syncTreeViewWithBreadcrumbs(breadcrumbs): void {
    const sidebarKPISections = [
      this.translateService.instant('SIDEBAR.KPI-OVERVIEW'),
      ...this.configKpis.map((kpi) =>
        this.translateService.instant(`HEADER.${kpi.kpiName}`),
      ),
      this.translateService.instant('SIDEBAR.MANUAL-REPORT'),
    ];

    this.activeNodes = breadcrumbs
      .map((breadcrumb) => breadcrumb.label)
      .filter((label) => sidebarKPISections.includes(label));
  }

  private expandActiveTreeViewNodes(activeNodes: string[]): void {
    this.treeControl.collapseAll();
    const activeNodesSize = activeNodes.length;

    if (activeNodesSize > 1) {
      const kpisNodeIndex = this.treeControl.dataNodes.findIndex(({ name }) =>
        activeNodes.includes(name),
      );
      this.treeControl.expand(this.treeControl.dataNodes[kpisNodeIndex]);
    }
  }
}
