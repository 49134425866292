<a
  class="machine-item-wrapper"
  [routerLink]="[
    '/shiftleader-dashboard/realtime-data/department',
    departmentId,
    'group',
    groupId,
    'subgroup',
    subGroupItem.subgroupName,
    'machine',
    machine?.id,
    'name',
    machine?.workCenterName
  ]"
>
  <div class="machine-item-content">
    <div
      appStatusColor
      [statusData]="statusCategory"
      class="status-indicator"
    ></div>
    <div class="machine-item-details">
      <div>
        <div class="name-wrapper">
          {{ machine?.workCenterName }}
        </div>
        <div class="name-wrapper">({{ machine?.id }})</div>
      </div>
      <hr class="divider" />
      <div
        *ngIf="machine && statusCategory && statusCategory === 'PRODUCTION'"
        class="machine-item-details-text"
      >
        <div class="machine-item-details-value">
          <div>{{ 'SUBGROUP-ITEM.ACTUAL' | translate }}</div>
          <div>
            <div class="actual-value">{{ machine?.piecesPerMinute }}</div>
            <div>{{ 'SUBGROUP-ITEM.PIECES-PER-MINUTE' | translate }}</div>
          </div>
        </div>
        <div class="machine-item-details-value">
          <div>{{ 'SUBGROUP-ITEM.TARGET' | translate }}</div>
          <div>
            <div class="target-value">{{ machine?.piecesPerMinuteTarget }}</div>
            <div>{{ 'SUBGROUP-ITEM.PIECES-PER-MINUTE' | translate }}</div>
          </div>
        </div>
      </div>
      <div
        *ngIf="machine && statusCategory && statusCategory !== 'PRODUCTION'"
        class="machine-item-status"
      >
        {{ 'MACHINE_STATUS_CODE.' + (machine?.statusCode || 0) | translate }}
      </div>
    </div>
  </div>
</a>
