<div>
  <div class="close-button-wrapper">
    <mat-icon aria-hidden="false" class="close-button" (click)="closeDialog()">
      cancel
    </mat-icon>
  </div>
  <div class="dialog-wrapper">
    <div class="dialog-header">
      <div mat-dialog-title>{{ 'LOGIN.SELECT_PLANT' | translate }}</div>
    </div>

    <div mat-dialog-content>
      <div class="plants-list">
        <div *ngFor="let plant of plants" class="dialog-content">
          <div (click)="selectPlant(plant)" class="plant-item">
            {{ plant }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
