import { ActivatedRoute, Params } from '@angular/router';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { DeviationWidgetService } from 'src/app/services/deviation-widget.service';
import { CircularProgressWidgetService } from 'src/app/services/circular-progress-widget.service';
import { ProductOrderChartListComponent } from './product-order-chart-list/product-order-chart-list.component';
import { WorkStationService } from '../../../services/work-station.service';
import { ProducedPiecesService } from '../../../services/produced-pieces.service';
import { ProductOrderService } from '../../../services/product-order.service';
import { PlannedPiecesService } from '../../../services/planned-pieces.service';

@Component({
  selector: 'app-machine',
  templateUrl: './machine.component.html',
  styleUrls: ['./machine.component.scss'],
})
export class MachineComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('productOrderChartList')
  productOrderChartList: ProductOrderChartListComponent;

  machineId: number;
  orderNumber: number;
  selectedMachineForPeriods: any;
  piecesPerMinute = {
    current: null,
    target: null,
    deviation: null,
    progressBarValue: null,
    targetLinePosition: null,
    progressBarColorClass: null,
    deviationColorClass: null,
    backgroundColorClass: null,
  };

  quantity = {
    current: null,
    target: null,
    percent: null,
    remaining: null,
    progressBarClass: null,
  };

  oee = null;
  refreshInterval: any;
  productOrderSubscription: Subscription;
  progressBarSize: number;

  constructor(
    private route: ActivatedRoute,
    private workStationService: WorkStationService,
    private producedPiecesService: ProducedPiecesService,
    private productOrderService: ProductOrderService,
    private plannedPiecesService: PlannedPiecesService,
    private circularProgressWidgetService: CircularProgressWidgetService,
    private deviationWidgetService: DeviationWidgetService,
  ) {}

  ngAfterViewInit(): void {
    const offsetPadding = 32;
    this.progressBarSize =
      this.productOrderChartList.deviationWidgetRef?.nativeElement.offsetWidth -
      offsetPadding;
  }

  async ngOnInit(): Promise<void> {
    await this.workStationService.fetchGroupsLiveData();
    this.getMachineData();
    this.plannedPiecesService.fetchPlannedPiecesData();
    this.refreshInterval = setInterval(async () => {
      this.producedPiecesService.fetchProducedPiecesChunk({
        category: 'WorkCenter',
        value: this.selectedMachineForPeriods?.machineId,
      });
      this.plannedPiecesService.fetchPlannedPiecesData();
      await this.workStationService.fetchGroupsLiveData();
      this.fetchCurrentProductOrderLiveData();
    }, 30000);
  }

  ngOnDestroy(): void {
    clearInterval(this.refreshInterval);
    if (this.productOrderSubscription) {
      this.productOrderSubscription.unsubscribe();
    }
  }

  private getMachineData(): void {
    this.route.params.subscribe((params: Params) => {
      if (params.machineId && this.machineId !== params.machineId) {
        this.machineId = params.machineId;
        this.findMachine();
        this.findCurrentProductOrderQuantity();

        this.producedPiecesService.fetchProducedPeacesLiveData({
          category: 'WorkCenter',
          value: this.machineId,
        });
      }
    });
  }

  private findMachine(): void {
    this.workStationService.groups.subscribe((groups: any) => {
      if (this.machineId && groups.length) {
        const {
          piecesPerMinute,
          piecesPerMinuteTarget,
          order,
          counter,
          id,
          workCenterName,
          supportsMultipleOrders,
        } = groups
          .flatMap((group) => {
            return group.subgroups.flatMap((subgroup) => subgroup.machines);
          })
          .find((machine) => machine.id === this.machineId);
        const {
          progressBarValue,
          targetLinePosition,
        } = this.deviationWidgetService.calculateProgressBar(
          piecesPerMinute,
          piecesPerMinuteTarget,
          this.progressBarSize,
        );
        const {
          progressBarColorClass,
          deviationColorClass,
          backgroundColorClass,
        } = this.deviationWidgetService.setDeviationWidgetColorClasses(
          piecesPerMinute,
          piecesPerMinuteTarget,
          true,
        );

        this.piecesPerMinute = {
          current: piecesPerMinute,
          target: piecesPerMinuteTarget,
          deviation: this.deviationWidgetService.calculateDeviation(
            piecesPerMinute,
            piecesPerMinuteTarget,
          ),
          progressBarValue,
          targetLinePosition,
          progressBarColorClass,
          deviationColorClass,
          backgroundColorClass,
        };

        if (order && this.orderNumber !== order) {
          this.orderNumber = order;
          this.fetchCurrentProductOrderLiveData();
        }

        if (counter !== this.quantity.current) {
          this.quantity.current = counter;
          this.calculateQuantity();
        }
        this.selectedMachineForPeriods = {
          machineId: id,
          supportsMultipleOrders,
          machines: [{ workCenterName }],
        };
      }
    });
  }

  private fetchCurrentProductOrderLiveData(): void {
    this.productOrderService.fetchProductOrderLiveData(
      this.machineId,
      this.orderNumber,
    );
  }

  private findCurrentProductOrderQuantity(): void {
    this.productOrderSubscription = this.productOrderService.productOrderSubject.subscribe(
      (productOrder) => {
        if (
          productOrder &&
          productOrder.orderNumber === this.orderNumber &&
          productOrder.workCenter === this.machineId &&
          this.quantity.target !== productOrder.plannedQuantity
        ) {
          this.quantity.target = productOrder.plannedQuantity;
          this.calculateQuantity();
        }
      },
    );
  }

  private calculateQuantity(): void {
    if (this.quantity.target) {
      const progressBarLowerBoundaryPercentage = 95;
      const progressBarUpperBoundaryPercentage = 105;
      const percent = this.circularProgressWidgetService.calculateProgressBarPercentage(
        this.quantity.current,
        this.quantity.target,
      );

      this.quantity = {
        current: this.quantity.current,
        target: this.quantity.target,
        remaining: this.quantity.target - this.quantity.current,
        percent,
        progressBarClass: this.circularProgressWidgetService.getProgressBarClass(
          percent,
          progressBarLowerBoundaryPercentage,
          progressBarUpperBoundaryPercentage,
        ),
      };
    }
  }
}
