import { Component, OnInit } from '@angular/core';
import { format } from 'date-fns';

@Component({
  selector: 'app-group-machines-status-chart',
  templateUrl: './group-machines-status-chart.component.html',
  styleUrls: ['./group-machines-status-chart.component.scss'],
})
export class GroupMachinesStatusChartComponent implements OnInit {
  public now: string = format(new Date(), 'd.M.yyyy');
  public today: string = format(new Date(), 'HH:m');
  constructor() {}

  ngOnInit(): void {}
}
