import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { format } from 'date-fns';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/app/services/loader.service';
import { SharedService } from 'src/app/services/shared.service';
import { WorkStationService } from 'projects/shiftleader-dashboard/src/app/services/work-station.service';
import { Group } from 'projects/shiftleader-dashboard/src/app/model/workstation-data.model';
import { DatePickerService } from '../../../../services/date-picker.service';
import { KpiService } from './../../../../services/kpi.service';
import { ConfigService } from '../../../../services/config.service';

@Component({
  selector: 'app-department-calendar-view',
  templateUrl: './department-calendar-view.component.html',
  styleUrls: [
    './department-calendar-view.component.scss',
    '../../../../../styles/reports.scss',
  ],
})
export class DepartmentCalendarViewComponent implements OnInit, OnDestroy {
  daysInMonth: any;
  selectedDate: Date;
  selectedDateSubscription: Subscription;
  historicalDataSubscription: Subscription;
  groupsSubscription: Subscription;
  kpiNames: string[];
  departmentId: string;
  selectedDayData = [];
  configKpis = [];
  isCircleCalendar = true;

  constructor(
    public router: Router,
    private kpiService: KpiService,
    private datePickerService: DatePickerService,
    public loaderService: LoaderService,
    private sharedService: SharedService,
    private configService: ConfigService,
    private workStationService: WorkStationService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.workStationService.fetchGroupsLiveData();
    this.groupsSubscription = this.workStationService.groups.subscribe(
      (groups: Group[]) => {
        if (groups.length) {
          this.departmentId = this.sharedService.getDepartmentId(groups);
        }
      },
    );
    this.selectedDateSubscription = this.datePickerService.singleDayDate.subscribe(
      (selectedDate) => {
        this.selectedDate = selectedDate;
        this.getHistoricalDataForDepartment();
        this.getConfigKPIs();
        this.getSingleDayData(selectedDate);
      },
    );
    this.historicalDataSubscription = this.kpiService.historicalData.subscribe(
      (historicalData) => {
        if (historicalData && this.selectedDate) {
          const sortedHistoricalData = this.kpiService.sortKPIs(
            historicalData,
            this.configKpis,
          );

          this.daysInMonth = this.kpiService.mapDataObjects(
            sortedHistoricalData,
            this.selectedDate,
          );

          this.kpiNames = this.kpiService.getKPINames(this.daysInMonth);
        }
      },
    );

    if (!this.kpiService.historicalDataSubject.getValue()) {
      this.getHistoricalDataForDepartment();
    }
  }

  getConfigKPIs(): void {
    this.configService.configData.subscribe((response) => {
      this.configKpis = response?.kpis.sort((a, b) =>
        a.sortingOrder > b.sortingOrder ? 1 : -1,
      );
      this.getSingleDayData(this.selectedDate);
    });
  }

  getSingleDayData(selectedDate): void {
    if (selectedDate && this.configKpis?.length && this.departmentId) {
      this.kpiService
        .getSingleDay(
          selectedDate,
          'Department',
          this.departmentId,
          this.configKpis,
        )
        .then((selectedDayData: any) => {
          if (selectedDayData) {
            this.selectedDayData = selectedDayData;
          } else {
            this.selectedDayData = this.kpiService.getConfigKPINames(
              this.configKpis,
            );
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.selectedDateSubscription.unsubscribe();
    this.historicalDataSubscription.unsubscribe();
    this.groupsSubscription.unsubscribe();
  }

  getHistoricalDataForDepartment(): void {
    this.kpiService.getHistoricalData(
      format(this.selectedDate, 'yyyy-MM-dd'),
      'Department',
      this.departmentId,
    );
  }

  keyValueOrder(): number {
    return 0;
  }
}
