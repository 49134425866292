<div [class]="'circular-progress-widget-container ' + backgroundColorClass">
  <div class="left-content-wrapper">
    <div class="circular-progress-widget-title">
      {{ title | translate }}
    </div>
    <div class="value-and-target-wrapper">
      <div class="circular-progress-widget-pieces-content-wrapper">
        <span class="current-label">
          {{ 'OPERATOR_COCKPIT.CURRENT' | translate }}
        </span>
        <div class="quantity-pcs-wrapper">
          <span class="current-value">{{ quantity | number }}</span>
          <span *ngIf="unit" class="unit">
            {{ 'OPERATOR_COCKPIT.SHORT_' + unit?.toUpperCase() | translate }}
          </span>
        </div>
      </div>
      <hr class="divider" />
      <div class="circular-progress-widget-pieces-content-wrapper">
        <span class="target-label">
          {{ 'OPERATOR_COCKPIT.TARGET' | translate }}
        </span>
        <div class="quantity-pcs-wrapper">
          <span class="target-value">{{ target | number }}</span>
          <span *ngIf="unit" class="unit">
            {{ 'OPERATOR_COCKPIT.SHORT_' + unit?.toUpperCase() | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="circular-progress-bar-wrapper">
    <div class="circular-progress-bar-buffer">
      <div class="percentage-wrapper">
        <span class="percentage" [class]="progressBarClass">
          {{ percentage }}%
        </span>
      </div>
      <mat-progress-spinner
        mode="{{ circularProgressMode }}"
        value="{{ circularProgressBuffer }}"
        strokeWidth="{{ circularProgressStroke }}"
      >
      </mat-progress-spinner>
    </div>
    <div class="circular-progress-bar-value" [class]="progressBarClass">
      <mat-progress-spinner
        mode="{{ circularProgressMode }}"
        value="{{ percentage }}"
        strokeWidth="{{ circularProgressStroke }}"
      >
      </mat-progress-spinner>
    </div>
  </div>
</div>
