<div class="machine-wrapper" id="outer-machine-wrapper">
  <app-status-timeline
    [selected]="selectedMachineForPeriods"
  ></app-status-timeline>
  <app-timeline-legend [isMachineLevel]="true"></app-timeline-legend>
  <app-production-overview
    class="production-overview-wrapper"
  ></app-production-overview>
  <app-chart-header
    title="PRODUCT-ORDER.PRODUCT-ORDER-TITLE"
    hideTime="true"
    class="chart-header"
  ></app-chart-header>
  <app-product-order-chart-list
    #productOrderChartList
    [piecesPerMinute]="piecesPerMinute"
    [quantity]="quantity"
    [supportsMultipleOrders]="selectedMachineForPeriods?.supportsMultipleOrders"
  ></app-product-order-chart-list>
  <app-product-order-expansion-panel-list
    id="POdetails"
  ></app-product-order-expansion-panel-list>
</div>
