<div *ngIf="!router.url.includes('machine')" class="subgroup-wrapper">
  <app-status-timeline [selected]="selectedSubgroup"></app-status-timeline>
  <app-timeline-legend></app-timeline-legend>
  <app-group-machines-status-chart></app-group-machines-status-chart>
  <app-production-overview
    class="production-overview-wrapper"
  ></app-production-overview>
  <app-group-status-bar
    [groupStatus]="selectedSubGroupStatus"
    [totalMachines]="totalMachines"
    [statusColorMap]="statusColorMap"
  ></app-group-status-bar>
  <div>
    <app-subgroup-machine-list
      [machineList]="selectedSubgroup?.machines"
    ></app-subgroup-machine-list>
  </div>
</div>
<router-outlet></router-outlet>
