<div class="breadcrumbs-wrapper">
  <div
    *ngFor="let breadcrumb of breadcrumbs; let i = index"
    class="breadcrumb-list-item"
  >
    <mat-icon *ngIf="i > 0" class="breadcrumb-arrow-right"
      >keyboard_arrow_right
    </mat-icon>
    <a [routerLink]="breadcrumb.url" class="breadcrumb-link">
      {{ breadcrumb.label }}
    </a>
  </div>
</div>
