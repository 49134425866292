import { Component, Input, OnInit } from '@angular/core';
import { ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import * as ChartAnnotation from 'chartjs-plugin-annotation';

@Component({
  selector: 'app-report-details-stacked-chart',
  templateUrl: './report-details-stacked-chart.component.html',
  styleUrls: ['./report-details-stacked-chart.component.scss'],
})
export class ReportDetailsStackedChartComponent implements OnInit {
  @Input() barChartData;
  @Input() chartLabels;
  @Input() chartHeading: string;
  @Input() isStackedBar: boolean;
  @Input() marginalValue: number;
  @Input() comparisonLogic: string;
  @Input() isGraphTypeStacked: any;
  @Input() isError: boolean;

  barChartLabels: Label[] = [];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  chartPlugins = [ChartAnnotation];
  barChartOptions;

  chartColors: Color[] = [
    { backgroundColor: '#2ab5ba' },
    { backgroundColor: '#f2c94c' },
    { backgroundColor: '#f2994a' },
    { backgroundColor: '#e74c3c' },
    { backgroundColor: '#9b51e0' },
  ];

  constructor() {}

  ngOnInit(): void {
    this.barChartOptions = {
      responsive: true,
      legend: {
        display: this.isGraphTypeStacked,
        position: 'bottom',
      },
      annotation: {
        annotations: [
          {
            type: 'line',
            mode: 'horizontal',
            scaleID: 'y-axis-0',
            value: this.marginalValue,
            borderColor: 'red',
            borderWidth: 4,
          },
        ],
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback(value, index, values): void {
                return value.toLocaleString(localStorage.getItem('locale'));
              },
            },
          },
        ],
      },
    };

    if (this.isGraphTypeStacked) {
      this.barChartData = this.generateDataObjects();
    } else {
      this.chartColors = this.generateChartColors();
    }
    this.barChartLabels = this.chartLabels;
  }

  generateChartColors(): object[] {
    const chartColors = { backgroundColor: [] };
    this.barChartData?.map((chartData) => {
      chartData?.data?.map((dataValue) => {
        if (this.compareWithMarginalValue(dataValue)) {
          chartColors.backgroundColor.push('#219653'); // green color
        } else {
          chartColors.backgroundColor.push('#e74c3c'); // red color
        }
      });
    });
    return [chartColors];
  }

  compareWithMarginalValue(value: number): boolean {
    if (this.marginalValue) {
      return this.comparisonLogic === 'higher is better'
        ? value >= this.marginalValue
        : value <= this.marginalValue;
    } else {
      return true;
    }
  }

  generateDataObjects(): any[] {
    const data = [];
    this.barChartData?.map((object) => {
      data.push({ ...object, ...{ stack: 'a' } });
    });
    return data;
  }
}
