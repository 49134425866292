import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { formatDistanceStrict } from 'date-fns';
import { SharedService } from 'src/app/services/shared.service';
import { ConfigService } from '../../../../../../services/config.service';

@Component({
  selector: 'app-subgroup-machine-item',
  templateUrl: './subgroup-machine-item.component.html',
  styleUrls: [
    './subgroup-machine-item.component.scss',
    '../../../../../../../../../../src/styles/shared-classes.scss',
  ],
})
export class SubgroupMachineItemComponent implements OnInit, OnDestroy {
  @Input() machine;
  stopTime;
  downTime;
  color;
  config;
  configSubscription: Subscription;

  constructor(private configService: ConfigService) {}

  ngOnInit(): void {
    this.configSubscription = this.configService.configData.subscribe(
      (config) => {
        this.config = config;
      },
    );
    const {
      statusCategory,
      color,
    } = this.configService.setMachineStatusCategory(
      this.machine.statusCode,
      this.config,
    );
    this.color = color;

    if (statusCategory !== 'PRODUCTION' && this.machine.timestamp) {
      this.stopTime = this.machine.timestamp;
      this.downTime = formatDistanceStrict(
        new Date(this.machine.timestamp),
        new Date(),
        {
          locale: SharedService.getLanguageCodeIdentifier().dateFnsLocale,
        },
      )
        .replace('eine', '1')
        .replace('ein', '1');
    }
  }

  ngOnDestroy(): void {
    this.configSubscription.unsubscribe();
  }

  setMachineHeadingText(category: string): string {
    switch (category) {
      case 'PRODUCTION':
        return 'Produktion';
      case 'SETUP':
        return 'Rüsten';
      case 'NOT CONNECTED':
        return 'Nicht verbunden';
      case 'PLANNED DOWNTIME':
        return 'geplanter Stillstand';
      case 'UNPLANNED DOWNTIME':
        return 'ungeplanter Stillstand';
      case 'UNDEFINED STOP':
        return 'unbegründet';
      default:
        return 'Nicht verbunden';
    }
  }
}
