
export const environment = {
  production: true,
  region: 'eu-central-1',
  awsCognitoIdentityPoolId: 'eu-central-1:5a617c3b-e5db-44b5-a692-a26a5b88431f',
  awsUserPoolsId: 'eu-central-1_iGEgmDy3m',
  awsUserPoolsWebClientId: '1t576rk322uh5hl03nn069pc0',
  apiGatewayEndpoint: 'https://syedlu0zz6.execute-api.eu-central-1.amazonaws.com/prod',
  apiGatewayEndpointDataLake: 'https://mu8sl3cqk0.execute-api.eu-central-1.amazonaws.com/prod',
  ldapUsername: 'ldap@kamax.com',
  env: 'production',
  etlApiGatewayEndpoint: 'https://b81unncq9e.execute-api.eu-central-1.amazonaws.com/v2',
  dsnSentry: 'https://4d63d94c1ae94e29aa3ede21c839d1f0@o1253789.ingest.sentry.io/6421345',
  apiGraphQLEndpoint: 'https://wv23n4bwp5dy5pmmh4pusb5eli.appsync-api.eu-central-1.amazonaws.com/graphql'
};
