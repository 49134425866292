<div
  [ngClass]="
    text === 'GROUP-STATUS-BAR.TOTAL'
      ? 'group-status-bar-total-container'
      : 'group-status-bar-item-container'
  "
  (click)="openDialog(text)"
>
  <div
    class="group-status-bar-item-heading"
    appStatusColor
    [ngStyle]="
      text === 'GROUP-STATUS-BAR.TOTAL'
        ? {
            'background-color': '#4085bb'
          }
        : {
            'background-color': color,
            color: '#000'
          }
    "
  >
    {{ text | translate }}
  </div>
  <div
    class="group-status-bar-item-number"
    [ngClass]="number === 0 ? 'txt-grey' : ''"
  >
    {{ number || 0 }}
  </div>
</div>
