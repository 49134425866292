<div class="header-container">
  <div class="header-left-wrapper">
    <div class="nexineer-logo-wrapper">
      <img src="assets/icons/nexineer-logo.svg" />
    </div>

    <div class="header-middle-content-wrapper">
      <app-breadcrumb *ngIf="isShiftleaderDashboard"></app-breadcrumb>
      <div class="header-title-wrapper">
        <div class="page-title">
          {{ headingTitle }}
        </div>
      </div>
    </div>
  </div>

  <div class="header-right-wrapper">
    <!--    <mat-icon>notifications_none</mat-icon>-->
    <div class="language-selector">
      <app-language-selector></app-language-selector>
    </div>
    <div class="profile-picture" [matMenuTriggerFor]="logout">
      <mat-icon aria-hidden="false" class="profile-icon">person</mat-icon>
    </div>
  </div>
</div>

<mat-menu #logout="matMenu" class="logout-menu">
  <button class="logout-menu-item" mat-menu-item (click)="onLogout()">
    {{ 'HEADER.LOG-OUT' | translate }}
  </button>
</mat-menu>
