import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-single-letter-calendar',
  templateUrl: './single-letter-calendar.component.svg',
  styleUrls: ['./single-letter-calendar.component.scss'],
})
export class SingleLetterCalendarComponent implements OnInit {
  @Input() heading: any;
  @Input() daysInMonth: any;
  nodeColors = [];

  constructor() {}

  ngOnInit(): void {
    this.setDayNodeColor();
  }

  setDayNodeColor = () => {
    this.daysInMonth.forEach((day) => {
      if (
        day[this.heading] &&
        day[this.heading][0]?.isTargetFulfilled !== undefined
      ) {
        this.nodeColors.push(
          day[this.heading][0]?.isTargetFulfilled ? '#219653' : '#E74C3C',
        );
      } else {
        this.nodeColors.push('white');
      }
    });
  }
}
