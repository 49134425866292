import { Observable, BehaviorSubject } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import {
  eachDayOfInterval,
  lastDayOfMonth,
  startOfMonth,
  format,
} from 'date-fns';
import { LoaderService } from 'src/app/services/loader.service';
import { SharedService } from 'src/app/services/shared.service';

@Injectable({
  providedIn: 'root',
})
export class KpiService {
  historicalDataPath = '/historical-data';
  historicalDataSubject = new BehaviorSubject(null);
  historicalData: Observable<any> = this.historicalDataSubject.asObservable();
  yearlyDataPath = '/yearly-overview';
  plantId = this.sharedService.getPlantId();

  constructor(
    @Inject('SMSF_API_NAME') private apiName: string,
    private loaderService: LoaderService,
    private sharedService: SharedService,
  ) {}

  createHistoricalDataReport(report: object): Promise<any> {
    const init = {
      headers: {},
      response: true,
      body: report,
    };
    return API.post(
      this.apiName,
      `${this.historicalDataPath}/headcount-safety-cleanliness`,
      init,
    );
  }

  getHistoricalDataReport(date: string): Promise<any> {
    const init = {
      headers: {},
      response: true,
      queryStringParameters: {
        kpiDate: date.toString(),
        plantId: this.plantId,
      },
    };
    return API.get(
      this.apiName,
      `${this.historicalDataPath}/headcount-safety-cleanliness`,
      init,
    );
  }

  getHistoricalData(date, category, value): void {
    this.loaderService.showLoader();

    const init = {
      headers: {},
      response: true,
      queryStringParameters: {
        date: date.toString(),
        category,
        value,
        plantId: this.plantId,
      },
    };
    API.get(this.apiName, this.historicalDataPath, init)
      .then((response) => {
        this.historicalDataSubject.next(response.data);
      })
      .finally(() => {
        this.loaderService.hideLoader();
      });
  }

  getYearlyHistoricalData(date, category, value, kpi): Promise<any> {
    const init = {
      headers: {},
      response: true,
      queryStringParameters: {
        date: format(date, 'yyyy-MM-dd'),
        category,
        value,
        kpi,
        plantId: this.plantId,
      },
    };
    return API.get(
      this.apiName,
      `${this.historicalDataPath + this.yearlyDataPath}`,
      init,
    );
  }

  mapDataObjects(historicalData: any, selectedDate: Date): any[] {
    return eachDayOfInterval({
      start: startOfMonth(selectedDate),
      end: lastDayOfMonth(selectedDate),
    }).map((day) => {
      const dayData = historicalData.find(
        (dataForDay) => day.getDate() === new Date(dataForDay.date).getDate(),
      );
      return dayData ? dayData : { date: day };
    });
  }

  sortKPIs(historicalData: any[], configKpis: any[]): any {
    return historicalData.map((data: any) => {
      const sortedData = {};

      configKpis?.forEach((configKpi) => {
        const kpiName = Object.keys(configKpi)
          .find((kpiKey) => Object.keys(data).includes(kpiKey.toUpperCase()))
          .toUpperCase();

        Object.assign(sortedData, {
          [kpiName]: data[kpiName],
        });
      });

      return {
        date: data.date,
        ...sortedData,
      };
    });
  }

  getSingleDay(selectedDay, category, value, configKpis?: any[]): any {
    const formattedDay = format(selectedDay, 'yyyy-MM-dd');

    const init = {
      headers: {},
      response: true,
      queryStringParameters: {
        date: formattedDay.toString(),
        category,
        value,
        plantId: this.plantId,
      },
    };
    return API.get(this.apiName, this.historicalDataPath, init).then(
      (response) => {
        const matchedDay = response.data.find((day) => {
          return day.date === formattedDay;
        });
        if (matchedDay) {
          return this.sortKPIs([matchedDay], configKpis).pop();
        }
      },
    );
  }

  getKPINames(daysInMonth: any): any[] {
    return [
      ...new Set(
        daysInMonth
          ?.flatMap((item) => Object.keys(item))
          .filter((key) => key !== 'date'),
      ),
    ];
  }

  getConfigKPINames(configKpis): any {
    return configKpis.reduce(
      (acc, cur) => ({
        ...acc,
        [cur.kpiName]: [],
      }),
      {},
    );
  }
}
