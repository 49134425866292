<div>
  <app-chart-header
    title="{{ 'CHART-HEADER.PRODUCED-ITEMS' | translate }}"
    [hideTime]="true"
  ></app-chart-header>

  <div class="production-overview-wrapper">
    <app-production-overview-chart
      [lineChartColors]="lineChartColors"
      [productionOverviewData]="productionOverviewData?.shift"
      class="chart-wrapper"
    >
    </app-production-overview-chart>
    <app-production-overview-chart
      [lineChartColors]="lineChartColors"
      [productionOverviewData]="productionOverviewData?.day"
      class="chart-wrapper"
    >
    </app-production-overview-chart>
  </div>
</div>
