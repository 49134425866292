import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-product-order-chart-list',
  templateUrl: './product-order-chart-list.component.html',
  styleUrls: ['./product-order-chart-list.component.scss'],
})
export class ProductOrderChartListComponent implements OnInit {
  @ViewChild('deviationWidget', { read: ElementRef })
  deviationWidgetRef: ElementRef;
  @Input() piecesPerMinute;
  @Input() quantity;
  @Input() supportsMultipleOrders;

  constructor() {}

  ngOnInit(): void {}
}
