import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Locale } from 'date-fns';
import { de, enUS, zhCN, es } from 'date-fns/locale';
import { Group } from 'projects/shiftleader-dashboard/src/app/model/workstation-data.model';
import { AuthService } from './auth.service';
import {
  LanguageCode,
  LanguageCodeIdentifier,
} from '../enums/language-code.enum';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  departmentId: string;
  readonly languageCodesRgx = /en|de|zh|es/;

  constructor(
    private translate: TranslateService,
    private authService: AuthService,
  ) {}

  static getLanguageCodeIdentifier(): { code: string; dateFnsLocale: Locale } {
    switch (localStorage.getItem('locale')) {
      case LanguageCode.English:
        return { code: LanguageCodeIdentifier.English, dateFnsLocale: enUS };
      case LanguageCode.German:
        return { code: LanguageCodeIdentifier.German, dateFnsLocale: de };
      case LanguageCode.Chinese:
        return { code: LanguageCodeIdentifier.Chinese, dateFnsLocale: zhCN };
      case LanguageCode.Spanish:
        return { code: LanguageCodeIdentifier.Spanish, dateFnsLocale: es };
      default:
        return { code: LanguageCodeIdentifier.English, dateFnsLocale: enUS };
    }
  }

  setDefaultBrowserLanguage(): string {
    const browserLanguage = this.translate.getBrowserLang();
    const defaultLanguage = browserLanguage.match(this.languageCodesRgx)
      ? browserLanguage
      : LanguageCode.English;
    this.translate.setDefaultLang(defaultLanguage);
    return defaultLanguage;
  }

  // TODO: handle multi-department use-case once this feature is available (plant can consist of more than one departments (1:N))
  getDepartmentId(groupList: Group[]): string {
    const [firstDepartment] = [
      ...new Set(groupList?.map((item) => item.department)),
    ];
    return firstDepartment;
  }

  getPlantId(): string {
    return this.authService.user?.plantId;
  }
}
