import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { formatDate } from '@angular/common';
import { Subscription } from 'rxjs';
import { addHours } from 'date-fns';
import { ChartPoint } from 'chart.js';
import { Color } from 'ng2-charts';
import { ProducedPiecesChartService } from 'src/app/services/produced-pieces-chart.service';
import { SharedService } from 'src/app/services/shared.service';
import { ProductionDataModel } from '../../model/production-overview-data.model';
import { Group } from '../../model/workstation-data.model';
import { ProducedPiecesService } from '../../services/produced-pieces.service';
import { PlannedPiecesService } from '../../services/planned-pieces.service';
import { WorkStationService } from '../../services/work-station.service';
import { RouterParamsService } from '../../services/router-params.service';

@Component({
  selector: 'app-production-overview',
  templateUrl: './production-overview.component.html',
  styleUrls: ['./production-overview.component.scss'],
})
export class ProductionOverviewComponent implements OnInit, OnDestroy {
  productionOverviewData: ProductionDataModel = null;
  lineChartColors: Color[];
  targetScope;
  scope;
  isInsideMachine;
  producedPiecesData;
  dynamicTargetLineShift = [];
  dynamicTargetLineDay = [];
  plannedPiecesDataDay;
  plannedPiecesDataShift;
  departmentName;
  groups: Group[];
  groupsSubscription: Subscription;

  constructor(
    private producedPiecesService: ProducedPiecesService,
    private plannedPiecesService: PlannedPiecesService,
    private workStationService: WorkStationService,
    private routerParamsService: RouterParamsService,
    private translate: TranslateService,
    private sharedService: SharedService,
    private producedPiecesChartService: ProducedPiecesChartService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.groupsSubscription = this.workStationService.groups.subscribe(
      (groups: Group[]) => {
        if (groups.length) {
          this.groups = groups;
        }
      },
    );
    this.getRouterParams();
    this.departmentName = this.sharedService.getDepartmentId(this.groups);
    this.setTargetScope();
    this.plannedPiecesService.plannedPiecesShiftSubject.subscribe(
      (plannedPiecesData: any) => {
        this.plannedPiecesDataShift = plannedPiecesData;
        if (plannedPiecesData && this.isInsideMachine) {
          this.calculateProducedPieces();
        }
      },
    );
    this.plannedPiecesService.plannedPiecesDaySubject.subscribe(
      (plannedPiecesData: any) => {
        this.plannedPiecesDataDay = plannedPiecesData;
        if (plannedPiecesData && this.isInsideMachine) {
          this.calculateProducedPieces();
        }
      },
    );

    this.producedPiecesService.producedPiecesSubject.subscribe(
      (producedPiecesData: any) => {
        const isSubscribeInSyncWithCurrentPage =
          producedPiecesData &&
          this.scope in producedPiecesData?.producedPiecesDay;

        if (isSubscribeInSyncWithCurrentPage) {
          this.producedPiecesData = producedPiecesData;
          this.calculateProducedPieces();
        }
        if (!this.lineChartColors) {
          this.lineChartColors = this.producedPiecesChartService.getLineChartColors(
            this.producedPiecesData?.isShift,
          );
        }
      },
    );
  }

  ngOnDestroy(): void {
    this.groupsSubscription.unsubscribe();
  }

  private calculateProducedPieces(): void {
    if (this.targetScope && this.plannedPiecesDataShift) {
      this.dynamicTargetLineShift = this.producedPiecesChartService.calculateTargetLine(
        this.plannedPiecesDataShift[this.targetScope],
      );
    }
    if (this.targetScope && this.plannedPiecesDataDay) {
      this.dynamicTargetLineDay = this.producedPiecesChartService.calculateTargetLine(
        this.plannedPiecesDataDay[this.targetScope],
      );
    }

    const graphDayStartDate = new Date(new Date().setHours(0, 0, 0, 0));
    const graphDayEndDate = addHours(graphDayStartDate, 24);
    const {
      graphShiftStartDate,
      graphShiftEndDate,
    } = this.producedPiecesChartService.getChartShiftStartEndDates(
      this.producedPiecesData?.currentShift,
      this.producedPiecesData?.shiftStart,
      this.producedPiecesData?.shiftEnd,
    );

    const shiftChartData: ChartPoint[] = this.producedPiecesChartService.mapProducedPiecesToChartPoints(
      this.producedPiecesData?.producedPiecesShift?.[this.scope]?.periodsCount,
      graphShiftStartDate,
    );
    const dayChartData: ChartPoint[] = this.producedPiecesChartService.mapProducedPiecesToChartPoints(
      this.producedPiecesData?.producedPiecesDay?.[this.scope]?.periodsCount,
      graphDayStartDate,
    );

    const targetPerShift = this.producedPiecesData?.targetsPerShift?.[
      this.scope
    ];
    const targetPerDay = this.producedPiecesData?.targetsPerDay?.[this.scope];
    const shiftTranslate = this.translate.instant('SUBGROUP-ITEM.SHIFT');
    const dayTranslate = this.translate.instant('SUBGROUP-ITEM.DAY');
    const dateLocale = SharedService.getLanguageCodeIdentifier().code;

    if (this.producedPiecesData) {
      this.productionOverviewData = {
        shift: {
          isShift: true,
          currentShift: this.producedPiecesData?.currentShift,
          duration:
            `${shiftTranslate}: ` + this.producedPiecesData?.currentShift,
          currentlyProduced: this.producedPiecesData?.producedPiecesShift?.[
            this.scope
          ]?.totalCount,
          targetProduction: this.producedPiecesData?.targetsPerShift?.[
            this.scope
          ],
          currentlyProducedPercentage: Math.round(
            (this.producedPiecesData?.producedPiecesShift?.[this.scope]
              ?.totalCount /
              this.producedPiecesData?.targetsPerShift?.[this.scope]) *
              100,
          ),
          lineChartData: [
            {
              data: shiftChartData,
            },
            {
              data: this.isInsideMachine ? this.dynamicTargetLineShift : [],
              lineTension: 0,
            },
            {
              data: [
                {
                  x: graphShiftStartDate,
                  y: targetPerShift,
                },
                {
                  x: graphShiftEndDate,
                  y: targetPerShift,
                },
              ],
            },
          ],
          lineChartOptions: this.producedPiecesChartService.getLineChartOptions(
            this.producedPiecesData?.targetsPerShift?.[this.scope],
          ),
        },
        day: {
          isShift: false,
          duration:
            `${dayTranslate}: ` +
            formatDate(graphDayStartDate, 'shortDate', dateLocale),
          currentlyProduced: this.producedPiecesData?.producedPiecesDay?.[
            this.scope
          ]?.totalCount,
          targetProduction: this.producedPiecesData?.targetsPerDay?.[
            this.scope
          ],
          currentlyProducedPercentage: Math.round(
            (this.producedPiecesData?.producedPiecesDay?.[this.scope]
              ?.totalCount /
              this.producedPiecesData?.targetsPerDay?.[this.scope]) *
              100,
          ),
          lineChartData: [
            {
              data: dayChartData,
            },
            {
              data: this.isInsideMachine ? this.dynamicTargetLineDay : [],
              lineTension: 0,
            },
            {
              data: [
                {
                  x: graphDayStartDate,
                  y: targetPerDay,
                },
                {
                  x: graphDayEndDate,
                  y: targetPerDay,
                },
              ],
            },
          ],
          lineChartOptions: this.producedPiecesChartService.getLineChartOptions(
            this.producedPiecesData?.targetsPerDay?.[this.scope],
          ),
        },
      };
    }
  }

  private getRouterParams(): void {
    this.routerParamsService.routerParams.subscribe(async (res: any) => {
      const { subgroupName, groupId, machineId } = res;
      if (machineId) {
        this.scope = machineId;
        this.isInsideMachine = true;
      } else if (subgroupName) {
        this.isInsideMachine = false;
        this.scope = subgroupName;
      } else if (groupId) {
        this.isInsideMachine = false;

        this.scope = this.workStationService.getGroupNameById(
          groupId,
          this.groups,
        );
      } else {
        this.isInsideMachine = false;
        this.scope = 'Department';
      }

      this.calculateProducedPieces();
    });
  }

  private setTargetScope(): void {
    this.routerParamsService.routerParams.subscribe(async (res: any) => {
      const { subgroupName, groupId, machineId } = res;
      if (machineId) {
        this.targetScope = machineId;
      } else if (subgroupName) {
        this.targetScope = `${this.departmentName}-${subgroupName}`;
      } else if (groupId) {
        const groupName = this.workStationService.getGroupNameById(
          groupId,
          this.groups,
        );
        this.targetScope = `${this.departmentName}-${groupName}`;
      } else {
        this.targetScope = this.departmentName;
      }

      this.calculateProducedPieces();
    });
  }
}
