import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { WorkStationService } from '../../../../services/work-station.service';
import { ProducedPiecesService } from '../../../../services/produced-pieces.service';
import { ConfigService } from '../../../../services/config.service';

@Component({
  selector: 'app-subgroup',
  templateUrl: './subgroup.component.html',
  styleUrls: ['./subgroup.component.scss'],
})
export class SubgroupComponent implements OnInit, OnDestroy {
  subgroupName: string;
  groupId;
  selectedSubgroup;
  selectedSubGroupStatus: any;
  totalMachines: number;
  refreshInterval;
  params: object;
  statusColorMap: object;
  config;
  configSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private workstationService: WorkStationService,
    private producedPiecesService: ProducedPiecesService,
    private configService: ConfigService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.configService.getConfig();
    this.configSubscription = this.configService.configData.subscribe(
      (config) => {
        this.statusColorMap = config?.machineOverview;
        this.config = config;
      },
    );
    await this.workstationService.fetchGroupsLiveData();
    this.getRouteParams();
    this.refreshInterval = setInterval(() => {
      this.producedPiecesService.fetchProducedPiecesChunk({
        category: 'Subgroup',
        value: this.selectedSubgroup?.subgroupName,
      });
    }, 30000);
  }

  getRouteParams(): void {
    this.route.params.subscribe((params: Params) => {
      if (params.groupId) {
        this.groupId = params.groupId;
      }
    });

    this.route.params.subscribe((params: Params) => {
      if (params.subgroupName) {
        this.subgroupName = params.subgroupName;
        this.findSubgroup();
        this.producedPiecesService.fetchProducedPeacesLiveData({
          category: 'Subgroup',
          value: this.subgroupName,
        });
      }
    });
  }

  private findSubgroup(): void {
    this.workstationService.groups.subscribe((groups: any) => {
      if (this.groupId && this.subgroupName && groups.length) {
        const group = groups.find(
          (groupItem) => groupItem.groupId === this.groupId,
        );
        const selectedSubgroup = group?.subgroups.find(
          (subgroup) => subgroup?.subgroupName === this.subgroupName,
        );
        this.selectedSubgroup = selectedSubgroup;
        if (selectedSubgroup?.machines) {
          this.totalMachines = selectedSubgroup.machines.length;
          this.selectedSubGroupStatus = this.workstationService.groupMachinesByStatus(
            selectedSubgroup.machines,
            this.config,
          );
        }
      }
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.refreshInterval);
    this.configSubscription.unsubscribe();
  }
}
