<canvas
  baseChart
  height="100%"
  [plugins]="lineChartPlugins"
  [datasets]="lineChartData"
  [options]="lineChartOptions"
  [colors]="lineChartColors"
  [legend]="lineChartLegend"
  [chartType]="lineChartType"
>
</canvas>
