import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/material/material.module';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { ToolLifetimeRoutingModule } from './tool-lifetime-routing.module';
import { ToolLifetimeComponent } from './tool-lifetime.component';
import { OverviewComponent } from './overview/overview.component';
import { DeviationComponent } from './deviation/deviation.component';

@NgModule({
  declarations: [ToolLifetimeComponent, OverviewComponent, DeviationComponent],
  imports: [
    CommonModule,
    ToolLifetimeRoutingModule,
    MaterialModule,
    SharedModule,
    TranslateModule.forChild({
      extend: true,
    }),
  ],
})
export class ToolLifetimeModule {}
