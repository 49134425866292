import { Inject, Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { BehaviorSubject, Observable } from 'rxjs';
import { SharedService } from 'src/app/services/shared.service';

@Injectable({
  providedIn: 'root',
})
export class ProductOrderService {
  productOrderPath = '/department/productorder';
  productOrderSubject = new BehaviorSubject<any>(null);
  currentProductOrderSubject = new BehaviorSubject<any>(null);
  selectedProductOrderSubject = new BehaviorSubject(null);
  selectedProductOrder: Observable<
    any
  > = this.selectedProductOrderSubject.asObservable();

  constructor(
    @Inject('SMSF_API_NAME') private apiName: string,
    private sharedService: SharedService,
  ) {}

  async getProductOrders(queryStringParameters: any): Promise<object> {
    const init = {
      headers: {},
      response: true,
      queryStringParameters: {
        ...queryStringParameters,
        plantId: this.sharedService.getPlantId(),
      },
    };
    return API.get(this.apiName, this.productOrderPath, init);
  }

  fetchProductOrderLiveData(
    machineId: number,
    order: number,
    isCurrentPOdetails?: boolean,
  ): void {
    const parameters = {
      workCenter: machineId,
      ...(order && { orderNumber: order }),
    };
    this.getProductOrders(parameters)
      .then((result: any) => {
        if (isCurrentPOdetails) {
          this.currentProductOrderSubject.next(result.data);
        } else {
          this.productOrderSubject.next(result.data);
        }
      })
      .catch();
  }

  setSelectedProductionOrder(productOrder: number): void {
    this.selectedProductOrderSubject.next(productOrder);
  }
}
