<div
  *ngFor="let period of periods; index as i"
  [ngStyle]="{
    width:
      ((period?.endOffset - period?.startOffset) / dayPeriod?.difference) *
        100 +
      '%'
  }"
>
  <div
    *ngIf="period?.statusCategory !== 'NODATA'"
    class="period-wrapper"
    [ngStyle]="{
      'background-color': period.statusCode
        ? setMachineColor(period.statusCode)
        : period.color
    }"
    matTooltipClass="tooltip-class"
    matTooltipPosition="above"
    matTooltip="{{
      period.statusCode
        ? ('TIMELINE_TOOLTIP.WORKCENTER' | translate) +
          period.workCenterName +
          '\n' +
          ('TIMELINE_TOOLTIP.START_TIME' | translate) +
          (period?.start | date: 'dd.MM.yyyy HH:mm') +
          '\n' +
          ('TIMELINE_TOOLTIP.END_TIME' | translate) +
          (period?.end | date: 'dd.MM.yyyy HH:mm') +
          '\n' +
          ('TIMELINE_TOOLTIP.STATUS' | translate) +
          ('MACHINE_STATUS_CODE.' + (period.statusCode || 0) | translate)
        : ('TIMELINE_TOOLTIP.PO' | translate) +
          period.orderNumber +
          '\n' +
          ('TIMELINE_TOOLTIP.MATERIAL_NO' | translate) +
          period.materialNumber +
          '\n' +
          ('TIMELINE_TOOLTIP.PRESS' | translate) +
          period.pressFamily +
          '\n' +
          ('TIMELINE_TOOLTIP.START_TIME' | translate) +
          (period?.start | date: 'dd.MM.yyyy HH:mm') +
          '\n' +
          ('TIMELINE_TOOLTIP.END_TIME' | translate) +
          (period?.end | date: 'dd.MM.yyyy HH:mm')
    }}"
  ></div>
</div>
