<app-timeline-actions
  [hourNow]="hourNow"
  [selectedDateTime]="selectedDateTime"
  [isLoadingPeriods]="isLoadingPeriods"
  (changeNavigationEmitter)="changeNavigationHandler($event)"
></app-timeline-actions>
<div class="timeline-wrapper">
  <div *ngIf="isLoaded; else elseBlock">
    <div
      class="timeline-shift-indicator"
      *ngIf="shifts.length > 0; else shiftElseBlock"
    >
      <div
        *ngFor="let hour of firstShiftHourOffset"
        class="shift-start-hour-offset"
      ></div>
      <div
        *ngFor="let minute of firstShiftMinuteOffset"
        class="shift-start-minute-offset"
      ></div>
      <div class="shift-label-outer-wrapper">
        <div class="shift-label-inner-wrapper">
          <div class="shift-label">
            {{ 'SUBGROUP-ITEM.SHIFT' | translate }} {{ shifts[0] }}
          </div>
        </div>
      </div>
      <ng-container *ngIf="shifts.length > 1">
        <div
          *ngFor="let hour of secondShiftHourOffset"
          class="shift-start-hour-offset"
        ></div>
      </ng-container>
      <div class="shift-label-outer-wrapper" *ngIf="shifts.length > 1">
        <div class="shift-label-inner-wrapper">
          <div class="shift-label">
            {{ 'SUBGROUP-ITEM.SHIFT' | translate }} {{ shifts[1] }}
          </div>
        </div>
      </div>
    </div>
    <ng-template #shiftElseBlock>
      <div class="empty-shift-block"></div>
    </ng-template>
    <div class="timeline-hours-wrapper">
      <div *ngFor="let hour of hourList" class="hour-wrapper">
        <div
          class="hour"
          *ngIf="
            hour !== currentDateTime || !isCurrentTimeOnTimeline;
            else currentHourBlock
          "
        >
          {{ hour }}
        </div>
        <ng-template #currentHourBlock>
          <div class="hour hour-now">
            {{ hour }}
            <div
              class="current-time-vertical-marker"
              [style.height.px]="currentTimeHeight"
            ></div>
          </div>
        </ng-template>
        <div class="ticks">
          <div *ngFor="let tick of ['', '', '', '']" class="small-ticks"></div>
        </div>
      </div>
      <div
        class="hour"
        *ngIf="
          hourNow !== currentDateTime || !isCurrentTimeOnTimeline;
          else currentHourNowBlock
        "
      >
        {{ hourNow }}
      </div>
      <ng-template #currentHourNowBlock>
        <div class="hour hour-now">
          {{ hourNow }}
          <div
            class="current-time-vertical-marker"
            [style.height.px]="currentTimeHeight"
          ></div>
        </div>
      </ng-template>
      <div style="padding-left: 20px"></div>
    </div>

    <div #machineTimelineWrapper class="progress-bar-list-wrapper">
      <div class="progress-bar-list-label-wrapper">
        <div
          *ngFor="let machine of selected.machines; index as i"
          class="progress-bar-wrapper"
        >
          <a
            class="label"
            [style.pointer-events]="
              selected.machines.length > 1 ? 'auto' : 'none'
            "
            [routerLink]="[
              'machine',
              machine.id,
              'name',
              machine?.workCenterName
            ]"
          >
            {{ machine?.workCenterName | uppercase }}
          </a>
        </div>
      </div>
      <div class="progress-bar-list-item-wrapper">
        <div
          *ngFor="let machinePeriods of calculatedMachinePeriods; index as i"
          class="progress-bar-wrapper"
        >
          <app-progress-bar
            [periods]="machinePeriods"
            [dayPeriod]="dayPeriod"
            class="progress-bar"
          ></app-progress-bar>
        </div>
      </div>
    </div>

    <div *ngIf="selected.machineId">
      <div #productOrderTimelineWrapper class="progress-bar-list-wrapper">
        <div class="progress-bar-list-label-wrapper">
          <ng-container
            *ngIf="selected.supportsMultipleOrders; else singleProductionOrders"
          >
            <div
              *ngFor="let productOrder of productOrders"
              class="progress-bar-wrapper"
            >
              <div class="label">
                <a
                  (click)="scrollToPOdetails(productOrder?.orderNumber)"
                  class="link"
                >
                  {{ productOrder?.orderNumber }}
                </a>
              </div>
            </div>
          </ng-container>
          <ng-template #singleProductionOrders>
            <div class="progress-bar-wrapper">
              <div class="label">
                <a (click)="scrollToPOdetails()" class="link">
                  {{ 'PRODUCT-ORDER.PRODUCT-ORDER-TITLE' | translate }}
                </a>
              </div>
            </div>
          </ng-template>
        </div>
        <div class="progress-bar-list-item-wrapper">
          <ng-container *ngIf="calculatedProductOrderPeriods.length === 0">
            <app-loader [size]="40"></app-loader>
          </ng-container>
          <div
            *ngFor="let productOrderPeriods of calculatedProductOrderPeriods"
            class="progress-bar-wrapper"
          >
            <app-progress-bar
              [periods]="productOrderPeriods"
              [dayPeriod]="dayPeriod"
              class="progress-bar"
            ></app-progress-bar>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #elseBlock>
    <app-loader></app-loader>
  </ng-template>
</div>
