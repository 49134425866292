<mat-expansion-panel
  [expanded]="productOrder?.orderNumber == selectedProductOrder"
  class="expansion-panel"
  [ngClass]="productOrder?.state"
>
  <mat-expansion-panel-header class="expansion-panel-header">
    <div class="header-content">
      <div class="expansion-panel-item">
        <div class="expansion-panel-item-title">
          {{ 'PO.PO_NUMBER' | translate }}
        </div>
        <div class="expansion-panel-item-value">
          {{
            productOrder?.orderNumber
              ? productOrder?.orderNumber
              : ('NOT_AVAILABLE' | translate)
          }}
        </div>
      </div>

      <div class="expansion-panel-item">
        <div class="expansion-panel-item-title">
          {{ 'PO.MATERIAL_NO' | translate }}
        </div>
        <div class="expansion-panel-item-value">
          {{
            productOrder?.materialNumber
              ? productOrder?.materialNumber
              : ('NOT_AVAILABLE' | translate)
          }}
        </div>
      </div>

      <div class="expansion-panel-item">
        <div class="expansion-panel-item-title">
          {{
            productOrder?.state != 'next'
              ? ('PO.ACTUAL_PLANNED_QUANTITY' | translate)
              : ('PO.PLANNED_QUANTITY' | translate)
          }}
        </div>
        <div class="expansion-panel-item-value">
          {{
            (productOrder?.actualQuantity | number)
              ? (productOrder?.actualQuantity | number) + ' / '
              : productOrder?.state != 'next'
              ? ('NOT_AVAILABLE' | translate) + ' / '
              : ''
          }}
          {{
            (productOrder?.plannedQuantity | number)
              ? (productOrder?.plannedQuantity | number)
              : ('NOT_AVAILABLE' | translate)
          }}
        </div>
      </div>

      <div class="expansion-panel-item">
        <div class="expansion-panel-item-title">
          {{ 'PO.PRESS' | translate }}
        </div>
        <div class="expansion-panel-item-value">
          {{
            productOrder?.pressFamily
              ? productOrder?.pressFamily
              : ('NOT_AVAILABLE' | translate)
          }}
        </div>
      </div>

      <div class="expansion-panel-item">
        <div class="expansion-panel-item-title">
          {{
            productOrder?.state != 'next'
              ? ('PO.ACTUAL_PLANNED_SPEED' | translate)
              : ('PO.PLANNED_SPEED' | translate)
          }}
        </div>
        <div class="expansion-panel-item-value">
          {{
            (productOrder?.actualSpeed | number)
              ? (productOrder?.actualSpeed | number) + ' / '
              : productOrder?.state != 'next'
              ? ('NOT_AVAILABLE' | translate) + ' / '
              : ''
          }}
          {{
            (productOrder?.plannedSpeed | number)
              ? (productOrder?.plannedSpeed | number)
              : ('NOT_AVAILABLE' | translate)
          }}
          {{
            productOrder?.actualSpeed || productOrder?.plannedSpeed
              ? ('PO.PIECES_PER_MINUTE' | translate)
              : ''
          }}
        </div>
      </div>
    </div>
  </mat-expansion-panel-header>

  <div class="expansion-panel-body">
    <div class="empty-panel-item"></div>
    <div class="main-content">
      <div class="expansion-panel-item">
        <div class="expansion-panel-item-title">
          {{ 'PO.WEIGHT' | translate }}
        </div>
        <div class="expansion-panel-item-value">
          {{
            productOrder?.materialWeight
              ? productOrder?.materialWeight
              : ('NOT_AVAILABLE' | translate)
          }}
        </div>
      </div>

      <div class="expansion-panel-item">
        <div class="expansion-panel-item-title">
          {{ 'PO.RAW_MATERIAL' | translate }}
        </div>
        <div class="expansion-panel-item-value">
          {{
            productOrder?.rawMaterial
              ? productOrder?.rawMaterial
              : ('NOT_AVAILABLE' | translate)
          }}
        </div>
      </div>

      <div class="expansion-panel-item">
        <div class="expansion-panel-item-title">
          {{ 'PO.WIRE_DIAMETER' | translate }}
        </div>
        <div class="expansion-panel-item-value">
          {{
            (productOrder?.wireDiameter | number)
              ? (productOrder?.wireDiameter | number)
              : ('NOT_AVAILABLE' | translate)
          }}
        </div>
      </div>

      <div class="expansion-panel-item">
        <div class="expansion-panel-item-title">
          {{ 'PO.RAW_MATERIAL_NUMBER' | translate }}
        </div>
        <div class="expansion-panel-item-value">
          {{
            productOrder?.rawMaterialBatchNumber
              ? productOrder?.rawMaterialBatchNumber
              : ('NOT_AVAILABLE' | translate)
          }}
        </div>
      </div>

      <div class="expansion-panel-item">
        <div class="expansion-panel-item-date">
          <span class="expansion-panel-item-title">
            {{ 'PO.START_DATE' | translate }}
          </span>
          <span class="expansion-panel-item-value"
            >{{
              (productOrder?.actualStart | date: 'short')
                ? (productOrder?.actualStart | date: 'short')
                : ('NOT_AVAILABLE' | translate)
            }}
          </span>
        </div>
      </div>

      <div
        class="expansion-panel-item"
        *ngIf="productOrder?.state != 'current'"
      >
        <div class="expansion-panel-item-date">
          <span class="expansion-panel-item-title">
            {{ 'PO.END_DATE' | translate }}</span
          >
          <span class="expansion-panel-item-value"
            >{{
              (productOrder?.actualEnd | date: 'short')
                ? (productOrder?.actualEnd | date: 'short')
                : ('NOT_AVAILABLE' | translate)
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</mat-expansion-panel>
