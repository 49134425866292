import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-subgroup-machine-list',
  templateUrl: './subgroup-machine-list.component.html',
  styleUrls: ['./subgroup-machine-list.component.scss'],
})
export class SubgroupMachineListComponent implements OnInit {
  @Input() machineList;

  constructor() {}

  ngOnInit(): void {}
}
