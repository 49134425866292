import { Component, OnInit } from '@angular/core';
import { format } from 'date-fns';
import { LoaderService } from 'src/app/services/loader.service';
import { DatePickerService } from './../../../services/date-picker.service';
import { KpiService } from './../../../services/kpi.service';
import { Group } from './../../../model/workstation-data.model';
import { WorkStationService } from './../../../services/work-station.service';

@Component({
  selector: 'app-submit-a-report',
  templateUrl: './submit-a-report.component.html',
  styleUrls: ['./submit-a-report.component.scss'],
})
export class SubmitAReportComponent implements OnInit {
  groupList: Group[];
  selectedDate: string;

  constructor(
    private workStationService: WorkStationService,
    private kpiService: KpiService,
    private datepickerService: DatePickerService,
    public loaderService: LoaderService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.workStationService.fetchGroupsLiveData();
    this.workStationService.groups.subscribe((groups: Group[]) => {
      if (groups.length) {
        this.groupList = groups;
      }
    });

    this.datepickerService.selectedDateSubject.next(new Date());

    this.datepickerService.selectedDate.subscribe((selectedDate) => {
      this.loaderService.showLoader();
      this.selectedDate = format(selectedDate, 'yyyy-MM-dd');
      this.kpiService
        .getHistoricalDataReport(this.selectedDate)
        .then(async (response) => {
          if (response.data.length > 0) {
            this.groupList = this.groupList.map((group) => {
              return {
                ...group,
                ...response.data.find(
                  (responseGroup) =>
                    responseGroup.groupName === group.groupName,
                ),
              };
            });
          }
          this.loaderService.hideLoader();
        })
        .catch((err) => this.loaderService.hideLoader());
    });
  }
}
