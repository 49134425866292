import { GroupCalendarViewComponent } from './reports/group-calendar-view/group-calendar-view.component';
import { DepartmentCalendarViewComponent } from './reports/department-calendar-view/department-calendar-view.component';
import { MachineCalendarViewComponent } from './reports/machine-calendar-view/machine-calendar-view.component';
import { SubgroupCalendarViewComponent } from './reports/subgroup-calendar-view/subgroup-calendar-view.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/services/auth.guard';
import { GroupGuard } from 'src/app/services/group.guard';
import { UserGroup } from 'src/app/enums/user-group.enum';
import { environment } from 'src/environments/environment';
import { TranslationLoaderResolverService } from 'src/app/services/translation-loader-resolver.service';
import { ReportsComponent } from './reports/reports.component';
import { SubmitAReportComponent } from './submit-a-report/submit-a-report.component';
import { ReportDetailsComponent } from './reports/report-details/report-details.component';
import { KpiResolverService } from '../../services/kpi-resolver.service';

const commonGroups =
  environment?.env === 'development' ? [UserGroup.AppSmsfDevAll] : [];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'reports',
    pathMatch: 'full',
  },
  {
    path: 'reports',
    component: ReportsComponent,
    resolve: {
      TranslationLoaderResolverService,
      param: KpiResolverService,
    },
    canActivate: [AuthGuard, GroupGuard],
    data: {
      title: 'SIDEBAR.KPI-OVERVIEW',
      allowedGroups: [
        ...commonGroups,
        UserGroup.AppSmsfViewKPI,
        UserGroup.AppSmsfEditKPI,
      ],
      breadcrumb: ({ param }) => param.kpiOverview,
    },
    children: [
      {
        path: '',
        redirectTo: 'department',
        pathMatch: 'full',
        data: { title: 'HEADER.DEPARTMENT' },
      },
      {
        path: 'department',
        component: DepartmentCalendarViewComponent,
        resolve: { param: KpiResolverService },
        data: { breadcrumb: ({ param }) => param.department },
        children: [
          {
            path: 'details/:reportName',
            component: ReportDetailsComponent,
            resolve: { param: KpiResolverService },
            data: {
              breadcrumb: ({ param }) => param.reportName,
            },
          },
        ],
      },
      {
        path: 'group/:groupId',
        component: GroupCalendarViewComponent,
        resolve: { param: KpiResolverService },
        data: {
          breadcrumb: ({ param }) => param.groupId,
        },
        children: [
          {
            path: 'details/:reportName',
            component: ReportDetailsComponent,
            resolve: { param: KpiResolverService },
            data: {
              breadcrumb: ({ param }) => param.reportName,
            },
          },
        ],
      },
      {
        path: 'subgroup/:subgroupName',
        component: SubgroupCalendarViewComponent,
        resolve: { param: KpiResolverService },
        data: {
          breadcrumb: ({ param }) => param.subgroupName,
        },
        children: [
          {
            path: 'details/:reportName',
            component: ReportDetailsComponent,
            resolve: { param: KpiResolverService },
            data: {
              breadcrumb: ({ param }) => param.reportName,
            },
          },
        ],
      },
      {
        path: 'machine/:machineId/name/:machineName',
        component: MachineCalendarViewComponent,
        resolve: { param: KpiResolverService },
        data: {
          breadcrumb: ({ param }) => param.machineName,
        },
        children: [
          {
            path: 'details/:reportName',
            component: ReportDetailsComponent,
            resolve: { param: KpiResolverService },
            data: {
              breadcrumb: ({ param }) => param.reportName,
            },
          },
        ],
      },
    ],
  },
  {
    path: 'submit-a-report',
    component: SubmitAReportComponent,
    resolve: { param: KpiResolverService },
    canActivate: [AuthGuard, GroupGuard],
    data: {
      title: 'SIDEBAR.MANUAL-REPORT',
      allowedGroups: [...commonGroups, UserGroup.AppSmsfEditKPI],
      breadcrumb: ({ param }) => param.manualReports,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HistoricalDataRoutingModule {}
