import { Inject, Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Group, Machine, StatusMachine } from '../model/workstation-data.model';
import { API } from 'aws-amplify';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root',
})
export class WorkStationService {
  groupsPath = '/department/groups';
  groupsSubject = new BehaviorSubject<Group[]>([]);
  groups: Observable<Group[]> = this.groupsSubject.asObservable();

  constructor(
    @Inject('SMSF_API_NAME') private apiName: string,
    private authService: AuthService,
    private injector: Injector,
  ) {}

  async fetchGroupsLiveData(): Promise<void> {
    await this.getGroups()
      .then((result: any) => {
        if (result) {
          this.groupsSubject.next(result.data);
        }
      })
      .catch();
  }

  private async getGroups(): Promise<object> {
    if (this.authService.user?.plantId) {
      const init = {
        headers: {},
        response: true,
        queryStringParameters: {
          plantId: this.authService.user?.plantId,
        },
      };
      return API.get(this.apiName, this.groupsPath, init);
    }
  }

  getGroupNameById(groupId: string, groupList: Group[]): string | null {
    if (groupList) {
      return groupList.find((group) => {
        return group.groupId === groupId;
      })?.groupName;
    } else {
      return null;
    }
  }

  getMachinesFromSubGroups(subgroups): Machine[] {
    return subgroups?.flatMap((subgroup) => subgroup.machines);
  }

  getMachinesFromGroups(groups): Machine[] {
    return groups?.flatMap((group) =>
      this.getMachinesFromSubGroups(group.subgroups),
    );
  }

  groupMachinesByStatus(machines, config): StatusMachine {
    return machines?.reduce((acc, current) => {
      const configService = this.injector.get(ConfigService);
      const { statusCategory } = configService.setMachineStatusCategory(
        current.statusCode,
        config,
      );
      acc[statusCategory] = [...(acc[statusCategory] || []), current];

      return acc;
    }, {});
  }

  getTotalMachines(groupStatus): number {
    return Object.keys(groupStatus).reduce((acc, cur) => {
      return (acc += groupStatus[cur].length);
    }, 0);
  }
}
