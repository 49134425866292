import { Component, Input, OnInit } from '@angular/core';
import { ConfigService } from '../../services/config.service';

@Component({
  selector: 'app-timeline-legend',
  templateUrl: './timeline-legend.component.html',
  styleUrls: ['./timeline-legend.component.scss'],
})
export class TimelineLegendComponent implements OnInit {
  @Input() isMachineLevel = false;
  allStatuses = {};
  periods = {};

  constructor(private configService: ConfigService) {}

  ngOnInit(): void {
    this.getAllStatusesAndPeriods();
  }

  getAllStatusesAndPeriods(): void {
    this.configService.configData.subscribe((response) => {
      if (response?.machineOverview) {
        const { machineOverview } = response;
        for (const item of Object.keys(machineOverview)) {
          this.allStatuses[item] = machineOverview[item].color;
        }
      }
      if (response?.productOrder) {
        const { productOrder } = response;
        for (const item of Object.keys(productOrder)) {
          this.periods[item] = productOrder[item].color;
        }
      }
    });
  }

  keyValueOrder = (): number => {
    return 0;
  }
}
