<div class="subgroup-item-wrapper">
  <div class="subgroup-item-heading">
    <div class="subgroup-item-heading-title">
      {{ subGroupItem?.subgroupName }}
    </div>
    <a
      class="subgroup-item-heading-link"
      routerLink="/shiftleader-dashboard/realtime-data/department/{{
        departmentId
      }}/group/{{ groupId }}/subgroup/{{ subGroupItem?.subgroupName }}"
    >
      {{ 'SUBGROUP-ITEM.SEE-DETAILS' | translate }}
      <mat-icon>navigate_next</mat-icon>
    </a>
  </div>

  <div class="subgroup-item-content">
    <div class="subgroup-item-data">
      <div class="subgroup-item-data-heading">
        {{ 'SUBGROUP-ITEM.SHIFT' | translate }}: {{ currentShift }}
      </div>
      <div class="subgroup-item-data-number">
        <span class="subgroup-item-data-number-producedPieces-shift">{{
          producedPiecesShift | number
        }}</span>
        <span class="subgroup-item-data-number-target">
          / {{ targetPerShift | number }}</span
        >
      </div>
    </div>

    <div class="subgroup-item-data">
      <div class="subgroup-item-data-heading">
        {{ 'SUBGROUP-ITEM.DAY' | translate }}:
      </div>
      <div class="subgroup-item-data-number">
        <span class="subgroup-item-data-number-producedPieces-day">{{
          producedPiecesDay | number
        }}</span>
        <span class="subgroup-item-data-number-target">
          / {{ targetPerDay | number }}</span
        >
      </div>
    </div>
  </div>

  <div>
    <app-group-machine-list
      [subGroupItem]="subGroupItem"
      [groupId]="groupId"
      [departmentId]="departmentId"
    ></app-group-machine-list>
  </div>
</div>
