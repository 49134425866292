import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { UserGroup } from 'src/app/enums/user-group.enum';
import { ConfigService } from 'projects/shiftleader-dashboard/src/app/services/config.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  isRealtimeDataGroup: boolean;
  isEditKPIGroup: boolean;
  isViewKPIGroup: boolean;
  isEasyToolLifeGroup: boolean;
  category: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private configService: ConfigService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.authService.isPlantIdSet()) {
      await this.authService.userAuthInit();
    }

    if (this.router.url.includes('shiftleader-dashboard')) {
      this.configService.getConfig();
    }

    const isDevGroup =
      this.isUserInGroup(UserGroup.AppSmsfDevAll) &&
      environment.env === 'development';

    if (isDevGroup) {
      this.isRealtimeDataGroup = true;
      this.isEditKPIGroup = true;
      this.isViewKPIGroup = true;
      this.isEasyToolLifeGroup = true;
    } else {
      this.isRealtimeDataGroup = this.isUserInGroup(UserGroup.AppSmsfLive);
      this.isEditKPIGroup = this.isUserInGroup(UserGroup.AppSmsfEditKPI);
      this.isViewKPIGroup = this.isUserInGroup(UserGroup.AppSmsfViewKPI);
      this.isEasyToolLifeGroup = this.isUserInGroup(
        UserGroup.AppSmsfProdEasyToolLife,
      );
    }
  }

  private isUserInGroup(group: string): boolean {
    /* istanbul ignore next */
    return this.authService.user?.groups?.includes(group);
  }

  /* istanbul ignore next */
  setSidebarCategory(category): void {
    this.category = category;
  }

  isOperatorCockpit(): boolean {
    return this.router.url.includes('operator-cockpit');
  }
}
