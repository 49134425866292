<div class="auth-form-wrapper">
  <div class="auth-form-content">
    <div class="auth-form-heading">
      <img src="assets/icons/kamax-logo.svg" />
      <p class="auth-form-title">{{ 'LOGIN.TEXT' | translate }}</p>
    </div>

    <form class="auth-form" #form="ngForm" (ngSubmit)="signIn()">
      <div class="auth-form-field">
        <label class="auth-form-label">{{ 'LOGIN.EMAIL' | translate }}</label>
        <input
          #mail="ngModel"
          [ngClass]="{ 'invalid-input': form.submitted && mail.invalid }"
          class="auth-form-input"
          type="email"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="email"
          placeholder="{{ 'LOGIN.EMAIL-PLACEHOLDER' | translate }}"
          required
        />
        <div *ngIf="form.submitted && mail.invalid">
          <div class="error-text" *ngIf="mail.errors?.required">
            {{ 'LOGIN.REQUIRED-FIELD' | translate }}
          </div>
        </div>
      </div>

      <div class="auth-form-field">
        <label class="auth-form-label">{{
          'LOGIN.PASSWORD' | translate
        }}</label>
        <input
          #userPassword="ngModel"
          [ngClass]="{
            'invalid-input': form.submitted && userPassword.invalid
          }"
          class="auth-form-input"
          type="password"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="password"
          placeholder="{{ 'LOGIN.PASSWORD-PLACEHOLDER' | translate }}"
          required
        />
        <div *ngIf="form.submitted && userPassword.invalid">
          <div class="error-text" *ngIf="userPassword.errors?.required">
            {{ 'LOGIN.REQUIRED-FIELD' | translate }}
          </div>
        </div>
      </div>

      <div class="auth-form-field">
        <div class="auth-form-label">{{ 'LOGIN.LANGUAGE' | translate }}</div>
        <app-language-selector
          class="language-selector-border"
        ></app-language-selector>
      </div>

      <button type=" submit" class="auth-form-button" [disabled]="!password">
        {{ 'LOGIN.SIGN-IN' | translate }}
      </button>
      <div class="auth-footer-container">
        <span class="auth-footer-text">
          {{ 'LOGIN.INFO' | translate }}
          <a
            href="mailto:support@smartshopfloor.nexineer.io"
            class="email-link"
          >
            support@smartshopfloor.nexineer.io
          </a>
        </span>
      </div>
    </form>
    <div class="login-preloader" *ngIf="loaderService.isLoading | async">
      <app-loader></app-loader>
    </div>
  </div>
</div>
