import { Component, Input, OnInit } from '@angular/core';
import { ProducedPiecesService } from '../../../../../../services/produced-pieces.service';

@Component({
  selector: 'app-subgroup-item',
  templateUrl: './subgroup-item.component.html',
  styleUrls: ['./subgroup-item.component.scss'],
})
export class SubgroupItemComponent implements OnInit {
  @Input() subGroupItem;
  @Input() groupId;
  @Input() departmentId;

  currentShift = '';
  targetPerShift = '';
  targetPerDay = '';

  producedPiecesShift: '';
  producedPiecesDay: '';

  constructor(private producedPiecesService: ProducedPiecesService) {}

  ngOnInit(): void {
    this.producedPiecesService.fetchProducedPeacesLiveData({
      category: 'Subgroup',
      value: this.subGroupItem?.subgroupName,
    });
    this.producedPiecesService.producedPiecesSubject.subscribe(
      (producedPieces: any) => {
        this.getSubgroupTargets(producedPieces);
      },
    );
  }

  getSubgroupTargets(producedPieces: any): void {
    if (producedPieces && this.subGroupItem) {
      this.currentShift = producedPieces?.currentShift;
      if (
        this.subGroupItem?.subgroupName in
          producedPieces?.producedPiecesShift &&
        this.subGroupItem?.subgroupName in producedPieces?.producedPiecesDay
      ) {
        this.producedPiecesShift =
          producedPieces?.producedPiecesShift[
            this.subGroupItem?.subgroupName
          ]?.totalCount;
        this.producedPiecesDay =
          producedPieces?.producedPiecesDay[
            this.subGroupItem?.subgroupName
          ]?.totalCount;
      }

      this.targetPerDay =
        producedPieces?.targetsPerDay[this.subGroupItem?.subgroupName];
      this.targetPerShift =
        producedPieces?.targetsPerShift[this.subGroupItem?.subgroupName];
    }
  }
}
