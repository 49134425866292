<div class="modal-wrapper">
  <div class="modal-header">
    <div class="title-wrapper">
      <mat-icon aria-hidden="false" [ngStyle]="getStatusIconColor()">{{
        iconName
      }}</mat-icon>

      <div class="title">
        {{ 'GROUP-STATUS-BAR.' + machineStatus.toUpperCase() | translate }}
      </div>
    </div>
    <div>
      <mat-icon aria-hidden="false" class="close-button" (click)="closeModal()"
        >cancel</mat-icon
      >
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <div
      *ngFor="let machine of selectedGroupStatus"
      class="machine-status-wrapper"
      [ngStyle]="getStatusColor()"
    >
      <div class="single-machine-wrapper">
        <div class="label">{{ 'STATUS_BAR.MACHINE' | translate }}</div>
        <div class="content">
          {{ machine.workCenterName }}({{ machine.id }})
        </div>
      </div>

      <div class="single-machine-wrapper">
        <div class="label">{{ 'STATUS_BAR.SUBGROUP' | translate }}</div>
        <div class="content">{{ machine.workCenterGroup }}</div>
      </div>

      <div class="single-machine-wrapper">
        <div class="label">{{ 'STATUS_BAR.MATERIAL_NO' | translate }}</div>
        <div class="content">{{ machine.materialNumber }}</div>
      </div>
      <div
        *ngIf="machineStatus !== 'PRODUCTION'"
        class="single-machine-wrapper"
      >
        <div class="label">{{ 'STATUS_BAR.STATUS_CODE' | translate }}</div>
        <div class="content">
          {{ 'MACHINE_STATUS_CODE.' + (machine?.statusCode || 0) | translate }}
        </div>
      </div>
      <div
        *ngIf="machineStatus === 'PRODUCTION'"
        class="single-machine-wrapper"
      >
        <div class="label">{{ 'STATUS_BAR.CYCLE_RATE' | translate }}</div>
        <div class="content">
          {{ calculateCycleRate(machine) }}
        </div>
      </div>

      <a
        class="link-class"
        (click)="
          redirectToMachine(
            machine.id,
            machine.subgroupName,
            machine.workCenterName
          )
        "
      >
        <mat-icon aria-hidden="false" class="arrow-icon"
          >keyboard_arrow_right</mat-icon
        ></a
      >
    </div>
  </mat-dialog-content>
</div>
