import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { KpiResolver } from '../model/historical-data.model';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class KpiResolverService implements Resolve<KpiResolver> {
  constructor(private translate: TranslateService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): KpiResolver {
    const groupId = route?.params?.groupId;
    const subgroupName = route?.params?.subgroupName;
    const machineName = route?.params?.machineName;
    const reportName = route?.params?.reportName;

    return {
      kpis: this.translate.instant('SIDEBAR.HISTORICAL-DATA'),
      kpiOverview: this.translate.instant('SIDEBAR.KPI-OVERVIEW'),
      department: this.translate.instant('HEADER.DEPARTMENT'),
      groupId: `${this.translate.instant('HEADER.GROUP')} ${groupId}`,
      subgroupName: `${this.translate.instant(
        'HEADER.SUBGROUP',
      )} ${subgroupName}`,
      machineName: `${this.translate.instant('HEADER.MACHINE')} ${machineName}`,
      reportName: this.translate.instant(`HEADER.${reportName?.toUpperCase()}`),
      manualReports: this.translate.instant('SIDEBAR.MANUAL-REPORT'),
    };
  }
}
