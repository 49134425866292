<div class="heading">{{ 'DROPDOWN-SELECT.TEXT' | translate }}</div>
<mat-expansion-panel class="expansion-panel" #mep="matExpansionPanel">
  <mat-expansion-panel-header>
    <mat-panel-title>
      {{
        selectedDropdownValue
          ? selectedDropdownValue
          : ('HEADER.DEPARTMENT' | translate) + ' ' + departmentId
      }}
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div
    class="link-wrapper-group"
    [routerLink]="reportName? ['/shiftleader-dashboard/historical-data/reports/department',
    'details',
    reportName] :
    ['/shiftleader-dashboard/historical-data/reports/department']"
    (click)="mep.expanded = false"
  >
    <a class="link link-heading"
      >{{ 'HEADER.DEPARTMENT' | translate }} {{ departmentId }}</a
    >
  </div>
  <div *ngIf="groupList">
    <div *ngFor="let group of groupList">
      <div
        class="link-wrapper-group"
        (click)="
          mep.expanded = false; setSelectedHeading('Subgroup ' + group?.groupId)
        "
        [routerLink]="reportName? ['/shiftleader-dashboard/historical-data/reports/group/',
          group.groupId,
          'details',
          reportName] :
          ['/shiftleader-dashboard/historical-data/reports/group/', group.groupId]"
      >
        <a class="link link-heading">
          {{ 'HEADER.GROUP' | translate }} {{ group?.groupId }}
        </a>
      </div>
      <div *ngFor="let subgroup of group?.subgroups">
        <div
          class="link-wrapper-subgroup"
          (click)="
            mep.expanded = false;
            setSelectedHeading('Subgroup ' + subgroup?.subgroupName)
          "
          [routerLink]="reportName? ['/shiftleader-dashboard/historical-data/reports/subgroup/',
            subgroup.subgroupName,
            'details',
            reportName] :
            ['/shiftleader-dashboard/historical-data/reports/subgroup/',
            subgroup.subgroupName]"
        >
          <a class="link link-heading">
            {{ 'HEADER.SUBGROUP' | translate }} {{ subgroup?.subgroupName }}
          </a>
        </div>
        <div
          *ngIf="isMachineListVisible(subgroup?.subgroupName, group?.groupId)"
        >
          <div
            class="link-wrapper-subgroup"
            *ngFor="let machine of subgroup?.machines"
            [routerLink]="[
              '/shiftleader-dashboard/historical-data/reports/machine',
              machine.id,
              'name',
              machine.workCenterName,
              'details',
              reportName
            ]"
            (click)="
              mep.expanded = false; setSelectedHeading('Machine ' + machine?.id)
            "
          >
            <a class="link">
              {{ 'HEADER.MACHINE' | translate }}
              {{ machine?.workCenterName }} ({{ machine?.id }})</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-expansion-panel>
