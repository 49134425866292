<mat-expansion-panel
  #mep="matExpansionPanel"
  class="expansion-panel"
  [ngClass]="isSubmitted ? 'submitted' : 'not-submitted'"
>
  <mat-expansion-panel-header>
    <div class="expansion-panel-heading">
      <div class="expansion-panel-heading-item">
        <mat-icon class="icon-submitted" *ngIf="isSubmitted"
          >check_circle</mat-icon
        >
        <mat-icon class="icon-not-submitted" *ngIf="!isSubmitted"
          >add_circle</mat-icon
        >
      </div>

      <div class="expansion-panel-heading-item heading">
        {{ groupReport?.groupId || 'CH-' + groupReport?.groupName }}
      </div>

      <div class="expansion-panel-heading-item text">
        <div *ngIf="isSubmitted">
          {{ 'REPORT-FORM.DATA-SAVED' | translate }}
        </div>
        <div *ngIf="!isSubmitted">
          {{ 'REPORT-FORM.DATA-NOT-SAVED' | translate }}
        </div>
      </div>
    </div>
  </mat-expansion-panel-header>

  <form [formGroup]="reportForm" (ngSubmit)="onSubmit()" class="report-form">
    <div class="report-form-item">
      <div class="report-form-heading">
        {{ 'REPORT-FORM.HEADCOUNT' | translate }}
      </div>
      <div class="report-form-text">
        {{ 'REPORT-FORM.ENTER-HEADCOUNT-TEXT' | translate }}
      </div>
      <div class="report-form-input-wrapper">
        <label class="report-form-input-label">{{
          'REPORT-FORM.ENTER-HEADCOUNT' | translate
        }}</label>
        <input
          formControlName="headcountEmployees"
          type="number"
          class="report-form-input"
          placeholder="{{ 'REPORT-FORM.PLACEHOLDER' | translate }}"
        />
      </div>
    </div>

    <div class="report-form-item">
      <div class="report-form-heading">
        {{ 'REPORT-FORM.SAFETY' | translate }}
      </div>
      <div class="report-form-text">
        {{ 'REPORT-FORM.ENTER-SAFETY-TEXT' | translate }}
      </div>
      <div class="report-form-inputs-wrapper">
        <div class="report-form-input-wrapper">
          <label class="report-form-input-label"
            >{{ 'REPORT-FORM.CATEGORY' | translate }} 0
          </label>
          <input
            formControlName="safetyCat0"
            type="number"
            class="report-form-input"
            placeholder="{{ 'REPORT-FORM.PLACEHOLDER' | translate }}"
          />
        </div>

        <div class="report-form-input-wrapper">
          <label class="report-form-input-label"
            >{{ 'REPORT-FORM.CATEGORY' | translate }} 1</label
          >
          <input
            formControlName="safetyCat1"
            type="number"
            class="report-form-input"
            placeholder="{{ 'REPORT-FORM.PLACEHOLDER' | translate }}"
          />
        </div>

        <div class="report-form-input-wrapper">
          <label class="report-form-input-label"
            >{{ 'REPORT-FORM.CATEGORY' | translate }} 2</label
          >
          <input
            formControlName="safetyCat2"
            type="number"
            class="report-form-input"
            placeholder="{{ 'REPORT-FORM.PLACEHOLDER' | translate }}"
          />
        </div>

        <div class="report-form-input-wrapper">
          <label class="report-form-input-label"
            >{{ 'REPORT-FORM.CATEGORY' | translate }} 3</label
          >
          <input
            formControlName="safetyCat3"
            type="number"
            class="report-form-input"
            placeholder="{{ 'REPORT-FORM.PLACEHOLDER' | translate }}"
          />
        </div>
      </div>
    </div>

    <div class="report-form-item">
      <div class="report-form-heading">
        {{ 'REPORT-FORM.ORDER' | translate }}
      </div>
      <div class="report-form-text">
        {{ 'REPORT-FORM.ENTER-ORDER-TEXT' | translate }}
      </div>
      <div class="report-form-input-wrapper">
        <label class="report-form-input-label">{{
          'REPORT-FORM.ENTER-ISSUES' | translate
        }}</label>
        <input
          formControlName="cleanlinessIssues"
          type="number"
          class="report-form-input"
          placeholder="{{ 'REPORT-FORM.PLACEHOLDER' | translate }}"
        />
      </div>
    </div>

    <div class="report-form-button-wrapper">
      <button
        [disabled]="reportForm.invalid || reportForm.pristine"
        class="report-form-button"
      >
        {{
          (isSubmitted ? 'REPORT-FORM.UPDATE' : 'REPORT-FORM.SAVE') | translate
        }}
      </button>
      <div
        class="report-form-button-cancel"
        *ngIf="reportForm.dirty"
        (click)="mep.expanded = false; resetForm()"
      >
        {{ 'REPORT-FORM.CANCEL' | translate }}
      </div>
    </div>
  </form>
</mat-expansion-panel>
