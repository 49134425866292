<div class="subgroup-machine-wrapper">
  <div
    class="subgroup-machine-heading"
    [ngStyle]="{ 'background-color': color }"
  >
    <!--<span>{{ setMachineHeadingText(machine?.statusCategory) }}</span>-->
    <span class="status-text">{{
      'MACHINE_STATUS_CODE.' + (machine?.statusCode || 0) | translate
    }}</span>
    <a
      [routerLink]="['machine', machine.id, 'name', machine?.workCenterName]"
      class="see-details"
      >{{ 'SUBGROUP-ITEM.SEE-DETAILS' | translate }}
      <mat-icon>navigate_next</mat-icon>
    </a>
  </div>

  <div class="subgroup-machine-content-row">
    <div class="subgroup-machine-content">
      <div class="subgroup-machine-item">
        <span class="txt-bold">{{ machine?.workCenterName }}</span>
        <span class="txt-grey">({{ machine?.workCenter }})</span>
      </div>

      <div class="subgroup-machine-item">
        <span class="txt-grey">{{
          'SUBGROUP-MACHINE-ITEM.MATERIAL-NUM' | translate
        }}</span>
        <span class="txt-bold">{{ machine?.materialNumber }}</span>
      </div>
    </div>

    <div class="subgroup-machine-content">
      <div class="subgroup-machine-item">
        <span class="txt-grey">{{
          'SUBGROUP-MACHINE-ITEM.STOP-TIME' | translate
        }}</span>
        <span class="txt-bold" [ngClass]="stopTime ? 'txt-red' : 'txt-bold'">
          {{ stopTime ? (stopTime | date: ['HH:mm']) : '--:--' }}
        </span>
      </div>

      <div class="subgroup-machine-item">
        <span class="txt-grey">{{
          'SUBGROUP-MACHINE-ITEM.DOWNTIME' | translate
        }}</span>
        <span class="txt-bold" [ngClass]="downTime ? 'txt-red' : 'txt-bold'">{{
          downTime ? downTime : '--:--'
        }}</span>
      </div>
    </div>
  </div>
</div>
