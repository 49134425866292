<div class="expansion-panel-list-wrapper">
  <div class="expansion-panel-list-item">
    <div class="expansion-panel-list-heading">
      {{ 'PO.CURRENT_PO' | translate }}
    </div>
    <div *ngIf="currentProductOrders?.length != 0; else elseBlock">
      <app-product-order-expansion-panel
        *ngFor="let activeProductOrder of currentProductOrders"
        [productOrder]="activeProductOrder"
        [selectedProductOrder]="selectedProductOrder"
      >
      </app-product-order-expansion-panel>
    </div>
  </div>

  <div class="expansion-panel-list-item">
    <div class="expansion-panel-list-heading">
      {{ 'PO.FUTURE_PO' | translate }}
    </div>
    <div *ngIf="futureProductOrders.length != 0; else elseBlock">
      <app-product-order-expansion-panel
        *ngFor="let nextProductOrder of futureProductOrders"
        [productOrder]="nextProductOrder"
      ></app-product-order-expansion-panel>
    </div>
  </div>

  <div class="expansion-panel-list-item">
    <div class="expansion-panel-list-heading">
      {{ 'PO.PAST_PO' | translate }}
    </div>
    <div *ngIf="pastProductOrders.length != 0; else elseBlock">
      <app-product-order-expansion-panel
        *ngFor="let pastProductOrder of pastProductOrders"
        [selectedProductOrder]="selectedProductOrder"
        [productOrder]="pastProductOrder"
      ></app-product-order-expansion-panel>
    </div>
  </div>
  <ng-template #elseBlock>
    <app-loader></app-loader>
  </ng-template>
</div>
