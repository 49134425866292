import { Component, Input, OnInit } from '@angular/core';
import { eachDayOfInterval, startOfWeek, subDays } from 'date-fns';

@Component({
  selector: 'app-calendar-view',
  templateUrl: './calendar-view.component.html',
  styleUrls: ['./calendar-view.component.scss'],
})
export class CalendarViewComponent implements OnInit {
  @Input() heading: string;
  @Input() title: string;
  @Input() daysInMonth: any;
  startOfTheMonthOffset: any = [];

  constructor() {}

  ngOnInit(): void {
    if (this.daysInMonth) {
      const startOfTheWeek = startOfWeek(new Date(this.daysInMonth[0].date), {
        weekStartsOn: 1,
      });
      if (
        startOfTheWeek.getDate() !== new Date(this.daysInMonth[0].date).getDate()
      ) {
        this.startOfTheMonthOffset = eachDayOfInterval({
          start: startOfTheWeek,
          end: subDays(new Date(this.daysInMonth[0].date), 1),
        });
      }
    }
  }
}
