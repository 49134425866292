import { MaterialModule } from 'src/app/material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ChartsModule } from 'ng2-charts';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { RealtimeDataRoutingModule } from './realtime-data-routing.module';
import { RealtimeDataComponent } from './realtime-data.component';
import { DepartmentComponent } from './department/department.component';
import { MachineComponent } from './machine/machine.component';
import { OeeChartComponent } from '../../components/oee-chart/oee-chart.component';
import { GroupItemComponent } from '../../components/group-overview/group-list/group-item/group-item.component';
import { GroupListComponent } from '../../components/group-overview/group-list/group-list.component';
import { GroupStatusBarComponent } from '../../components/group-overview/group-status-bar/group-status-bar.component';
import { GroupMachineItemComponent } from '../../components/group-overview/group-list/group-item/subgroup-list/subgroup-item/group-machine-list/group-machine-item/group-machine-item.component';
import { GroupMachineListComponent } from '../../components/group-overview/group-list/group-item/subgroup-list/subgroup-item/group-machine-list/group-machine-list.component';
import { SubgroupItemComponent } from '../../components/group-overview/group-list/group-item/subgroup-list/subgroup-item/subgroup-item.component';
import { SubgroupListComponent } from '../../components/group-overview/group-list/group-item/subgroup-list/subgroup-list.component';
import { ProductionOverviewComponent } from '../../components/production-overview/production-overview.component';
import { ProductionOverviewChartComponent } from '../../components/production-overview/production-overview-chart/production-overview-chart.component';
import { ProductOrderChartListComponent } from './machine/product-order-chart-list/product-order-chart-list.component';
import { GroupOverviewComponent } from '../../components/group-overview/group-overview.component';
import { GroupsComponent } from './groups/groups.component';
import { SubgroupComponent } from './groups/subgroup/subgroup.component';
import { SubgroupMachineListComponent } from './groups/subgroup/subgroup-machine-list/subgroup-machine-list.component';
import { ProductOrderExpansionPanelComponent } from './machine/product-order-expansion-panel/product-order-expansion-panel.component';
import { ProductOrderExpansionPanelListComponent } from './machine/product-order-expansion-panel-list/product-order-expansion-panel-list.component';
import { GroupMachinesStatusChartComponent } from './groups/group-machines-status-chart/group-machines-status-chart.component';
import { ChartHeaderComponent } from '../../components/chart-header/chart-header.component';
import { GroupStatusBarItemComponent } from '../../components/group-overview/group-status-bar/group-status-bar-item/group-status-bar-item.component';
// tslint:disable-next-line:max-line-length
import { SubgroupMachineItemComponent } from './groups/subgroup/subgroup-machine-list/subgroup-machine-item/subgroup-machine-item.component';
import { StatusTimelineComponent } from '../../components/status-timeline/status-timeline.component';
import { TimelineActionsComponent } from '../../components/timeline-actions/timeline-actions.component';
import { ProgressBarComponent } from '../../components/status-timeline/progress-bar/progress-bar.component';
import { TimelineLegendComponent } from '../../components/timeline-legend/timeline-legend.component';
import { StatusColorDirective } from '../../directive/status-color.directive';

@NgModule({
  declarations: [
    RealtimeDataComponent,
    DepartmentComponent,
    MachineComponent,
    OeeChartComponent,
    GroupStatusBarComponent,
    GroupListComponent,
    GroupItemComponent,
    SubgroupListComponent,
    SubgroupItemComponent,
    GroupMachineListComponent,
    GroupMachineItemComponent,
    ProductionOverviewComponent,
    ProductionOverviewChartComponent,
    ProductOrderChartListComponent,
    GroupOverviewComponent,
    GroupsComponent,
    SubgroupComponent,
    SubgroupMachineListComponent,
    SubgroupMachineItemComponent,
    StatusColorDirective,
    ProductOrderExpansionPanelComponent,
    ProductOrderExpansionPanelListComponent,
    GroupMachinesStatusChartComponent,
    ChartHeaderComponent,
    GroupStatusBarItemComponent,
    TimelineActionsComponent,
    StatusTimelineComponent,
    ProgressBarComponent,
    TimelineLegendComponent,
  ],
  imports: [
    CommonModule,
    RealtimeDataRoutingModule,
    ChartsModule,
    MaterialModule,
    SharedModule,
    TranslateModule.forChild({
      extend: true,
    }),
  ],
})
export class RealtimeDataModule {}
