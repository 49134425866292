import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { API } from 'aws-amplify';
import { SharedService } from 'src/app/services/shared.service';
import { DowntimeReasonModalEvent } from 'projects/operator-cockpit/src/app/enums/downtime-reason-modal-event.enum';
import { APIService } from './API.service';
import { DowntimeReasonEvent } from 'projects/operator-cockpit/src/app/model/downtime-reason.model';

@Injectable({
  providedIn: 'root',
})
export class MachinePeriodsService {
  machinePeriodsSubject = new BehaviorSubject<any>([]);
  machinePeriods: Observable<any> = this.machinePeriodsSubject.asObservable();

  constructor(
    @Inject('SMSF_API_NAME') private apiName: string,
    private sharedService: SharedService,
    private graphqlApi: APIService,
  ) {}

  /* istanbul ignore next */
  async getMachinePeriods(queryStringParameters): Promise<object> {
    const plantId = this.sharedService.getPlantId();
    const init = {
      headers: {},
      response: true,
      queryStringParameters: {
        ...queryStringParameters,
        plantId,
      },
    };
    return API.get(this.apiName, `/department/machine-periods`, init);
  }

  /* istanbul ignore next */
  fetchMachinePeriodsLiveData(queryStringParameters: any): void {
    this.getMachinePeriods(queryStringParameters)
      .then((result: any) => {
        if (result?.data && result.data.length) {
          const groupedByWorkCenterName = Object.values(
            result.data.reduce((acc, cur) => {
              acc[cur.workCenterName] = [
                ...(acc[cur.workCenterName] || []),
                cur,
              ];
              return acc;
            }, []),
          );
          this.machinePeriodsSubject.next(groupedByWorkCenterName);
        }
      })
      .catch();
  }

  public async updateWorkCenterPeriod(
    event: DowntimeReasonEvent,
    index: string,
    start: number,
  ): Promise<void> {
    switch (event?.type) {
      case DowntimeReasonModalEvent.Submit:
        const { statusCode, location } = event;
        await this.graphqlApi.UpdateWorkCenterPeriods({
          index,
          start,
          status: statusCode,
          ...(location && { location }),
        });
        break;
      case DowntimeReasonModalEvent.Dismiss:
        await this.graphqlApi.UpdateWorkCenterPeriods({
          index,
          start,
          flag: 1,
        });
        break;
      default:
        throw new Error('Provided action not supported.');
    }
  }
}
