<div *ngIf="loaderService.isLoading | async; else groupListTemplate">
  <app-loader></app-loader>
</div>
<ng-template #groupListTemplate>
  <div *ngFor="let groupItem of groups">
    <app-group-item
      [groupItem]="groupItem"
      [showLabels]="showLabels"
      [departmentId]="departmentId"
    ></app-group-item>
  </div>
</ng-template>
