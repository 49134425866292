import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-group-machine-list',
  templateUrl: './group-machine-list.component.html',
  styleUrls: ['./group-machine-list.component.scss'],
})
export class GroupMachineListComponent implements OnInit {
  @Input() subGroupItem;
  @Input() groupId;
  @Input() departmentId;

  constructor() {}

  ngOnInit(): void {}
}
