import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-placeholder',
  templateUrl: './data-placeholder.component.html',
  styleUrls: ['./data-placeholder.component.scss'],
})
export class DataPlaceholderComponent implements OnInit {
  @Input() placeholderText: string;

  constructor() {}

  ngOnInit(): void {}
}
