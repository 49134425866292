import { Component, Input, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-group-status-bar',
  templateUrl: './group-status-bar.component.html',
  styleUrls: [
    './group-status-bar.component.scss',
    '../../../../../../../src/styles/shared-classes.scss',
  ],
})
export class GroupStatusBarComponent implements OnInit {
  @Input() groupStatus;
  @Input() totalMachines;
  @Input() statusColorMap;

  constructor(public loaderService: LoaderService) {}

  ngOnInit(): void {}
}
