import { Component, OnInit } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ToolLifetimeService } from '../../../services/tool-lifetime.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
      ),
    ]),
  ],
})
export class OverviewComponent implements OnInit {
  toolData = [];
  expandedElement: any;
  columns = [
    {
      columnDef: 'toolId',
      header: this.translate.instant('TOOL_TABLE.TOOL_ID'),
      cell: (tool) => `${tool?.ToolID}`,
    },
    {
      columnDef: 'sapNumber',
      header: this.translate.instant('TOOL_TABLE.SAP_NUMBER'),
      cell: (tool) => `${tool?.Material}`,
    },
    {
      columnDef: 'toolName',
      header: this.translate.instant('TOOL_TABLE.TOOL_NAME'),
      cell: (tool) => `${tool?.MaterialDescription}`,
    },
    {
      columnDef: 'reworked',
      header: this.translate.instant('TOOL_TABLE.REWORKED'),
      cell: (tool) => `${tool?.Reworked}`,
    },
    {
      columnDef: 'refurbished',
      header: this.translate.instant('TOOL_TABLE.REFURBISHED'),
      cell: (tool) => `${tool?.Refurbished}`,
    },
    {
      columnDef: 'firstUsed',
      header: this.translate.instant('TOOL_TABLE.FIRST_USED'),
      cell: (tool) => `${tool?.FirstUsed}`,
    },
    {
      columnDef: 'prodOrder',
      header: this.translate.instant('TOOL_TABLE.PROD_ORDER'),
      cell: (tool) =>
        tool?.ProductionOrders?.length > 1
          ? `${tool?.ProductionOrders[0]?.ProductionOrder} ...`
          : `${tool?.ProductionOrders[0]?.ProductionOrder}`,
    },
    {
      columnDef: 'h9Number',
      header: this.translate.instant('TOOL_TABLE.H9_NUMBER'),
      cell: (tool) => `${tool?.ProductionOrders[0]?.Material}`,
    },
    {
      columnDef: 'machine',
      header: this.translate.instant('TOOL_TABLE.MACHINE'),
      cell: (tool) => `${tool?.ProductionOrders[0]?.WorkCenter}`,
    },
    {
      columnDef: 'punchesDone',
      header: this.translate.instant('TOOL_TABLE.PUNCHES_DONE'),
      cell: (tool) => `${tool?.Punches}`,
    },
    {
      columnDef: 'toolStatus',
      header: this.translate.instant('TOOL_TABLE.TOOL_STATUS'),
      cell: (tool) => `${tool?.Status}`,
    },
  ];
  displayedColumns = this.columns.map((column) => column.columnDef);

  constructor(
    private toolLifetimeService: ToolLifetimeService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.toolLifetimeService.getToolReport().subscribe((toolReport) => {
      this.toolData = toolReport?.data?.payload;
    });
  }
}
