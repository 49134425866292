<div class="main-container">
  <app-production-overview></app-production-overview>
  <div class="group-container">
    <app-group-overview
      [groups]="groups"
      [groupStatus]="groupStatus"
      [totalMachines]="totalMachines"
      [departmentId]="departmentId"
      [statusColorMap]="statusColorMap"
    ></app-group-overview>
  </div>
</div>
