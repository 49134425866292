<div class="legend-wrapper">
  <div *ngFor="let status of allStatuses | keyvalue: keyValueOrder">
    <div class="legend-node-wrapper">
      <div class="dot" [ngStyle]="{ 'background-color': status?.value }"></div>
      <div>{{ 'GROUP-STATUS-BAR.' + status.key | translate }}</div>
    </div>
  </div>
</div>

<div class="legend-wrapper" *ngIf="isMachineLevel">
  <div *ngFor="let period of periods | keyvalue: keyValueOrder">
    <div class="legend-node-wrapper">
      <div class="dot" [ngStyle]="{ 'background-color': period?.value }"></div>
      <div>{{ 'PRODUCT-ORDER.' + period.key | translate }}</div>
    </div>
  </div>
</div>
