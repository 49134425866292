<router-outlet></router-outlet>

<div *ngIf="!router.url.includes('subgroup')" class="main-container">
  <!-- <app-oee-chart></app-oee-chart> -->
  <app-production-overview></app-production-overview>
  <div>
    <app-group-overview
      [showLabels]="showLabels"
      [groups]="[selectedGroup]"
      [groupStatus]="selectedGroupStatus"
      [totalMachines]="totalMachines"
      [departmentId]="departmentId"
      [statusColorMap]="statusColorMap"
    >
    </app-group-overview>
  </div>
</div>
