<div *ngIf="!router.url.includes('details')">
  <div
    *ngIf="loaderService.isLoading | async; else elseBlock"
    class="loader-wrapper"
  >
    <app-loader></app-loader>
  </div>
  <ng-template #elseBlock>
    <div class="sub-kpi-wrapper">
      <div *ngFor="let day of selectedDayData | keyvalue: keyValueOrder">
        <div *ngIf="day?.key != 'date'">
          <app-single-day-preview
            [data]="day?.value"
            [title]="day.key != 'date' ? day.key : ''"
          ></app-single-day-preview>
        </div>
      </div>
    </div>
    <div class="calendar-label">
      <div class="calendar-label-text">
        {{ 'CALENDAR.CALENDAR_LABEL' | translate }}
      </div>
      <div>
        <mat-button-toggle-group [(ngModel)]="isCircleCalendar">
          <mat-button-toggle [value]="true">
            <mat-icon aria-hidden="false">desktop_windows</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle [value]="false">
            <mat-icon aria-hidden="false">phone_android</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div>
      <div *ngFor="let singleDay of kpiNames">
        <div *ngIf="isCircleCalendar">
          <app-letter-calendar
            [heading]="singleDay"
            [title]="singleDay"
            [daysInMonth]="daysInMonth"
          ></app-letter-calendar>
        </div>
        <div *ngIf="!isCircleCalendar">
          <app-calendar-view
            [heading]="singleDay"
            [title]="singleDay"
            [daysInMonth]="daysInMonth"
          ></app-calendar-view>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<router-outlet></router-outlet>
