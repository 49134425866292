import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  differenceInMilliseconds,
  format,
  isAfter,
  isBefore,
  subHours,
  isSameSecond,
  isWithinInterval,
  isSameMinute,
} from 'date-fns';
import { addHours } from 'date-fns';
import { MachinePeriodsService } from 'src/app/services/machine-periods.service';
import { ProductOrderService } from '../../services/product-order.service';
import { ConfigService } from '../../services/config.service';
import { ProducedPiecesService } from '../../services/produced-pieces.service';

@Component({
  selector: 'app-status-timeline',
  templateUrl: './status-timeline.component.html',
  styleUrls: ['./status-timeline.component.scss'],
})
export class StatusTimelineComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selected;

  hourList = [];
  hourNow = format(new Date(), 'HH:mm');
  calculatedMachinePeriods = [];
  machinePeriods;
  productOrders = [];
  calculatedProductOrderPeriods = [];
  productOrderConfig: object;
  refreshInterval;
  isLoaded = false;
  isLoadingPeriods = true;
  previousSelection;
  selectedDateTime = new Date();
  currentDateTime = format(this.selectedDateTime, 'HH:mm');
  currentTimeHeight = 0;
  isCurrentTimeOnTimeline = false;
  timeOffset = 0;
  readonly timelineRange = 12;

  lastEndDate;
  dayPeriod = {
    start: new Date(new Date(subHours(new Date(), this.timelineRange))),
    end: new Date(),
    difference: differenceInMilliseconds(
      new Date(),
      new Date(new Date(subHours(new Date(), this.timelineRange))),
    ),
  };

  shiftStartTime: Date;
  currentShift: number;
  shifts = [];
  firstShiftHourOffset = new Array(0);
  firstShiftMinuteOffset = new Array(0);
  secondShiftHourOffset = new Array(8);

  private machineTimelineWrapper: ElementRef;

  @ViewChild('machineTimelineWrapper', { static: false }) set machineWrapper(
    content: ElementRef,
  ) {
    setTimeout(() => {
      if (content) {
        this.machineTimelineWrapper = content;
        if (!this.isSingleMachineSelected()) {
          this.calculateCurrentTimeLineHeight();
        }
      }
    }, 0);
  }
  private productOrderTimelineWrapper: ElementRef;

  @ViewChild('productOrderTimelineWrapper', { static: false })
  set productOrderWrapper(content: ElementRef) {
    setTimeout(() => {
      if (content) {
        this.productOrderTimelineWrapper = content;
        this.calculateCurrentTimeLineHeight();
      }
    }, 0);
  }

  constructor(
    private machinePeriodsService: MachinePeriodsService,
    private productOrderService: ProductOrderService,
    private configService: ConfigService,
    private producedPiecesService: ProducedPiecesService,
  ) {}

  ngOnInit(): void {
    this.machinePeriodsService.machinePeriods.subscribe((machinePeriods) => {
      this.machinePeriods = machinePeriods;
      this.calculateMachinePeriods();
      this.waitForData();
    });

    this.producedPiecesService.producedPiecesSubject.subscribe(
      (producedPieces) => {
        if (
          producedPieces &&
          producedPieces.shiftStart !== this.shiftStartTime
        ) {
          this.shiftStartTime = new Date(producedPieces?.shiftStart);
          this.currentShift = producedPieces?.currentShift;
          this.calculateTimelineShiftOffsets();
        }
      },
    );

    this.configService.configData.subscribe((response) => {
      this.productOrderConfig = response?.productOrder;
    });
  }

  ngOnDestroy(): void {
    clearInterval(this.refreshInterval);
    this.selected = {};
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    clearInterval(this.refreshInterval);
    this.refreshMachinePeriods();
    if (this.selected?.machineId) {
      await this.setProductOrderPeriods();
      this.calculateCurrentTimeLineHeight();
    }
    this.waitForData();
    this.refreshInterval = setInterval(() => {
      this.refreshMachinePeriods();
    }, 10000);
  }

  async changeNavigationHandler(event): Promise<void> {
    this.isLoadingPeriods = true;

    switch (event?.moveDirection) {
      case 'forward':
        this.timeOffset = this.timeOffset + parseInt(event?.selectedHours, 10);
        break;
      case 'backward':
        this.timeOffset = this.timeOffset - parseInt(event?.selectedHours, 10);
        break;
      case 'reset':
        this.timeOffset = 0;
        break;
      default:
        break;
    }
    this.calculateTimelineShiftOffsets();
    this.refreshMachinePeriods();
    if (this.isSingleMachineSelected()) {
      await this.setProductOrderPeriods();
    }
  }

  private isSingleMachineSelected(): boolean {
    return !!this.selected?.machineId;
  }

  waitForData(): void {
    if (this.selected) {
      const currentSelection = this.selected.subgroupName
        ? this.selected.subgroupName
        : this.selected.machineId;
      if (currentSelection !== this.previousSelection) {
        this.isLoaded = false;
        this.previousSelection = currentSelection;
      } else {
        this.isLoaded = true;
        this.isLoadingPeriods = false;
      }
    }
  }

  private refreshMachinePeriods(): void {
    this.dayPeriod = {
      start: new Date(
        new Date(subHours(this.selectedDateTime, this.timelineRange)),
      ),
      end: this.selectedDateTime,
      difference: differenceInMilliseconds(
        this.selectedDateTime,
        new Date(new Date(subHours(this.selectedDateTime, this.timelineRange))),
      ),
    };

    this.generateHourSegments();
    const { date, startTime } = this.getStartTime();

    if (this.selected) {
      const params = this.selected.subgroupName
        ? {
            category: 'Subgroup',
            value: this.selected.subgroupName,
            date,
            startTime,
          }
        : {
            category: 'WorkCenter',
            value: this.selected.machineId,
            date,
            startTime,
          };
      this.machinePeriodsService.fetchMachinePeriodsLiveData(params);
    }
    this.calculateMachinePeriods();
  }

  private getStartTime(): any {
    return {
      date: format(
        subHours(this.selectedDateTime, this.timelineRange),
        'yyyy-MM-dd',
      ),
      startTime: format(
        subHours(this.selectedDateTime, this.timelineRange),
        'HH:mm:ss',
      ),
    };
  }

  private async setProductOrderPeriods(): Promise<void> {
    if (this.selected) {
      try {
        const { date, startTime } = this.getStartTime();
        const { supportsMultipleOrders } = this.selected;

        const { data }: any = await this.productOrderService.getProductOrders({
          workCenter: this.selected.machineId,
          startTime,
          date,
        });

        this.productOrders = Object.entries(data).flatMap(
          ([entryKey, entryValue]: any) => {
            return entryValue
              .filter((entryArray) =>
                supportsMultipleOrders
                  ? entryKey === 'past' || entryKey === 'current'
                  : entryArray,
              )
              .map(
                ({
                  orderNumber: order,
                  statusTimestampMin,
                  statusTimestampMax,
                  materialNumber,
                  pressFamily,
                }) => ({
                  orderNumber: order,
                  start: statusTimestampMin,
                  end: statusTimestampMax,
                  color: this.mapProductOrderColor(entryKey),
                  materialNumber,
                  pressFamily,
                }),
              );
          },
        );

        this.calculatedProductOrderPeriods = supportsMultipleOrders
          ? this.productOrders.map((productOrder) =>
              this.calculatePeriodsWithOffsets([productOrder]),
            )
          : [this.calculatePeriodsWithOffsets(this.productOrders)];
      } catch {}
    }
  }

  private mapProductOrderColor(periodOfTime): string {
    const { PAST, PRESENT, FUTURE }: any = this.productOrderConfig;

    switch (periodOfTime) {
      case 'past':
        return PAST?.color;
      case 'current':
        return PRESENT?.color;
      case 'planned':
        return FUTURE?.color;
      default:
        throw new Error('No match for product orders property has been found.');
    }
  }

  calculateMachinePeriods(): void {
    this.generateHourSegments();
    if (this.machinePeriods && this.selected?.machines?.length) {
      this.calculatedMachinePeriods = this.selected.machines
        // populate machine list with all the machines for selected subgroup
        .map((machine) => [{ workCenterName: machine.workCenterName }])
        // find data for every machine in the machine list and merge it together
        .map((machine) => {
          return this.machinePeriods.reduce((acc, cur) => {
            const machinePeriod =
              cur[0]?.workCenterName === machine[0]?.workCenterName
                ? cur
                : machine;
            acc = [...acc, machinePeriod];
            return acc.flat(2);
          }, []);
        })
        .map((machine) => {
          return this.calculatePeriodsWithOffsets(machine);
        });
    }
  }

  private calculatePeriodsWithOffsets(periods): Array<any> {
    const startTwelveHoursBefore = subHours(
      this.selectedDateTime,
      this.timelineRange,
    );

    const calculatedPeriods = periods
      // filter out periods before 12 hours of selected time
      .filter((period) =>
        isAfter(new Date(period?.end), startTwelveHoursBefore),
      )
      // exclude future periods from this moment
      .filter((period) =>
        isBefore(new Date(period?.start), this.selectedDateTime),
      )
      .map((period) => {
        const startOffset = new Date(period?.start).getTime();
        const endOffset = new Date(period?.end).getTime();

        // check if first period starts before 12 hours from now or from selected time
        if (isBefore(new Date(period?.start), startTwelveHoursBefore)) {
          return {
            ...period,
            startOffset: startTwelveHoursBefore,
            endOffset,
          };
          // check if last period lasts longer than current/selected date
        } else if (isAfter(new Date(period?.end), this.selectedDateTime)) {
          return {
            ...period,
            startOffset,
            endOffset: new Date(this.selectedDateTime).getTime(),
          };
        } else {
          return {
            ...period,
            startOffset,
            endOffset,
          };
        }
      });

    // add starting empty period if there is no data between 12 hours from now start and first period's start
    const [firstPeriod]: [any] = periods;
    if (
      isAfter(
        new Date(firstPeriod?.start),
        subHours(this.selectedDateTime, this.timelineRange),
      )
    ) {
      calculatedPeriods.unshift({
        startOffset: startTwelveHoursBefore,
        endOffset: new Date(firstPeriod?.start).getTime(),
        statusCategory: 'NODATA',
      });
    }

    // add empty period in case there is no data at the moment
    const lastPeriod = calculatedPeriods.slice(-1)[0];
    if (isBefore(new Date(lastPeriod?.end), this.selectedDateTime)) {
      calculatedPeriods.push({
        startOffset: new Date(lastPeriod?.end).getTime(),
        endOffset: this.selectedDateTime.getTime(),
        statusCategory: 'NODATA',
      });
    }

    const calculatedPeriodsWithOffsets = [];

    for (let i = 0; i < calculatedPeriods.length; i++) {
      calculatedPeriodsWithOffsets.push(calculatedPeriods[i]);
      if (
        i < calculatedPeriods.length - 1 &&
        !isSameSecond(
          new Date(calculatedPeriods[i].end),
          new Date(calculatedPeriods[i + 1].start),
        )
      ) {
        calculatedPeriodsWithOffsets.push({
          startOffset: new Date(calculatedPeriods[i].end).getTime(),
          endOffset: new Date(calculatedPeriods[i + 1].start).getTime(),
          statusCategory: 'NODATA',
        });
      }
    }
    return calculatedPeriodsWithOffsets;
  }

  generateHourSegments(): void {
    const newHours = [];
    this.selectedDateTime = new Date();
    this.currentDateTime = format(this.selectedDateTime, 'HH:mm');
    this.selectedDateTime.setHours(
      this.selectedDateTime.getHours() + this.timeOffset,
    );
    this.isCurrentTimeOnTimeline =
      this.timeOffset >= 0 && this.timeOffset <= this.timelineRange;
    const now = format(this.selectedDateTime, 'HH:mm');
    for (let i = this.timelineRange; i > 0; i--) {
      newHours.push(format(subHours(this.selectedDateTime, i), 'HH:mm'));
    }
    this.calculateTimelineShiftOffsets();
    this.hourList = newHours;
    this.hourNow = now;
  }

  private calculateCurrentTimeLineHeight(): void {
    const machineWrapperHeight = this.machineTimelineWrapper?.nativeElement
      ?.offsetHeight;

    if (
      this.machineTimelineWrapper &&
      this.currentTimeHeight !== machineWrapperHeight
    ) {
      this.currentTimeHeight = machineWrapperHeight;

      if (this.productOrderTimelineWrapper) {
        this.currentTimeHeight += this.productOrderTimelineWrapper.nativeElement.offsetHeight;
      }
    }
  }

  private calculateTimelineShiftOffsets(): void {
    this.shifts = [];
    this.firstShiftHourOffset = new Array(0);
    this.firstShiftMinuteOffset = new Array(0);
    let selectedDateTime = this.selectedDateTime;
    if (!selectedDateTime) {
      selectedDateTime = new Date();
      selectedDateTime.setHours(selectedDateTime.getHours() + this.timeOffset);
    }
    let shiftStartOffset = 0;

    const firstShift = this.calculateTimelineShifts(selectedDateTime);

    for (let i = this.timelineRange; i > 0; i--) {
      const subbedTime = subHours(selectedDateTime, i);
      if (isAfter(firstShift, subbedTime)) {
        shiftStartOffset++;
      } else {
        this.firstShiftHourOffset = new Array(
          shiftStartOffset ? shiftStartOffset - 1 : 0,
        );
      }
    }
    if (firstShift) {
      this.firstShiftMinuteOffset = new Array(
        60 - selectedDateTime.getMinutes() + firstShift.getMinutes(),
      );
    }
  }

  private calculateTimelineShifts(selectedDateTime: Date): Date {
    let firstShift: Date;
    let currentShiftTime = this.shiftStartTime;
    let currentShift = this.currentShift;

    const startTime = subHours(selectedDateTime, 12);
    if (this.isDateInInterval(currentShiftTime, startTime, selectedDateTime)) {
      // current shift is on timeline
      const beforeShift = subHours(currentShiftTime, 8);
      if (this.isDateInInterval(beforeShift, startTime, selectedDateTime)) {
        firstShift = beforeShift;
        this.shifts.push(
          currentShift === 1 ? 3 : currentShift - 1,
          currentShift,
        );
      } else {
        const afterShift = addHours(currentShiftTime, 8);
        firstShift = currentShiftTime;
        this.shifts.push(currentShift);
        if (this.isDateInInterval(afterShift, startTime, selectedDateTime)) {
          this.shifts.push(currentShift === 3 ? 1 : currentShift + 1);
        }
      }
    } else if (isBefore(currentShiftTime, startTime)) {
      // current shift is in the past, go to the future
      while (!firstShift && isBefore(currentShiftTime, selectedDateTime)) {
        currentShiftTime = addHours(currentShiftTime, 8);
        currentShift = currentShift === 3 ? 1 : currentShift + 1;
        if (
          this.isDateInInterval(currentShiftTime, startTime, selectedDateTime)
        ) {
          this.shifts.push(currentShift);
          firstShift = currentShiftTime;
          const potentialSecondShift = addHours(currentShiftTime, 8);
          if (
            this.isDateInInterval(
              potentialSecondShift,
              startTime,
              selectedDateTime,
            )
          ) {
            this.shifts.push(currentShift === 3 ? 1 : currentShift + 1);
          }
        }
      }
    } else {
      // current shift is in the future, go to the past
      while (!firstShift && isAfter(currentShiftTime, startTime)) {
        currentShiftTime = subHours(currentShiftTime, 8);
        currentShift = currentShift === 1 ? 3 : currentShift - 1;
        if (
          this.isDateInInterval(currentShiftTime, startTime, selectedDateTime)
        ) {
          const potentialFirstShift = subHours(currentShiftTime, 8);
          if (
            this.isDateInInterval(
              potentialFirstShift,
              startTime,
              selectedDateTime,
            )
          ) {
            firstShift = potentialFirstShift;
            this.shifts.push(currentShift === 1 ? 3 : currentShift - 1);
          }
          if (!firstShift) {
            firstShift = currentShiftTime;
          }
          this.shifts.push(currentShift);
        }
      }
    }
    return firstShift;
  }

  private isDateInInterval(date: Date, first: Date, second: Date): boolean {
    return (
      isWithinInterval(date, { start: first, end: second }) ||
      isSameMinute(date, first) ||
      isSameMinute(date, second)
    );
  }

  scrollToPOdetails(orderNumber?: number): void {
    if (orderNumber) {
      this.productOrderService.setSelectedProductionOrder(orderNumber);
    }

    const topPosition = document.getElementById('POdetails').offsetTop;
    document.getElementById('outer-machine-wrapper').scrollTop =
      topPosition - 90;
  }
}
