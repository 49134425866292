import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

export class CustomUrlSerializer implements UrlSerializer {
  private defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

  parse(url: string): UrlTree {
    url = encodeURI(url);
    return this.defaultUrlSerializer.parse(url);
  }

  serialize(tree: UrlTree): string {
    return decodeURI(this.defaultUrlSerializer.serialize(tree));
  }
}
