<!--<app-report-details-category-bar [reportType]="reportType" [data]="data?.total">-->
<!--</app-report-details-category-bar>-->
<div *ngIf="loaderService.isLoading | async; else elseBlock">
  <app-loader></app-loader>
</div>
<ng-template #elseBlock>
  <div *ngFor="let report of data; let reportIndex = index">
    <div *ngIf="report.description !== 'OEE in %'">
      <div class="report-details-item-chart-heading">
        {{ 'REPORT-DETAILS.' + report.description | translate }}
      </div>
      <div class="report-details-item-chart-wrapper">
        <div>
          <div class="target-box target-box-year">
            <div>{{ 'REPORT-DETAILS.YEAR-TARGET' | translate }}</div>
            <div>
              {{
                report.yearlyMarginValue
                  ? (report.yearlyMarginValue | number: '1.2-2')
                  : 0
              }}
              {{ report.description.includes('%') ? '%' : '' }}
            </div>
          </div>
          <app-report-details-stacked-chart
            [isGraphTypeStacked]="report.isGraphTypeStacked"
            [comparisonLogic]="getComparisonLogic()"
            [isStackedBar]="isStackedBar(reportIndex)"
            chartHeading="{{ 'REPORT-DETAILS.YEAR' | translate }}"
            [marginalValue]="report.yearlyMarginValue"
            [barChartData]="report.yearlyData"
            [chartLabels]="chartMonthLabels"
            [isError]="isYearlyError"
            class="report-details-chart-item"
          >
          </app-report-details-stacked-chart>
        </div>
        <div>
          <div class="target-box target-box-month">
            <div>{{ 'REPORT-DETAILS.MONTH-TARGET' | translate }}</div>
            <div>
              {{
                report.monthlyMarginValue
                  ? (report.monthlyMarginValue | number: '1.2-2')
                  : 0
              }}
              {{ report.description.includes('%') ? '%' : '' }}
            </div>
          </div>
          <app-report-details-stacked-chart
            [isGraphTypeStacked]="report.isGraphTypeStacked"
            [comparisonLogic]="getComparisonLogic()"
            [isStackedBar]="isStackedBar(reportIndex)"
            chartHeading="{{ 'REPORT-DETAILS.MONTH' | translate }}"
            [marginalValue]="report.monthlyMarginValue"
            [barChartData]="report.monthlyData"
            [chartLabels]="getChartMonthLabels()"
            [isError]="isMonthlyError"
            class="report-details-chart-item"
          >
          </app-report-details-stacked-chart>
        </div>
      </div>
    </div>
  </div>
</ng-template>
