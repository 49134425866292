import { Component, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { AuthService } from 'src/app/services/auth.service';
import { TreeNode, DepartmentNode } from '../../model/tree-node.model';
import { WorkStationService } from '../../services/work-station.service';
import { RouterParamsService } from '../../services/router-params.service';
import { Group } from '../../model/workstation-data.model';
@Component({
  selector: 'app-department-tree-view',
  templateUrl: './department-tree-view.component.html',
  styleUrls: ['./department-tree-view.component.scss'],
})
export class DepartmentTreeViewComponent implements OnInit {
  @Output() dataSource = new MatTreeNestedDataSource<TreeNode>();
  /* istanbul ignore next */
  @Output() treeControl = new NestedTreeControl<TreeNode>(
    (node) => node.children,
  );
  @Output() activeNodes = [];
  groups: Group[];

  constructor(
    private workStationService: WorkStationService,
    private authService: AuthService,
    private routerParamsService: RouterParamsService,
  ) {}

  async ngOnInit(): Promise<void> {
    // initialize MatTreeNestedDataSource to avoid scroll UI issues while awating for the data
    this.dataSource.data.push({ name: '', level: 0 });

    if (!this.authService.user) {
      await this.authService.userAuthInit();
    }

    await this.workStationService.fetchGroupsLiveData();
    this.workStationService.groups.subscribe((groups: Group[]) => {
      if (groups.length) {
        this.groups = groups;
      }
    });

    const aggrDepartmentGroups = this.aggregateDepartmentGroups(this.groups);
    this.dataSource.data = this.treeDataMapper(aggrDepartmentGroups);
    this.treeControl.dataNodes = this.dataSource.data;

    this.routerParamsService.routerParams.subscribe((params: any) => {
      this.syncTreeViewWithRouterParams(params);
      this.expandActiveTreeViewNodes(this.activeNodes);
    });
  }

  private aggregateDepartmentGroups(groups: Array<Group>): DepartmentNode {
    return groups.reduce((acc, current) => {
      const { department, groupName, subgroups } = current;

      acc[department] = {
        ...acc[department],
        [groupName]: subgroups,
      };

      return acc;
    }, {});
  }

  private treeDataMapper(aggregatedData: DepartmentNode): Array<TreeNode> {
    return Object.entries(aggregatedData).map(([department, groups]) => {
      const rootPath = `shiftleader-dashboard/realtime-data/department/${department}`;

      // department level
      return {
        name: department,
        level: 0,
        link: `${rootPath}`,
        children: Object.entries(groups).map(([group, subgroups]) => {
          const groupId = `${department}-${group}`;
          const groupName = group;
          const groupResourcePath = `${rootPath}/group/${groupId}`;
          // group level
          return {
            name: groupName,
            level0Name: department,
            level: 1,
            link: groupResourcePath,
            children: subgroups.map(({ subgroupName, machines }) => {
              const subgroupResourcePath = `${groupResourcePath}/subgroup/${subgroupName}`;
              // subgroup level
              return {
                name: subgroupName,
                level0Name: department,
                level1Name: groupName,
                level: 2,
                link: subgroupResourcePath,
                children: machines.map(({ workCenter, workCenterName }) => {
                  // machine level
                  return {
                    name: `${workCenterName} (${workCenter})`,
                    level0Name: department,
                    level1Name: groupName,
                    level2Name: subgroupName,
                    level: 3,
                    link: `${subgroupResourcePath}/machine/${workCenter}/name/${encodeURIComponent(
                      workCenterName,
                    )}`,
                  };
                }),
              };
            }),
          };
        }),
      };
    });
  }

  private syncTreeViewWithRouterParams({
    departmentId,
    groupId,
    subgroupName,
    machineName,
    machineId,
  }): void {
    const params = {
      departmentId,
      ...(groupId && { groupId: groupId.split('-')[1] }),
      ...(subgroupName && { subgroupName }),
      ...(machineId && {
        machine: {
          name: decodeURIComponent(machineName),
          id: machineId,
        },
      }),
    };

    this.activeNodes = Object.entries(params).map(([entryKey, entryValue]) => {
      if (entryKey === 'machine') {
        return `${entryValue.name} (${entryValue.id})`;
      }
      return `${entryValue}`;
    });
  }

  private expandActiveTreeViewNodes(activeNodes: string[]): void {
    this.treeControl.collapseAll();
    const activeNodesSize = activeNodes.length;

    if (activeNodesSize > 1) {
      let departmentIndex;
      let groupIndex;
      let subgroupIndex;

      const departmentNode = this.treeControl.dataNodes.find(
        ({ name: departmentName }, _, departments) => {
          if (!departmentIndex) {
            departmentIndex = departments.findIndex((department) =>
              activeNodes.includes(department.name),
            );
          }
          return activeNodes.includes(departmentName);
        },
      );
      this.treeControl.expand(this.treeControl.dataNodes[departmentIndex]);

      if (departmentNode && activeNodesSize > 2) {
        const groupNode = departmentNode.children.find(
          ({ name: groupName }, _, groups) => {
            if (!groupIndex) {
              groupIndex = groups.findIndex((group) =>
                activeNodes.includes(group.name),
              );
            }
            return activeNodes.includes(groupName);
          },
        );
        this.treeControl.expand(
          this.treeControl.dataNodes[departmentIndex].children[groupIndex],
        );

        if (groupNode && activeNodesSize > 3) {
          subgroupIndex = groupNode.children.findIndex(
            ({ name: subgroupName }) => activeNodes.includes(subgroupName),
          );
          this.treeControl.expand(
            this.treeControl.dataNodes[departmentIndex].children[groupIndex]
              .children[subgroupIndex],
          );
        }
      }
    }
  }
}
