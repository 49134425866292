import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import * as ChartAnnotation from 'chartjs-plugin-annotation';

@Component({
  selector: 'app-produced-pieces-chart',
  templateUrl: './produced-pieces-chart.component.html',
  styleUrls: ['./produced-pieces-chart.component.scss'],
})
export class ProducedPiecesChartComponent implements OnInit {
  @Input() lineChartData: ChartDataSets;
  @Input() lineChartOptions: ChartOptions;
  @Input() lineChartColors: ChartDataSets[];

  lineChartLegend = false;
  lineChartType = 'line';
  lineChartPlugins = [ChartAnnotation];

  constructor() {}

  ngOnInit(): void {}
}
