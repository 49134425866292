<div *ngIf="showLabels">
  <app-chart-header
    title="{{ 'CHART-HEADER.MACHINE-OVERVIEW' | translate }}"
  ></app-chart-header>
</div>

<app-group-status-bar
  [groupStatus]="groupStatus"
  [totalMachines]="totalMachines"
  [statusColorMap]="statusColorMap"
></app-group-status-bar>

<app-group-list
  [groups]="groups"
  [showLabels]="showLabels"
  [departmentId]="departmentId"
></app-group-list>
