import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';
import { SharedService } from './services/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    public router: Router,
    public translate: TranslateService,
    private sharedService: SharedService,
    private authService: AuthService,
  ) {
    this.sharedService.setDefaultBrowserLanguage();
  }

  async ngOnInit(): Promise<void> {
    if (this.authService.isPlantIdSet()) {
      await this.authService.userAuthInit();
    }
  }
}
