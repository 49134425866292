import { Injectable } from '@angular/core';
import { DeviationWidget } from 'projects/operator-cockpit/src/app/model/operator-cockpit.model';
@Injectable({
  providedIn: 'root',
})
export class DeviationWidgetService {
  constructor() {}

  public calculateDeviation(actualValue: number, target: number): string {
    if (actualValue === target) {
      return '-';
    }
    const deviation = Math.round(((actualValue - target) / target) * 100);
    /* istanbul ignore next */
    const prefixMathSymbol = Math.sign(deviation) > 0 ? '+' : '-';

    return `${prefixMathSymbol} ${Math.abs(deviation)}%`;
  }

  public calculateProgressBar(
    actualValue: number,
    target: number,
    progressBarSize: number,
  ): DeviationWidget {
    const valueBarLimit = 95;
    let targetLine = 80;

    let progressBarValue = (actualValue / target) * targetLine;
    if (progressBarValue > valueBarLimit) {
      targetLine = target / (actualValue / valueBarLimit);
      progressBarValue = 95;
    }

    return {
      progressBarValue,
      targetLinePosition: (targetLine * progressBarSize) / 100,
    };
  }

  public setDeviationWidgetColorClasses(
    actualValue: number,
    target: number,
    isShiftLeaderDashboard?: boolean,
  ): DeviationWidget {
    const bufferClass = isShiftLeaderDashboard
      ? 'progress-bar-buffer-grey'
      : 'progress-bar-buffer-white';
    const backgroundColorClass = isShiftLeaderDashboard ? 'background-white' : 'background-grey';
    if (actualValue < target) {
      return {
        progressBarColorClass: `progress-bar-status-red ${bufferClass}`,
        deviationColorClass: 'deviation-red',
        backgroundColorClass,
      };
    } else if (actualValue > target) {
      return {
        progressBarColorClass: `progress-bar-status-green ${bufferClass}`,
        deviationColorClass: 'deviation-green',
        backgroundColorClass,
      };
    } else {
      return {
        progressBarColorClass: `progress-bar-status-green ${bufferClass}`,
        deviationColorClass: 'deviation-black',
        backgroundColorClass,
      };
    }
  }
}
